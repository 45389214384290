import * as React from 'react';

function SearchIcon(props) {
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse cx={7.39198} cy={7.39481} rx={7.05653} ry={7.10087} fill="#0FF" />
      <path
        opacity={0.4}
        d="M15.66 16.67a1.084 1.084 0 01-.73-.317L13.26 14.4a.892.892 0 01-.067-1.248.8.8 0 011.139 0l2.101 1.681c.303.31.397.77.239 1.175a1.095 1.095 0 01-.968.698l-.044-.037z"
        fill="#fff"
      />
    </svg>
  );
}

export default SearchIcon;
