import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TOURNAMENT } from '../../../../assets/imgs/index';
import './styles.scss';
import { getTranslatedValue } from '../../../lib/getTranslatedValue';

function ExploreItem(props) {
  const { tournament } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const renderDate = (date) => {
    const formattedDate = moment(new Date(date)).format('DD, MMM');
    return formattedDate;
  };
  return (
    <tbody className="tournament-explore-body-wrapper">
      <tr
        className="tournament-row-wrapper"
        onClick={() => history.push(`/tournament/${tournament?.slug}`)}
      >
        <td className="wrapper-column tournament" data-label="Tournament">
          <div className="tournament-info-wrapper">
            <div className="tournament-image">
              <img
                src={tournament?.image ?? TOURNAMENT.noImage}
                alt="dgg-network"
                className="game-image"
              />
            </div>
            <div className="tournament-name">
              <h6 className="tournament-title">{getTranslatedValue(tournament, 'name')}</h6>
              <div className="host-name">
                <h6>{t('tournament.host')}</h6>
                <p>{tournament?.applicationUser?.username}</p>
              </div>
            </div>
          </div>
        </td>
        <td className="wrapper-column game" data-label={t('explore.game')}>
          <h6>{tournament?.applicationGame?.name}</h6>
        </td>
        <td className="wrapper-column bracket" data-label={t('explore.bracketType')}>
          <h6>{tournament?.applicationBracket}</h6>
        </td>
        <td className="wrapper-column date" data-label={t('filter.start')}>
          <h6>{renderDate(tournament?.start_date)}</h6>
        </td>
        <td className="wrapper-column bracket" data-label={t('Network')}>
          <h6>
            {tournament?.applicationNetwork?.network_name ||
              tournament?.ticketCollection?.paymentToken?.chain?.name}
          </h6>
        </td>
        <td className="wrapper-column prize" data-label={t('prize')}>
          <h6>{`${tournament?.prize_amount || 0} ${tournament?.gamePoint}`}</h6>
        </td>
      </tr>
    </tbody>
  );
}

export default ExploreItem;
