import { useWeb3React } from '@web3-react/core';
import { useState } from 'react';
import { URI_AVAILABLE } from '@web3-react/walletconnect-v2';
import { walletConnectV2 } from '../connectors/walletConnectV2';

const DGG_PREFIX = 'dgg://';

export default function useWalletConnect() {
  const [uri, setUri] = useState();
  const { connector } = useWeb3React();

  const getUri = () => {
    walletConnectV2.activate();
    walletConnectV2.events.on(URI_AVAILABLE, (uri) => {
      console.log(`URI: ${uri}`);
      const wcUri = DGG_PREFIX + uri;
      setUri(wcUri);
    });
  };

  const clearUri = () => {
    setUri(undefined);
    if (connector?.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
  };

  return { uri, getUri, clearUri };
}
