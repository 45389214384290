import { STATUS_JOIN_TOURNAMENT } from '../constants';

const statusJoinTournament = (status) => {
  let result = {
    name: '',
    style: '',
  };
  switch (status) {
    case STATUS_JOIN_TOURNAMENT.NOT_JOIN:
      result = {
        name: 'Join Tournament',
        style: '',
      };
      break;
    case STATUS_JOIN_TOURNAMENT.REQUEST:
      result = {
        name: 'Requested',
        style: 'btn-disable',
      };
      break;
    case STATUS_JOIN_TOURNAMENT.APPLY:
      result = {
        name: 'Pay to Join Tournament',
        style: '',
      };
      break;
    case STATUS_JOIN_TOURNAMENT.REJECT:
      result = {
        name: 'Reject',
        style: '',
      };
      break;
    case STATUS_JOIN_TOURNAMENT.WAITING_FOR_CONFIRM:
      result = {
        name: 'Waiting for confirm',
        style: 'btn-disable',
      };
      break;
    case STATUS_JOIN_TOURNAMENT.PAID_FALSE:
      result = {
        name: 'Pay again',
        style: '',
      };
      break;
    default:
      break;
  }
  return result;
};
export default statusJoinTournament;
