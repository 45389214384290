import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/imgs';
import TButton from '../../common/Button';
import './styles.scss';

function AddInputEmail(props) {
  const { listEmail, setListEmail, handleAddEmail } = props;
  const { t } = useTranslation();

  const handleChangeEmail = (e, item) => {
    const index = listEmail.findIndex((input) => input.id === item.id);
    const listNewEmail = [...listEmail];
    listNewEmail[index].email = e.target.value;
    setListEmail(listNewEmail);
  };
  const handleCloseInput = (item) => {
    const index = listEmail.findIndex((input) => input.id === item.id);
    const listNewEmail = [...listEmail];
    listNewEmail.splice(index, 1);
    setListEmail(listNewEmail);
  };

  return (
    <div className="add-email">
      {listEmail.map((item, index) => (
        <div key={item.id} className="wrap-input-custom">
          <input
            value={item.email}
            onChange={(e) => handleChangeEmail(e, item)}
            type="email"
            placeholder="Enter email address"
            className="input-custom"
          />
          {index > 0 && (
            <img
              src={ICONS.iconCloseTeam}
              alt="dgg-network"
              className="btn-close-input"
              onClick={() => handleCloseInput(item)}
              aria-hidden
            />
          )}
        </div>
      ))}
      <div className="invite-player">
        <TButton
          title={t('addAnother')}
          className="btn-add-email"
          onClick={handleAddEmail}
          type="reverse"
        />
      </div>
    </div>
  );
}

export default AddInputEmail;
