import React from 'react';

function EyeActiveIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.991 3.235H10c1.163 0 2.293.28 3.334.804l-2.716 2.74A3.761 3.761 0 0010 6.723c-1.796 0-3.252 1.469-3.252 3.282 0 .213.025.427.057.623l-3.008 3.036c-.813-.952-1.52-2.101-2.081-3.421a.625.625 0 010-.484c1.74-4.086 4.837-6.523 8.276-6.523zm5.366 2.224c1.179 1.083 2.179 2.543 2.927 4.299a.625.625 0 010 .484c-1.74 4.085-4.837 6.53-8.285 6.53h-.008c-1.569 0-3.073-.516-4.398-1.46l2.577-2.6a3.27 3.27 0 001.83.565c1.788 0 3.243-1.468 3.243-3.273 0-.681-.211-1.321-.56-1.846l2.674-2.7z"
        fill="#003452"
      />
      <path
        opacity={0.4}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.356 5.458l1.48-1.493a.608.608 0 000-.87.61.61 0 00-.862 0l-.764.772c-.008.016-.024.032-.04.049a.218.218 0 01-.041.05l-.724.73-2.577 2.6L3.104 16.1l-.024.033a.615.615 0 00.073.772.59.59 0 00.431.18.603.603 0 00.431-.18l8.667-8.747 2.674-2.699zM10 12.047a2.04 2.04 0 002.024-2.043c0-.345-.081-.673-.227-.952l-2.74 2.765c.276.147.601.23.943.23z"
        fill="#0FF"
      />
    </svg>
  );
}

export default EyeActiveIcon;
