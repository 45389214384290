import React, { useState } from 'react';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

function InfoItem(props) {
  const { name, value, type } = props;
  const typePassword = 1;
  const [show, setShow] = useState(false);
  return (
    <div className="d-flex aligns-item-center justify-content-between">
      {type === typePassword ? (
        <>
          <h6>
            {name}: <span>{show ? value : '********'}</span>
          </h6>
          {show ? (
            <AiOutlineEyeInvisible onClick={() => setShow(false)} className="hidden-password" />
          ) : (
            <AiOutlineEye onClick={() => setShow(true)} className="hidden-password" />
          )}
        </>
      ) : (
        <h6>
          {name}: <span>{value}</span>
        </h6>
      )}
    </div>
  );
}

export default InfoItem;
