import React from 'react';

function TwiterIcon({ color = '#00FFFF', ...props }) {
  return (
    <svg
      width={20}
      height={19}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.049 0L7.77 10.028 0 18.182h1.749l6.803-7.139 5.497 7.139H20L11.844 7.59 19.076 0h-1.749l-6.265 6.574L6 0H.049zM2.62 1.251h2.734l12.073 15.68h-2.734L2.62 1.25z"
        fill={color}
      />
    </svg>
  );
}

export default TwiterIcon;
