import React from 'react';

function DggIcon(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M39.46 22.883c.413 2.402.692 4.847.45 7.278-.05.505-.118 1.009-.208 1.508 0 0-.468 2.58-2.688 3.165 0 0-2.422.79-5.651-1.273 0 0-5.146-3.684-12.26-10.373 0 0-6.508-6.283-9.232-7.986 0 0-1.913-1.394-3.527-.129-1.708 1.337-2.748 3.822-3.5 5.813a16.404 16.404 0 00-.95 7.095 8.915 8.915 0 001.096 3.582c.087.154-.076.341-.226.251-.865-.518-1.393-1.73-1.747-2.453-2.03-4.142-.714-9.195 1.27-13.027.577-1.118 1.518-2.695 2.351-3.632a3.823 3.823 0 011.977-1.208 4.41 4.41 0 012.482.182s4.175 1.658 10.95 7.678c0 0 9.368 7.774 10.948 8.6 0 0 2.22 1.674 3.448.95.09-.044.173-.1.248-.166.229-.242.4-.532.502-.849.22-.656.346-1.34.375-2.032.036-.515.078-1.032.092-1.546.022-.808-.055-1.343-.238-2.615v-.04c-.03-.23-.066-.457-.11-.685a16.531 16.531 0 00-.867-2.94 15.075 15.075 0 00-1.264-2.514 2.17 2.17 0 00-1.797-.96c-1.045 0-2.389.325-5.595 2.12 0 0-5.735 3.096-12.174 10.195a.229.229 0 01-.397-.215c.476-1.369 3.21-5.5 10.19-11.33a18.27 18.27 0 014.798-2.918c1.33-.543 2.86-.983 4.365-1.009a3.595 3.595 0 012.875 1.323c.61.76 1.092 1.698 1.514 2.574.301.622.583 1.256.845 1.901 0 0 1.33 3.792 1.655 5.685z"
        fill="#fff"
      />
      <path
        d="M11.233 20.258H9.825v-1.412a.807.807 0 10-1.614 0v1.412H6.794a.808.808 0 000 1.616h1.412v1.412a.807.807 0 001.614 0v-1.409h1.413a.808.808 0 000-1.615v-.004z"
        fill="#0FF"
      />
      <path d="M29.647 20.048a1.278 1.278 0 100-2.556 1.278 1.278 0 000 2.556z" fill="red" />
      <path d="M29.647 24.634a1.278 1.278 0 100-2.556 1.278 1.278 0 000 2.556z" fill="#009245" />
      <path d="M31.94 22.34a1.278 1.278 0 10.001-2.555 1.278 1.278 0 000 2.556z" fill="#FBB03B" />
      <path d="M27.356 22.34a1.278 1.278 0 100-2.555 1.278 1.278 0 000 2.556z" fill="#0071BC" />
      <path
        d="M32.928 20.985v-.098a.034.034 0 00-.036-.036h-.262a.692.692 0 00-.662-.484h-.565v.296h.505a.41.41 0 01.412.404.401.401 0 01-.248.364.396.396 0 01-.152.03h-.17v-.503a.107.107 0 00-.107-.107h-.485a.034.034 0 00-.026.01.034.034 0 00-.01.026v.098c0 .005 0 .01.003.014a.036.036 0 00.019.02c.004.002.009.003.014.003h.237v.08h-.237a.035.035 0 00-.036.037v.098c0 .005 0 .01.003.014a.034.034 0 00.033.022h.237v.481h.566a.694.694 0 00.662-.483h.262c.005 0 .01-.001.014-.003a.034.034 0 00.02-.02.033.033 0 00.002-.013v-.099c0-.005-.001-.01-.003-.014a.036.036 0 00-.033-.023h-.23v-.04-.04h.23c.005.002.01.002.015 0a.037.037 0 00.014-.006.034.034 0 00.013-.027zM30.37 24.028h-.357l-.12-.308h-.524l-.118.308h-.338l.56-1.34h.32l.576 1.34zm-.577-.584l-.16-.41-.153.41h.313zM27.996 21.755h-.375l-.258-.43-.272.43h-.377l.466-.672-.45-.669h.374l.247.389.25-.389h.364l-.424.65.455.69zM30.315 18.281l-.513.75v.595h-.314v-.589l-.51-.752h.357l.303.475.304-.475.373-.004zM7.026 10.995a2.998 2.998 0 100-5.995 2.998 2.998 0 000 5.995zM32.926 10.995a2.998 2.998 0 100-5.995 2.998 2.998 0 000 5.995z"
        fill="#fff"
      />
    </svg>
  );
}

export default DggIcon;
