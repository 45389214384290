import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { SCHOLARSHIP } from '../../../assets/imgs';
import GameCard from '../../components/common/GameCard';
import Layout from '../../components/common/Layout';
import useMessage from '../../components/common/toast/UseMessage';
import { ERR_MESSAGE } from '../../constants';
import scholarshipApi from '../../services/scholarship';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import './styles.scss';

function Scholarship(props) {
  const { dataConnect } = props;
  const { openMessageError } = useMessage();
  const dispatch = useAppDispatch();
  const [games, setGames] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();

  const getGames = async () => {
    dispatch(showLoading());
    try {
      const res = await scholarshipApi.getListGame();
      const { data } = res;
      setGames(data);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const goToDetail = (id) => {
    history.push(`/scholarships/${id}`);
    window.scroll(0, 0);
  };
  useEffect(() => {
    getGames().catch((error) => {
      openMessageError('Error: ', error);
    });
  }, []);

  const renderItem = (game, key) => (
    <Col lg="3" md="4" sm="6" className="mb-4" key={key}>
      <GameCard game={game} goToDetail={() => goToDetail(game.slug)} />
    </Col>
  );

  return (
    <Layout title={t('scholarship.title')} dataConnect={dataConnect}>
      <Col>
        <div className="scholarship-banner">
          <img src={SCHOLARSHIP.bannerScholarship} alt="banner" />
        </div>
      </Col>
      <Row>{games && <>{games.map((game, key) => renderItem(game, key))}</>}</Row>
    </Layout>
  );
}

export default Scholarship;
