import React from 'react';

function OverViewIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 36C28.2435 36 32.3132 34.3143 35.3137 31.3137C38.3143 28.3131 40 24.2435 40 20C40 15.7565 38.3143 11.6869 35.3137 8.68629C32.3132 5.68571 28.2435 4 24 4V20L12.686 31.314C14.1702 32.8019 15.9338 33.9819 17.8754 34.7861C19.817 35.5903 21.8985 36.0028 24 36Z"
        fill="url(#paint0_linear_1236_1653)"
      />
      <path
        d="M24.0001 20L12.6861 31.314C10.4483 29.0764 8.9244 26.2254 8.30699 23.1216C7.68957 20.0178 8.00643 16.8007 9.21748 13.877C10.4285 10.9533 12.4794 8.4544 15.1107 6.6963C17.742 4.93821 20.8355 3.99988 24.0001 4V20ZM26.0001 40H40.0001V44H26.0001V40ZM8.0001 40H22.0001V44H8.0001V40Z"
        fill="#76808F"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1236_1653"
          x1="26.344"
          y1="36"
          x2="26.344"
          y2="4"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.333" stopColor="#04ABAB" />
          <stop offset="1" stopColor="#00FFFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default OverViewIcon;
