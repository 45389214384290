import React from 'react';
import { Spinner } from 'react-bootstrap';
import './styles.scss';

const LoadingButton = ({
  className,
  disabled = false,
  title,
  onClick,
  id,
  type,
  width,
  loading,
  children,
}) => (
  <button
    className={`btn-stroke-fill btn-loading ${className} ${type} ${width} ${
      disabled ? 'disabled' : ''
    }`}
    disabled={disabled || loading}
    onClick={onClick}
    id={id}
    type="button"
  >
    <Spinner animation="border" className={loading ? '' : 'visually-hidden'} size="sm" />
    {title}
    {children}
  </button>
);

export default LoadingButton;
