import React from 'react';

function DiscordIcon({ color = '#00FFFF', ...props }) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.862 6.926a32.986 32.986 0 00-8.142-2.525.123.123 0 00-.131.062 22.97 22.97 0 00-1.014 2.082 30.452 30.452 0 00-9.144 0 21.095 21.095 0 00-1.03-2.082.129.129 0 00-.13-.062 32.894 32.894 0 00-8.143 2.525.116.116 0 00-.053.046C.889 14.719-.532 22.276.165 29.739a.137.137 0 00.052.094c3.422 2.513 6.736 4.038 9.989 5.049a.13.13 0 00.14-.046 23.706 23.706 0 002.043-3.324.127.127 0 00-.07-.176 21.844 21.844 0 01-3.12-1.487.128.128 0 01-.012-.213c.21-.157.42-.32.62-.486a.124.124 0 01.129-.017c6.546 2.989 13.633 2.989 20.102 0a.123.123 0 01.131.016c.2.165.41.33.621.487a.128.128 0 01-.01.213 20.492 20.492 0 01-3.123 1.485.128.128 0 00-.067.178 26.63 26.63 0 002.041 3.322.127.127 0 00.14.048c3.27-1.011 6.583-2.536 10.005-5.05a.128.128 0 00.052-.091c.834-8.628-1.397-16.123-5.914-22.767a.102.102 0 00-.052-.048zM13.367 25.195c-1.971 0-3.595-1.81-3.595-4.032s1.592-4.031 3.595-4.031c2.018 0 3.626 1.825 3.595 4.031 0 2.223-1.593 4.032-3.595 4.032zm13.29 0c-1.97 0-3.594-1.81-3.594-4.032s1.593-4.031 3.595-4.031c2.018 0 3.626 1.825 3.595 4.031 0 2.223-1.577 4.032-3.595 4.032z"
        fill={color}
      />
    </svg>
  );
}

export default DiscordIcon;
