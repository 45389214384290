import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';

function NoTournament() {
  const { t } = useTranslation();
  return (
    <div className="no-tournament-filter-wrapper">
      <div className="no-tournament-filter-content">
        <div className="no-tournament-filter-title">{t('tournament.noTournaments')}</div>
      </div>
    </div>
  );
}

export default NoTournament;
