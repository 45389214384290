import React from 'react';

function TicketIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1555_25396)">
        <path
          d="M13.31 12.043c2.224-2.916 1.657-7.087-1.266-9.316C9.122.498 4.95 1.055 2.726 3.971.502 6.886 1.069 11.057 3.99 13.286c2.923 2.23 7.095 1.673 9.32-1.243z"
          fill="#FFD45A"
        />
        <path
          opacity={0.1}
          d="M7.968.254A7.768 7.768 0 00.256 8.062c.032 4.288 3.52 7.728 7.792 7.696 4.288-.032 7.728-3.52 7.696-7.792C15.728 3.678 12.24.238 7.968.254zm.08 14.208a6.44 6.44 0 01-6.48-6.416c-.032-3.568 2.848-6.464 6.4-6.48a6.44 6.44 0 016.48 6.416c.032 3.552-2.832 6.448-6.4 6.48z"
          fill="#232020"
        />
        <path
          d="M7.968.016C3.552.048 0 3.648.016 8.064.048 12.48 3.648 16.032 8.048 16c4.416-.032 7.968-3.632 7.936-8.032C15.968 3.552 12.368 0 7.968.016zm.08 14.64c-3.664.032-6.656-2.928-6.688-6.608-.016-3.664 2.944-6.656 6.608-6.688 3.664-.016 6.672 2.928 6.688 6.608a6.627 6.627 0 01-6.608 6.688z"
          fill="#FFD22C"
        />
        <path
          d="M6.625 4.192s-.432-.352-1.072-.4a.8.8 0 00-.464.112c-.112.064-.256.144-.304.208-.096.128-.304.416-.304.416s-.464.128-.656.432c0 0-.128.048-.176.272a.795.795 0 00.112.656c.08.112.176.24.272.32l.176.272s-.208.464-.224 1.744c0 .944.288 1.888.896 2.624.016.016.016.032.032.032a3.663 3.663 0 001.984 1.2 5.16 5.16 0 001.632.112c1.648-.144 3.088-1.248 3.52-2.848l.048-.192s.272-1.104-.112-2.544c0 0 .272-.368.384-.464 0 0 .016-.032.048-.064a.868.868 0 00-.08-1.008 1.514 1.514 0 00-.688-.448s-.464-.848-1.184-.768c0 0-.544.064-.88.32-.016.016-1.504-.432-2.96.016z"
          fill="#D18B00"
        />
        <path
          d="M6.576 4.192s-.432-.352-1.072-.4a.8.8 0 00-.464.112c-.112.064-.256.144-.304.208-.096.128-.304.416-.304.416s-.464.128-.656.432c0 0-.128.048-.176.272a.795.795 0 00.112.656c.08.112.176.24.272.32l.176.272s-.208.464-.224 1.744c0 .944.288 1.888.896 2.624.016.016.016.032.032.032a3.663 3.663 0 001.984 1.2 5.16 5.16 0 001.632.112c1.649-.144 3.088-1.248 3.52-2.848l.049-.192s.271-1.104-.112-2.544c0 0 .272-.368.383-.464 0 0 .016-.032.048-.064a.868.868 0 00-.08-1.008 1.514 1.514 0 00-.688-.448s-.463-.848-1.184-.768c0 0-.544.064-.88.32-.016.016-1.504-.432-2.96.016z"
          fill="#FFC239"
        />
        <path
          d="M8.145 6.784a1.34 1.34 0 01.592-.48c.256-.096.512-.032.768-.048-.256.032-.512-.016-.768.08a1.933 1.933 0 00-.592.448zM7.57 6.737c-.209-.288-.513-.624-.897-.576-.128.016-.256 0-.384-.032.128.016.256.032.368 0 .416-.064.752.288.912.608zM5.744 6.433a.53.53 0 01.672-.016.715.715 0 00-.672.016zM5.424 7.136c.128-.336.48-.608.848-.56-.368.016-.672.256-.848.56zM5.872 7.408c-.016-.256.128-.544.384-.592-.224.112-.336.352-.384.592zM6.512 7.473c.224.416.672.736 1.152.576-.48.224-.992-.128-1.152-.576zM7.969 8.095c.48.176.928-.16 1.2-.544-.208.432-.736.784-1.2.544zM7.361 7.137c.32.192.608.192.944.048-.192.368-.784.336-.944-.048zM9.584 6.895c.24.032.464.256.448.512a.668.668 0 00-.448-.512zM9.584 6.656c.32-.128.688.048.896.304l.08.112c-.24-.288-.608-.496-.976-.416zM9.408 6.495c.192-.192.528-.256.768-.096a.87.87 0 00-.768.096zM11.888 5.426a1.527 1.527 0 01-.48.48c.16-.144.32-.32.48-.48zM12.064 5.695a1.954 1.954 0 01-.48.48c.16-.16.32-.336.48-.48zM12.193 6a1.64 1.64 0 01-.464.416c.144-.144.288-.288.464-.416zM4.049 5.25c.144.176.272.368.448.512a1.074 1.074 0 01-.448-.512zM3.873 5.52c.16.16.288.32.448.48a1.292 1.292 0 01-.448-.48zM3.777 5.824c.144.16.304.304.464.448a1.136 1.136 0 01-.464-.448z"
          fill="#FFFF97"
        />
        <path
          d="M6.577 4.193a2.138 2.138 0 00-1.184-.4c-.208.016-.4.128-.576.256-.096.064-.144.16-.208.24l-.192.256c-.128.048-.256.096-.368.16a.784.784 0 00-.288.272.515.515 0 00-.192.288.657.657 0 000 .352c.032.112.08.224.144.32.064.096.144.192.24.272l.176.272c-.112.32-.16.656-.192.992a5.306 5.306 0 00-.016 1.008c.032.672.224 1.344.576 1.92a3.717 3.717 0 001.456 1.36c.608.304 1.28.432 1.952.448a4.332 4.332 0 001.952-.416 3.982 3.982 0 001.536-1.28c.384-.544.624-1.2.672-1.888a6.437 6.437 0 00-.176-2c.064-.08.112-.16.176-.24.064-.08.128-.16.208-.224a.912.912 0 00.176-.448.852.852 0 00-.112-.48A1.176 1.176 0 0012 4.881a1.471 1.471 0 00-.448-.224 1.528 1.528 0 00-.384-.464 1.311 1.311 0 00-.528-.288c-.192-.048-.4 0-.592.048a1.662 1.662 0 00-.544.24 5.719 5.719 0 00-2.208-.144c-.24.016-.48.08-.72.144zm0 0c.24-.064.48-.128.72-.16a5.685 5.685 0 012.208.144c.16-.128.352-.192.544-.24.192-.048.4-.096.592-.048.192.048.384.144.528.288.144.128.288.288.384.464.16.048.304.128.448.224.128.096.256.224.336.368.08.144.128.32.112.48a.969.969 0 01-.176.464c-.048.064-.112.144-.176.224a2.29 2.29 0 00-.176.24c.176.656.24 1.344.176 2.016-.032.672-.288 1.344-.672 1.888-.384.56-.928.992-1.536 1.296a4.4 4.4 0 01-1.968.416c-.672-.016-1.36-.144-1.968-.448a3.654 3.654 0 01-1.472-1.36 4.072 4.072 0 01-.576-1.92c-.016-.336 0-.672.032-1.008.032-.336.064-.672.192-.992l-.176-.272a1.274 1.274 0 01-.24-.272.825.825 0 01-.144-.32.657.657 0 010-.352c.032-.112.08-.24.192-.304a.93.93 0 01.288-.272 1.51 1.51 0 01.368-.16l.192-.256c.064-.08.112-.176.208-.24.176-.128.368-.24.576-.256.208 0 .416.032.624.112.192.032.384.128.56.256z"
          fill="url(#paint0_linear_1555_25396)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1555_25396"
          x1={3.53558}
          y1={8.01844}
          x2={12.486}
          y2={8.01844}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <clipPath id="clip0_1555_25396">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TicketIcon;
