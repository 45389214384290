import * as React from 'react';

function ReferralCopyIcon({ color = '#0FF', ...props }) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#clip0_681_21655)"
        stroke={color}
        strokeWidth={1.33953}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M13.018 6.213H7.282c-.704 0-1.274.533-1.274 1.19v5.359c0 .657.57 1.19 1.274 1.19h5.736c.704 0 1.275-.533 1.275-1.19V7.404c0-.658-.57-1.191-1.275-1.191z" />
        <path d="M3.458 9.786H2.82c-.338 0-.663-.125-.902-.348a1.152 1.152 0 01-.373-.842V3.238c0-.316.134-.62.373-.842.24-.224.564-.35.902-.35h5.736c.338 0 .662.126.901.35.24.223.373.526.373.842v.595" />
      </g>
      <defs>
        <clipPath id="clip0_681_21655">
          <path fill="#fff" transform="translate(.271 .855)" d="M0 0H15.2964V14.2883H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ReferralCopyIcon;
