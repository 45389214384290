import React from 'react';
import './styles.scss';

function LoginBtn({ className = '', onClick = null, children }) {
  return (
    <button type="button" onClick={onClick} className={`btn-login ${className}`}>
      {children}
    </button>
  );
}

export default LoginBtn;
