import * as React from 'react';

function SuccessTickIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={12} cy={12} r={10} fill="#3AD349" />
      <path
        d="M11.248 15.823a.851.851 0 01-.618-.269l-2.373-2.492a.95.95 0 010-1.3.845.845 0 011.236 0l1.755 1.843 4.129-4.336a.845.845 0 011.236 0 .952.952 0 010 1.3l-4.745 4.985a.851.851 0 01-.62.27"
        fill="#F5F5F5"
      />
    </svg>
  );
}

export default SuccessTickIcon;
