import React, { useState, useEffect } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import JSCookie from 'js-cookie';
import TButton from '../../common/Button';
import RedeemGameCard from './RedeemGameCard';
import RedWarningIcon from '../../icons/RedWarningIcon';
import TicketIcon from '../../icons/TicketIcon';
import MinusBorderIcon from '../../icons/MinusBorderIcon';
import PlusBorderIcon from '../../icons/PlusBorderIcon';
import GarbageIcon from '../../icons/GarbageIcon';
import SelectTicketDropdown from './SelectTicketDropdown';
import RedemptionModal from './RedemptionModal';
import {
  CONTRACT_BY_CHAIN,
  ClaimTicketSCStatus,
  ConfirmRequestBackendStatus,
  CreateRequestStatus,
  MEDIUM_SCREEN_WIDTH,
  REQUEST_TIMEOUT_MILISECOND,
  SendingRequestSCStatus,
  WALLET_ERROR_REASON,
} from '../../../constants';
import useCreateRequestRedeem from '../../../hooks/useCreateRequestRedeem';
import useConfirmRequest from '../../../hooks/useConfirmRequest';
import SelectChainDropdown from './SelectChainDropdown';
import marketPlaceApi from '../../../services/marketplace';
import useMessage from '../../common/toast/UseMessage';
import { ICONS, TOURNAMENT_PRIZE } from '../../../../assets/imgs';
import tournamentApi from '../../../services/tournament';
import useActiveWeb3React from '../../../hooks/useActiveWeb3React';
import useSwitchNetwork from '../../../hooks/useSwitchNetwork';
import CONTRACT_ABI from '../../../abis/tournament.json';
import formatPointNumber from '../../../lib/formatPointNumber';
import shortenAddress from '../../../utils/shortenAddress';
import ModalConnectWallet from '../../common/ModalConnectWallet/ModalConnectWallet';
import useScreenWidth from '../../../hooks/useScreenWidth';
import ModalRedeemSuccess from './ModalRedeemSuccess';
import ToastErrorInsufficientFund from '../../common/toast/ToastErrorInsufficientFund';

function RedemptionTab(props) {
  const { t } = useTranslation();
  const { isLogin, dataConnect } = props;
  const [chainSelected, setChainSelected] = useState();
  const [selectedGame, setSelectedGame] = useState();
  const [showModalRedeem, setShowModalRedeem] = useState(false);
  const [showRetryBtn, setShowRetryBtn] = useState(false);
  const [showModalConnectWallet, setShowModalConnectWallet] = useState(false);

  const [createRequestRedeemStatus, setCreateRequestRedeemStatus] = useState(
    CreateRequestStatus.INIT,
  );
  const [redeemRequest, setRedeemRequest] = useState();
  const [sendingRequestStatus, setSendingRequestStatus] = useState(SendingRequestSCStatus.INIT);
  const [requestTransactionHash, setRequestTransactionHash] = useState();

  const [confirmRequestStatus, setConfirmRequestStatus] = useState(
    ConfirmRequestBackendStatus.INIT,
  );
  const [redeemSignature, setRedeemSignature] = useState();
  const [claimTicketStatus, setClaimTicketStatus] = useState(ClaimTicketSCStatus.INIT);

  const [listGame, setListGame] = useState();
  const [listChain, setListChain] = useState([]);
  const [listTicketDropdown, setListTicketDropdown] = useState([]);

  const [canSubmitRequest, setCanSubmitRequest] = useState(true);

  const [listRedeemTicket, setListRedeemTicket] = useState([
    { ticketCollectionId: 1, quantity: 1 },
  ]);
  const [redeemRequestPayload, setRedeemRequestPayload] = useState();
  const [showModalRedeemSuccess, setShowModalRedeemSuccess] = useState(false);
  const [listTicketType, setlistTicketType] = useState([]);
  const [lastItemRedeem, setLastItemRedeem] = useState();
  const { openMessageError } = useMessage();
  const { account: signerAddress, provider: activeWeb3Provider } = useActiveWeb3React(
    chainSelected?.chainId,
  );
  const walletAccount = JSCookie.get('walletAccount');
  const accountEmail = JSCookie.get('email');
  const { switchNetwork } = useSwitchNetwork();
  const screenWidth = useScreenWidth();
  const [isRedeemProcessing, setIsRedeemProcessing] = useState(false);

  const getListGamePoint = async () => {
    try {
      if (chainSelected) {
        const params = {
          offset: 0,
          limit: 10,
          chainId: chainSelected.chainId,
        };
        const res = await tournamentApi.getListGamePoint(params);
        setListGame(res?.data);
      }
    } catch (error) {
      openMessageError(error.response?.data || error.response.data?.message || ERR_MESSAGE);
    }
  };

  const getListChain = async () => {
    try {
      const res = await marketPlaceApi.getChains();
      setListChain(res?.data?.data);
    } catch (error) {
      openMessageError(error.response?.data || error.response.data?.message || ERR_MESSAGE);
    }
  };

  const getListTicketCollection = async () => {
    try {
      const params = {
        offset: 0,
        limit: 10,
        chainId: chainSelected?.chainId,
        gameId: selectedGame?.id,
      };
      const res = await marketPlaceApi.getListTicketCollection(params);
      setListTicketDropdown(res?.data?.data);
    } catch (error) {
      openMessageError(error.response.data || error.response.data?.message || ERR_MESSAGE);
    }
  };

  const getListTicketType = async () => {
    try {
      const res = await marketPlaceApi.getListTicketTypes();
      setlistTicketType(res?.data?.data);
    } catch (error) {
      openMessageError(error.response?.data || error.response.data?.message || ERR_MESSAGE);
    }
  };

  useEffect(() => {
    const lastChainSelected = localStorage.getItem('lastChainRedeem');
    const lastItemRedeem = localStorage.getItem('lastRedeemTicket');
    if (lastChainSelected) {
      setChainSelected(JSON.parse(lastChainSelected));
    }
    if (lastItemRedeem) {
      setLastItemRedeem(JSON.parse(lastItemRedeem));
    }
  }, []);

  useEffect(() => {
    if (isLogin) {
      getListChain();
      getListGamePoint();
      getListTicketType();
    }
  }, [isLogin]);

  useEffect(() => {
    if (chainSelected) {
      getListGamePoint();
    }
  }, [chainSelected]);

  useEffect(() => {
    if (listGame) {
      setSelectedGame(listGame[0]);
    }
  }, [listGame]);

  useEffect(() => {
    const lastChainSelected = localStorage.getItem('lastChainRedeem');
    if (listChain && !lastChainSelected) {
      setChainSelected(listChain[0]);
    }
  }, [listChain]);

  useEffect(() => {
    if (isLogin && chainSelected && selectedGame) {
      getListTicketCollection();
      setListRedeemTicket([{ ticketCollectionId: 1, quantity: 1 }]);
    }
  }, [isLogin, chainSelected, selectedGame]);

  const handleAddNewRedeemTicket = () => {
    if (listRedeemTicket.length > 3) return;
    setListRedeemTicket([
      ...listRedeemTicket,
      {
        ticketCollectionId: 1,
        quantity: 1,
      },
    ]);
  };

  useEffect(() => {
    if (listRedeemTicket) {
      const currentPrice = listRedeemTicket.reduce((total, item) => {
        const correspondingTicket = listTicketDropdown.find(
          (ticket) => ticket.id === item.ticketCollectionId,
        );
        if (correspondingTicket && item.quantity && correspondingTicket?.points) {
          // in dev
          total += item.quantity * correspondingTicket?.points;
        }

        return total;
      }, 0);
      if (currentPrice > selectedGame?.balance) {
        setCanSubmitRequest(false);
      } else {
        setCanSubmitRequest(true);
      }

      const groupedTickets = listRedeemTicket.reduce((result, ticket) => {
        const existingTicket = result.find(
          (item) => item.ticketCollectionId === ticket.ticketCollectionId,
        );

        if (existingTicket) {
          existingTicket.quantity += ticket.quantity;
        } else {
          result.push({ ...ticket });
        }

        return result;
      }, []);
      setRedeemRequestPayload(groupedTickets);
    }
  }, [listRedeemTicket]);

  const { createRequestRedeem } = useCreateRequestRedeem({
    setStatus: (status) => {
      setCreateRequestRedeemStatus(status);
    },
    setRedeemRequest: (data) => {
      setRedeemRequest(data);
    },
    chainId: chainSelected?.chainId,
  });

  const { createConfirmRequest } = useConfirmRequest({
    setStatus: (status) => {
      setConfirmRequestStatus(status);
    },
    setRedeemSignature: (data) => {
      setRedeemSignature(data);
    },
    chainId: chainSelected?.chainId,
  });

  const handleSubmitRequest = () => {
    setIsRedeemProcessing(true);
    setShowModalRedeem(true);
    createRequestRedeem({
      redeemRequest: {
        gameId: selectedGame?.id,
        chainId: chainSelected?.chainId,
        requests: redeemRequestPayload,
      },
    });
    setCreateRequestRedeemStatus(CreateRequestStatus.INIT);
    setSendingRequestStatus(SendingRequestSCStatus.INIT);
    setConfirmRequestStatus(ConfirmRequestBackendStatus.INIT);
    setClaimTicketStatus(ClaimTicketSCStatus.INIT);
  };

  const handleCreateSendingRequest = async () => {
    try {
      const chainId = chainSelected?.chainId;
      if (!redeemRequest) {
        toast.error(t('require.closeModal'));
        return;
      }

      if (!signerAddress) {
        toast.error(t('require.connectyourWallet'));
        return;
      }
      const walletAddress = JSCookie.get('walletAccount');
      if (walletAddress !== signerAddress) {
        toast.error(
          `${t('error.pleaseSwitch')} ${shortenAddress(walletAddress, 4)} ${t(
            'error.linkedAccount',
          )}`,
        );
        return;
      }

      setSendingRequestStatus(SendingRequestSCStatus.PENDING);
      await switchNetwork(chainId);

      const signer = activeWeb3Provider.getSigner();
      const rewardClaimTickets = redeemRequest?.redeemDetail.map((item) => ({
        ticketCollectionAddress: item?.ticketCollectionAddress,
        quantity: item?.quantity,
      }));

      const { tournamentAddress } = CONTRACT_BY_CHAIN[chainId];
      const contract = new ethers.Contract(tournamentAddress, CONTRACT_ABI, signer);
      const createSendingRequestTransaction = await contract.createRedeemRequest(
        redeemRequest.id,
        redeemRequest.revenueAddress,
        redeemRequest.tokenAddress,
        rewardClaimTickets,
      );

      setSendingRequestStatus(SendingRequestSCStatus.PENDING);
      const response = await createSendingRequestTransaction.wait();

      if (response.status === 0) {
        setSendingRequestStatus(SendingRequestSCStatus.FAILED);
        setIsRedeemProcessing(false);
      } else {
        const txHash = response?.transactionHash;
        setRequestTransactionHash(txHash);
        setSendingRequestStatus(SendingRequestSCStatus.CREATED);
      }

      setTimeout(() => {
        setSendingRequestStatus(SendingRequestSCStatus.CREATED);
      }, REQUEST_TIMEOUT_MILISECOND);
    } catch (error) {
      console.log('Create Sending Request Error: ', error);
      setIsRedeemProcessing(false);
      if (error.message.includes('user rejected transaction')) {
        toast.error(t('alert.rejectedRequest'));
        setSendingRequestStatus(SendingRequestSCStatus.REJECTED);
      } else if (
        error?.reason === WALLET_ERROR_REASON.INSUFFICIENT_FUND ||
        error?.reason === WALLET_ERROR_REASON.CANNOT_ESTIMATE
      ) {
        toast.error(
          <ToastErrorInsufficientFund chainId={chainSelected?.chainId} account={signerAddress} />,
        );
        setSendingRequestStatus(SendingRequestSCStatus.FAILED);
      } else {
        toast.error(
          error?.data?.message || error?.reason || error?.message || t('error.errorMessage'),
        );
        setSendingRequestStatus(SendingRequestSCStatus.FAILED);
      }
    }
  };

  const handleSaveAfterRedeem = () => {
    localStorage.setItem('lastChainRedeem', JSON.stringify(chainSelected));

    const ticketMaxQuantity = listRedeemTicket?.reduce((maxQuantityItem, currentItem) => {
      if (!maxQuantityItem || currentItem.quantity > maxQuantityItem.quantity) {
        return currentItem;
      }
      return maxQuantityItem;
    }, null);

    localStorage.setItem('lastRedeemTicket', JSON.stringify(ticketMaxQuantity));
  };

  const handleCreateClaimTicket = async () => {
    try {
      const chainId = chainSelected?.chainId;
      if (!redeemSignature) {
        toast.error(t('require.closeModal'));
        return;
      }

      if (!signerAddress) {
        toast.error(t('require.connectyourWallet'));
        return;
      }

      const walletAddress = JSCookie.get('walletAccount');
      if (walletAddress !== signerAddress) {
        toast.error(
          `${t('error.pleaseSwitch')} ${shortenAddress(walletAddress, 4)} ${t(
            'error.linkedAccount',
          )}`,
        );
        return;
      }

      setClaimTicketStatus(ClaimTicketSCStatus.PENDING);
      await switchNetwork(chainId);

      const signer = activeWeb3Provider.getSigner();
      const { tournamentAddress } = CONTRACT_BY_CHAIN[chainId];
      const contract = new ethers.Contract(tournamentAddress, CONTRACT_ABI, signer);
      const createClaimTransaction = await contract.claimTicket(
        redeemSignature?.id,
        redeemSignature?.signature,
      );

      setClaimTicketStatus(ClaimTicketSCStatus.PENDING);
      const response = await createClaimTransaction.wait();

      if (response.status === 0) {
        setClaimTicketStatus(ClaimTicketSCStatus.FAILED);
      } else {
        const txHash = response?.transactionHash;
        await tournamentApi.sendClaimTicketLog(txHash, chainId);
        setClaimTicketStatus(ClaimTicketSCStatus.CREATED);
        setShowModalRedeem(false);
        setShowModalRedeemSuccess(true);
        // claim done, save to localstorage
        handleSaveAfterRedeem();
      }
      setIsRedeemProcessing(false);

      // claim done, set all to null
    } catch (error) {
      setIsRedeemProcessing(false);
      console.log('error: ', error);
      if (error.message.includes('user rejected transaction')) {
        toast.error(t('alert.rejectedRequest'));
        setClaimTicketStatus(ClaimTicketSCStatus.REJECTED);
      } else if (
        error?.reason === WALLET_ERROR_REASON.INSUFFICIENT_FUND ||
        error?.reason === WALLET_ERROR_REASON.CANNOT_ESTIMATE
      ) {
        toast.error(
          <ToastErrorInsufficientFund chainId={chainSelected?.chainId} account={signerAddress} />,
        );
        setClaimTicketStatus(ClaimTicketSCStatus.FAILED);
      } else {
        toast.error(
          error?.data?.message || error?.reason || error?.message || t('error.errorMessage'),
        );
        setClaimTicketStatus(ClaimTicketSCStatus.FAILED);
      }
    }
  };

  const handleClickRetryBtn = () => {
    setIsRedeemProcessing(true);
    if (createRequestRedeemStatus === CreateRequestStatus.FAILED) {
      createRequestRedeem({
        redeemRequest: {
          gameId: selectedGame?.id,
          chainId: chainSelected?.chainId,
          requests: redeemRequestPayload,
        },
      });
    }
    if (
      sendingRequestStatus === SendingRequestSCStatus.FAILED ||
      sendingRequestStatus === SendingRequestSCStatus.REJECTED
    ) {
      handleCreateSendingRequest();
    }
    if (confirmRequestStatus === ConfirmRequestBackendStatus.FAILED) {
      createConfirmRequest({ requestTransactionHash });
    }
    if (
      claimTicketStatus === ClaimTicketSCStatus.FAILED ||
      claimTicketStatus === ClaimTicketSCStatus.REJECTED
    ) {
      handleCreateClaimTicket();
    }
  };

  const handleClickCloseBtn = () => {
    setShowModalRedeem(false);
    setCreateRequestRedeemStatus(CreateRequestStatus.INIT);
    setSendingRequestStatus(SendingRequestSCStatus.INIT);
    setConfirmRequestStatus(ConfirmRequestBackendStatus.INIT);
    setClaimTicketStatus(ClaimTicketSCStatus.INIT);
    setShowRetryBtn(false);
  };

  useEffect(() => {
    if (
      createRequestRedeemStatus === CreateRequestStatus.FAILED ||
      createRequestRedeemStatus === CreateRequestStatus.REJECTED
    ) {
      setShowRetryBtn(true);
    }
    if (createRequestRedeemStatus === CreateRequestStatus.CREATED && showModalRedeem) {
      handleCreateSendingRequest();
      setShowRetryBtn(false);
    }
  }, [createRequestRedeemStatus]);

  useEffect(() => {
    if (
      sendingRequestStatus === SendingRequestSCStatus.FAILED ||
      sendingRequestStatus === SendingRequestSCStatus.REJECTED
    ) {
      setShowRetryBtn(true);
    }
    if (sendingRequestStatus === SendingRequestSCStatus.CREATED && showModalRedeem) {
      createConfirmRequest({ requestTransactionHash });
      setShowRetryBtn(false);
    }
  }, [sendingRequestStatus]);

  useEffect(() => {
    if (
      confirmRequestStatus === ConfirmRequestBackendStatus.FAILED ||
      confirmRequestStatus === ConfirmRequestBackendStatus.REJECTED
    ) {
      setShowRetryBtn(true);
    }
    if (confirmRequestStatus === ConfirmRequestBackendStatus.CREATED && showModalRedeem) {
      handleCreateClaimTicket();
      setShowRetryBtn(false);
    }
  }, [confirmRequestStatus]);

  useEffect(() => {
    if (
      claimTicketStatus === ClaimTicketSCStatus.FAILED ||
      claimTicketStatus === ClaimTicketSCStatus.REJECTED
    ) {
      setShowRetryBtn(true);
    }
    if (claimTicketStatus === ClaimTicketSCStatus.CREATED && showModalRedeem) {
      toast.success(t('success.redeemSuccessfully'));
      setShowRetryBtn(false);
    }
  }, [claimTicketStatus]);

  const handleResetStatus = () => {
    setCreateRequestRedeemStatus(CreateRequestStatus.INIT);
    setSendingRequestStatus(SendingRequestSCStatus.INIT);
    setConfirmRequestStatus(ConfirmRequestBackendStatus.INIT);
    setClaimTicketStatus(ClaimTicketSCStatus.INIT);
  };

  const handleLinkWallet = () => {
    dataConnect.linkWallet();
  };

  const renderBtnSubmitRequest = () => {
    if (!signerAddress) {
      return (
        <>
          <TButton
            title={t('login.connectWallet')}
            className="btn-connect-wallet"
            onClick={() => {
              setShowModalConnectWallet(true);
            }}
          />
          <ModalConnectWallet
            showDialog={showModalConnectWallet}
            setShowDialog={setShowModalConnectWallet}
          />
        </>
      );
    }
    if (accountEmail && !Number(walletAccount)) {
      return (
        <TButton
          title={t('require.link')}
          className="btn-connect-wallet"
          onClick={handleLinkWallet}
        />
      );
    }
    return (
      <TButton
        title={t('require.submitRequest')}
        className="btn-submit-redeem"
        onClick={handleSubmitRequest}
        disabled={!canSubmitRequest || listTicketDropdown?.length === 0 || isRedeemProcessing}
      />
    );
  };

  return (
    <div className="redemption-tab-wrapper">
      <div className="list-game-point">
        <div className="my-game-reward">
          <div className="redeem-title">{t('myPrize.myGameReward')}</div>
          <div className="number-available">
            {t('myPrize.available')}: {listGame?.length} {t('explore.game')}
          </div>
        </div>
        {listGame?.length > 0 ? (
          listGame?.map((item) => (
            <RedeemGameCard
              key={item.id}
              data={item}
              selectedGame={selectedGame}
              setSelectedGame={setSelectedGame}
            />
          ))
        ) : (
          <div className="no-game-available">
            <img
              src={TOURNAMENT_PRIZE.noTicketGame}
              alt="no-ticket-img"
              className="no-ticket-img"
            />
            <p>{t('myPrize.noGameToReward')}</p>
          </div>
        )}
      </div>
      <div className="">
        <div className="ticket-quantity-wrapper">
          <div className="header-warning-wrap">
            <div className="sub-header">{t('redeemRewards.title')}</div>
            <div className="recommend-title">
              {t(
                '* Recommend redeeming a maximum of 50 tickets per transaction for a quicker processing time.',
              )}
            </div>
          </div>
          <div className="btn-select-chain-wrapper">
            <SelectChainDropdown
              listDropdown={listChain}
              showing={chainSelected}
              setShowing={setChainSelected}
              classNameDropdown="height-chain-custom"
            />
            <div className="my-balance-wrapper">
              <p>{t('myPrize.balance')}</p>
              <div className="balance-number-wrap">
                <p className="balance-point">
                  {selectedGame?.balance ? formatPointNumber(selectedGame?.balance, 6) : 0}
                </p>
                {selectedGame?.tokenImage ? (
                  <img
                    className="img-balance-point"
                    src={selectedGame?.tokenImage}
                    alt="game-point"
                  />
                ) : (
                  <TicketIcon />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="ticket-quantity-wrapper submit-request-wrapper">
          <div className="submit-request-flex-wrap">
            {screenWidth > MEDIUM_SCREEN_WIDTH && renderBtnSubmitRequest()}
          </div>
          <div className="break-line" />
          {listTicketDropdown?.length > 0 && (
            <div className="redeem-ticket-list-wrapper">
              <div className="redeem-header">
                <p>{t('myPrize.item')}</p>
                <p>{t('myPrize.quantity')}</p>
              </div>
              <div className="table-redeem-ticket">
                {listRedeemTicket.map((i, index) => (
                  <TableRedeemTicketItem
                    // eslint-disable-next-line
                    key={`line-${index}`}
                    data={i}
                    listRedeemTicket={listRedeemTicket}
                    setListRedeemTicket={setListRedeemTicket}
                    itemIndex={index}
                    listTicketDropdown={listTicketDropdown}
                    lastItemRedeem={lastItemRedeem}
                  />
                ))}
              </div>
              <div className="btn-add-redeem-wrapper">
                <TButton
                  title={t('myPrize.add')}
                  leftIcon={ICONS.plusIcon}
                  className="btn-add-new-redeem-ticket"
                  onClick={handleAddNewRedeemTicket}
                />
              </div>
            </div>
          )}
          {!canSubmitRequest && (
            <div className="not-enough-point">
              <RedWarningIcon />
              <div>{t('warning.notHaveEnoughMEOWPOINTS')}</div>
            </div>
          )}
          {screenWidth <= MEDIUM_SCREEN_WIDTH && renderBtnSubmitRequest()}
          {showModalRedeem && (
            <RedemptionModal
              show={showModalRedeem}
              setShow={setShowModalRedeem}
              chainSelected={chainSelected}
              selectedGame={selectedGame}
              createRequestRedeemStatus={createRequestRedeemStatus}
              sendingRequestStatus={sendingRequestStatus}
              confirmRequestStatus={confirmRequestStatus}
              claimTicketStatus={claimTicketStatus}
              handleClickRetryBtn={handleClickRetryBtn}
              handleClickCloseBtn={handleClickCloseBtn}
              handleResetStatus={handleResetStatus}
              showRetryBtn={showRetryBtn}
              redeemRequestPayload={redeemRequestPayload}
              listTicketType={listTicketType}
            />
          )}
          {showModalRedeemSuccess && (
            <ModalRedeemSuccess
              show={showModalRedeemSuccess}
              setShow={setShowModalRedeemSuccess}
              redeemRequest={redeemRequest}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default RedemptionTab;

function TableRedeemTicketItem({
  listRedeemTicket,
  setListRedeemTicket,
  itemIndex,
  listTicketDropdown,
  lastItemRedeem,
}) {
  const [ticketQuantity, setTicketQuantity] = useState(1);
  const [ticketSelected, setTicketSelected] = useState();
  useEffect(() => {
    const lastSelectedTicket = listTicketDropdown?.find(
      (item) => item?.id === lastItemRedeem?.ticketCollectionId,
    );
    if (listTicketDropdown?.length > 0 && !lastSelectedTicket) {
      setTicketSelected(listTicketDropdown[0]);
    }
    if (listTicketDropdown?.length > 0 && lastSelectedTicket) {
      setTicketSelected(lastSelectedTicket);
    }
  }, [listTicketDropdown, lastItemRedeem]);

  const incrementCount = () => {
    setTicketQuantity(ticketQuantity + 1);
  };

  const decrementCount = () => {
    if (ticketQuantity > 1) {
      setTicketQuantity(ticketQuantity - 1);
    } else {
      setTicketQuantity(1);
    }
  };

  const handleRemoveRedeemTicket = () => {
    if (listRedeemTicket.length === 1) return;
    const newListRedeemTicket = [...listRedeemTicket];
    newListRedeemTicket.splice(itemIndex, 1);
    setListRedeemTicket(newListRedeemTicket);
  };

  useEffect(() => {
    const currentTicketRedeem = {
      ticketCollectionId: ticketSelected?.id,
      quantity: ticketQuantity,
      ticketType: ticketSelected?.ticketType,
      points: ticketSelected?.points,
    };
    const newListRedeemTicket = [...listRedeemTicket];
    newListRedeemTicket[itemIndex] = currentTicketRedeem;
    setListRedeemTicket(newListRedeemTicket);
  }, [ticketSelected, ticketQuantity]);

  const ticketDropdownSelected = listTicketDropdown.find(
    (i) => i.id === listRedeemTicket[itemIndex].ticketCollectionId,
  );

  return (
    <div className="table-redeem-wrapper">
      <SelectTicketDropdown
        listDropdown={listTicketDropdown}
        showing={ticketDropdownSelected}
        setShowing={setTicketSelected}
      />
      <div className="quantity-garbage">
        <div className="quantity-wrapper">
          <div className="cursor-pointer" aria-hidden onClick={decrementCount}>
            <MinusBorderIcon />
          </div>
          <input
            className="input-quantity-number"
            placeholder="0"
            type="number"
            value={listRedeemTicket[itemIndex].quantity || ''}
            onChange={(e) => {
              setTicketQuantity(Number(e.target.value));
            }}
          />
          <div className="cursor-pointer" aria-hidden onClick={incrementCount}>
            <PlusBorderIcon />
          </div>
        </div>
        <GarbageIcon className="garbage-mobile cursor-pointer" onClick={handleRemoveRedeemTicket} />
      </div>
      <GarbageIcon className="garbage-web cursor-pointer" onClick={handleRemoveRedeemTicket} />
    </div>
  );
}
