import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { STATUS_TOURNAMENT_DETAIL, DATE_FORMAT, TYPE_TOURNAMENT } from '../../../constants';
import convertDate from '../../../utils/convertDate';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import { useAppDispatch } from '../../../store';
import './styles.scss';

function InfoTag(props) {
  const { tournament } = props;
  const dispatch = useAppDispatch();
  const [dateTitle, setDateTitle] = useState('');
  const [date, setDate] = useState('');
  const { t } = useTranslation();
  console.log('tournament: ', tournament);
  const getDate = () => {
    dispatch(showLoading);
    let displayDate;
    if (tournament.status === STATUS_TOURNAMENT_DETAIL.New) {
      displayDate = tournament.registration_deadline;
      setDateTitle('REGISTRATION DEADLINE');
    } else if (
      tournament.status === STATUS_TOURNAMENT_DETAIL.Live ||
      tournament.status === STATUS_TOURNAMENT_DETAIL.Done
    ) {
      displayDate = tournament.end_date;
      setDateTitle('END DATE');
    }
    setDate(convertDate(displayDate, DATE_FORMAT.cardTournament));
    dispatch(hideLoading);
  };

  useEffect(() => {
    getDate();
  }, [tournament.id]);

  return (
    <>
      <div className="info-wrapper">
        <div className="info-item">
          <div className="info-content">{`${tournament.prize_amount || 0} MEOW`}</div>
          <div className="info-title">{t('PRIZE TARGET')}</div>
        </div>
        {tournament.ticketCollection && (
          <div className="info-item">
            <div className="info-content">
              {tournament.ticketCollection?.points}{' '}
              {tournament.ticketCollection?.gamePoint?.pointSymbol} &#40;~
              {tournament.ticketCollection?.ticketType?.price}{' '}
              {tournament.ticketCollection?.paymentToken?.symbol}&#41;
            </div>
            <div className="info-title">{t('TICKET PRICE')}</div>
          </div>
        )}
        <div className="info-item">
          <div className="info-content">{tournament.applicationBracket ?? '-'}</div>
          <div className="info-title">{t('BRACKET TYPE')}</div>
        </div>
      </div>
      {tournament?.type !== TYPE_TOURNAMENT.INSTANT && (
        <div className="info-wrapper behind-infor-wrapper">
          <div className="info-item">
            <div className="info-content">
              {tournament.applicationMode?.teamSize ?? '-'} {t('Players')}
            </div>
            <div className="info-title">{t('PER TEAM')}</div>
          </div>
          <div className="info-item">
            <div className="info-content">{tournament.applicationRegion?.name ?? '-'}</div>
            <div className="info-title">{t('GAME REGION')}</div>
          </div>
          <div className="info-item">
            <div className="info-content">{date}</div>
            <div className="info-title">{t(dateTitle)}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default InfoTag;
