import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './style.scss';
import BigNumber from 'bignumber.js';
import TButton from '../../../components/common/Button';
import DonutChart from '../DonutChart';
import SelectGame from '../../../components/common/SelectCustom/SelectGame';
import referralApi from '../../../services/referral';
import formatPointNumber from '../../../lib/formatPointNumber';
import ShowTxHash from '../../../components/common/ShowTxHash/ShowTxHash';
import shortenAddress, { shortenString } from '../../../utils/shortenAddress';
import { GAME_TYPE } from '../../../constants';

function GameOverview({ listGame = [] }) {
  const { t } = useTranslation();
  const [gameFilter, setGameFilter] = useState();
  const history = useHistory();
  const [listReferralPoint, setListReferralPoint] = useState();
  const getReferralPoint = async () => {
    if (gameFilter) {
      const res = await referralApi.getReferralPoints(gameFilter);
      setListReferralPoint(res.data);
    }
  };

  useEffect(() => {
    if (listGame?.length > 0) {
      setGameFilter(listGame[0]?.id);
    }
  }, [listGame]);

  useEffect(() => {
    if (gameFilter) {
      getReferralPoint();
    }
  }, [gameFilter]);

  const totalEarning = (listReferralPoint?.contributors || []).reduce(
    (total, contributor) =>
      new BigNumber(total).plus(new BigNumber(contributor?.amount || 0)).toNumber(),
    0,
  );

  return (
    <div
      className={`game-overview-wrapper ${
        totalEarning ? 'three-column-game-wrapper' : 'two-column-game-wrapper'
      }`}
    >
      <div className="total-earning-wrapper">
        <SelectGame
          items={listGame}
          selectedItem={gameFilter}
          setSelectedItem={setGameFilter}
          classNameDropdown="btn-select-game"
          type={GAME_TYPE}
          textDefault={t('referral.selectGame')}
        />
        <div className="btn-redeem-rewards-wrapper">
          <TButton
            title={t('redeemRewards.title')}
            className="btn-redeem-reward"
            onClick={() => {
              history.push('/redeem-reward');
            }}
          />
        </div>
      </div>
      <div className="total-earning-wrapper">
        <div className="total-earning-title">
          <p className="referral-small-title">{t('myPrize.totalEarnings')}</p>
          <h4>
            {listReferralPoint?.totalReward
              ? formatPointNumber(listReferralPoint?.totalReward)
              : '-'}{' '}
            <span className="small-point">{listReferralPoint?.tokenSymbol}</span>
          </h4>
        </div>
        <DonutChart
          amountData={listReferralPoint?.summaries}
          totalNumber={listReferralPoint?.totalReward}
          title={t('myPrize.earningContributions')}
        />
      </div>
      {Number(totalEarning) ? (
        <div className="total-earning-wrapper">
          <p className="referral-small-title">{t('referral.topReferrals')}</p>
          <div className="list-referral-point">
            {listReferralPoint?.contributors?.map((i) => (
              <ReferralPointItem key={i?.id} data={i} />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default GameOverview;

function ReferralPointItem({ data }) {
  const renderName = () => {
    if (data?.lastName) {
      return (
        <div className="point-item">{shortenString(`${data?.firstName} ${data?.lastName}`)}</div>
      );
    }
    return (
      <div className="point-item">
        {String(data?.from).includes('@') ? (
          shortenAddress(data?.from, 4)
        ) : (
          <ShowTxHash txLength={2} txHash={data?.from} />
        )}
      </div>
    );
  };

  return Number(data?.amount) ? (
    <div className="referral-point-item">
      {renderName()}
      <div className="point-item">
        {Number(data?.amount) ? formatPointNumber(data?.amount) : '-'} {data?.tokenSymbol}
      </div>
    </div>
  ) : null;
}
