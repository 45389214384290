import React from 'react';

function WarningIcon({ width = 77, height = 71, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 77 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.834 5.184h0C33.562 2.087 35.882.5 38.307.5s4.736 1.587 6.474 4.685l29.87 53.4h0c1.716 3.063 1.886 5.895.605 8.071-1.281 2.179-3.86 3.444-7.409 3.444h-59.1c-3.544 0-6.108-1.283-7.4-3.447-1.288-2.154-1.12-5.004.597-8.069h0l29.89-53.4z"
        fill="#FACC15"
        stroke="#000"
      />
      <path
        d="M38.307 53.91a3.93 3.93 0 00-3.94 3.93 3.931 3.931 0 003.94 3.94 3.94 3.94 0 003.94-3.94 3.94 3.94 0 00-3.94-3.93zM38.308 49.31c1.44 0 2.73-1.77 2.85-3.93l1.51-25.62a3.68 3.68 0 00-3.71-3.93h-1.31a3.68 3.68 0 00-3.71 3.93l1.51 25.62c.13 2.16 1.4 3.93 2.86 3.93z"
        fill="#000"
      />
    </svg>
  );
}

export default WarningIcon;
