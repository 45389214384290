import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ERR_MESSAGE, READ_NOTIFY_STATUS } from '../../../constants';
import userApis from '../../../services/user';
import useMessage from '../toast/UseMessage';
import NotiItem from './NotiItem';
import './styles.scss';
import { useAuthContext } from '../../../context/AuthContext';

function NotificationIcon(props) {
  const { img, className } = props;
  const [show, setShow] = useState(false);
  const [notifications, setNotification] = useState([]);
  const [countNotify, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoadMore, setLoadMore] = useState(false);
  const NotifyRef = useRef(null);
  const history = useHistory();
  const TRANSFER_SITE = 'https';
  const { isLogin } = useAuthContext();
  const { t } = useTranslation();

  const { openMessageError } = useMessage();

  const maxShowCountNotify = 9;

  useEffect(() => {
    function handleClickOutside(event) {
      if (NotifyRef.current && !NotifyRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [NotifyRef]);

  const getNotification = async () => {
    const params = {
      page,
    };
    try {
      const res = await userApis.getNotification(params);
      const { data } = res;
      setNotification(data?.notification);
      setCount(data?.totalNotifyUnread);
    } catch (error) {
      openMessageError(error.response.data || error.response.data?.message || ERR_MESSAGE);
    }
  };

  useEffect(() => {
    if (isLogin) {
      getNotification();
    }
  }, []);

  const readNotification = async (id, index) => {
    const data = {
      notificationId: id,
    };
    try {
      await userApis.readNotification(data);
      const newNotifications = [...notifications];
      newNotifications[index].NotificationUser.read = READ_NOTIFY_STATUS;
      setNotification(newNotifications);
      if (countNotify > 0) {
        setCount(countNotify - 1);
      }
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    }
  };

  const handleReadNotification = (id, index, link) => {
    readNotification(id, index);
    if (link.includes(TRANSFER_SITE)) {
      window.open(link, '_blank');
    } else {
      history.push(link);
    }
  };

  const loadMoreNotification = async () => {
    setLoadMore(true);
    const params = {
      page: page + 1,
    };
    try {
      const res = await userApis.getNotification(params);
      const { data } = res;
      if (data?.notification?.length > 0) {
        setPage(page + 1);
        setNotification([...notifications, ...data.notification]);
      }
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      setLoadMore(false);
    }
  };

  const handleScroll = async () => {
    const el = document.getElementById('wrap-scroll-notify');
    const isCheckScroll = el.scrollTop + el.clientHeight + 1 >= el.scrollHeight;
    if (isCheckScroll && !isLoadMore) {
      loadMoreNotification();
    }
  };

  return (
    <div
      className={show ? 'sub-notification sub-notification-hover' : 'sub-notification'}
      onClick={() => setShow(true)}
      aria-hidden
      style={{ zIndex: '1000' }}
    >
      <img src={img} alt="icon" />
      <div
        className={`wrap-content-notify ${className}`}
        style={show ? { display: 'block' } : { display: 'none' }}
        ref={NotifyRef}
      >
        <h6 className="color-primary mb-3">{t(`notification.title`)}</h6>
        <div className="wrap-scroll-notify" onScroll={handleScroll} id="wrap-scroll-notify">
          {notifications.length === 0 && (
            <h6 className="d-flex justify-content-center" style={{ width: '300px' }}>
              {t(`notification.message`)}
            </h6>
          )}
          {notifications.length > 0 &&
            notifications.map((item, index) => (
              <NotiItem
                data={item}
                key={item.id}
                onClick={() => handleReadNotification(item.id, index, item.link)}
              />
            ))}
        </div>
      </div>
      {countNotify > 0 && (
        <div className="wrap-count-notify">
          {countNotify > maxShowCountNotify ? '9+' : countNotify}
        </div>
      )}
    </div>
  );
}

export default NotificationIcon;
