import React from 'react';

function FlagIcon(props) {
  return (
    <svg
      width={41}
      height={40}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.161 8.188a.427.427 0 00-.302-.523L11.807 3.632a.427.427 0 00-.523.302L7.14 19.399a.427.427 0 00.302.523l9.898 2.652a.427.427 0 01.302.522l-.111.413a.427.427 0 00.302.523l15.67 4.199a.427.427 0 00.524-.302l4.143-15.465a.427.427 0 00-.301-.523L27.353 9.123a.427.427 0 01-.302-.522l.11-.413z"
        fill="#0FF"
      />
      <path
        d="M11.395 3.522a1.921 1.921 0 013.712.995l-8.565 31.96a1.921 1.921 0 11-3.712-.995l8.565-31.96z"
        fill="#0FF"
      />
    </svg>
  );
}

export default FlagIcon;
