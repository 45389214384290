import FormData from 'form-data';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import TButton from '../../../components/common/Button';
import InputField from '../../../components/common/Input';
import ModalDelete from '../../../components/common/Modal/ModalDelete';
import NewLayout from '../../../components/common/NewLayout/NewLayout';
import TablePlayer from '../../../components/common/Table/TablePlayer';
import useMessage from '../../../components/common/toast/UseMessage';
import SearchCard from '../../../components/pages/MyTeam/SearchCard';
import {
  ERR_MESSAGE,
  DELETE_TEAM_MEMBER_MESSAGE,
  DELETE_TEAM_INVITE_MESSAGE,
  EVENT__KEY,
} from '../../../constants';
import tournamentApi from '../../../services/tournament';
import { useAppDispatch } from '../../../store';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import './styles.scss';

function UpdateMyTeam(props) {
  const { t } = useTranslation();
  const { dataConnect } = props;
  const [name, setName] = useState('');
  const [isOwner, setIsOwner] = useState();
  const [searchInviteMember, setSearchInviteMember] = useState('');
  const [email, setEmail] = useState('');
  const history = useHistory();
  const { openMessageError, openMessage } = useMessage();
  const dispatch = useAppDispatch();
  const [pendingMember, setPendingMember] = useState([]);
  const [inviteMember, setInviteMember] = useState([]);
  const [currentMember, setCurrentMember] = useState([]);
  const [newInviteMember, setNewInviteMember] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { id } = useParams();
  const [isShow, setIsShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const getPendingMember = async () => {
    dispatch(showLoading());
    const params = {
      teamId: id,
    };
    try {
      const res = await tournamentApi.pendingMember(params);
      const { data } = res;
      setPendingMember(data);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const getDetail = async () => {
    dispatch(showLoading());
    try {
      const { data } = await tournamentApi.getMyTeamDetail(id);
      if (!data.data.isOwner) {
        history.push(`/my-team/${id}`);
      }
      setName(data.data.name);
      setIsOwner(data.data.isOwner);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const getCurrentMember = async () => {
    dispatch(showLoading());
    const params = { name: '' };
    try {
      const res = await tournamentApi.getCurrentMember(params, id);
      const { data } = res;
      setCurrentMember(data);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const getInviteMember = async () => {
    dispatch(showLoading());
    const params = {
      teamId: Number(id),
      name: searchInviteMember,
      limit: 12,
      page: currentPage,
    };
    try {
      const res = await tournamentApi.getInviteMembers(params);
      const { data } = res;
      setInviteMember(data.data);
      setTotalPage(data.totalPage);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleUpdateTeam = async () => {
    if (!name) return;
    dispatch(showLoading());
    const dataForm = new FormData();
    dataForm.append('teamId', id);
    dataForm.append('name', name);
    const userIds = newInviteMember.filter((item) => item.id).map((item) => item.id);
    const payloadMember = {
      teamId: Number(id),
      userIds,
    };
    try {
      await tournamentApi.updateMyTeam(dataForm);
      if (userIds.length) {
        await tournamentApi.inviteMember(payloadMember);
      }
      openMessage({ message: t('success.updateTeamSuccessful') });
      history.push('/my-team');
    } catch (error) {
      openMessageError(error.response.data || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleDelete = async () => {
    dispatch(showLoading());
    try {
      await tournamentApi.deleteMyTeam(Number(id));
      openMessage({ message: t('success.deleteTeamSuccessful') });
      history.push('/my-team');
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const inviteEmail = async () => {
    const data = {
      teamId: Number(id),
      emails: [email],
    };
    dispatch(showLoading());
    try {
      await tournamentApi.inviteEmail(data);
      openMessage({ message: t('success.sendTeamSuccessful') });
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const goToMyTeams = () => {
    history.push('/my-team');
  };
  const handleRemovePendingMember = async (e, value) => {
    e.stopPropagation();
    dispatch(showLoading());
    const data = {
      teamId: Number(id),
      userId: value,
    };
    try {
      await tournamentApi.rejectPendingMember(data);
      getPendingMember();
      openMessage({ message: DELETE_TEAM_INVITE_MESSAGE });
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleRemoveCurrentMember = async (e, value) => {
    e.stopPropagation();
    dispatch(showLoading());
    const params = {
      teamId: Number(id),
      userId: value,
    };
    try {
      await tournamentApi.rejectCurrentMember(params);
      getCurrentMember();
      openMessage({ message: DELETE_TEAM_MEMBER_MESSAGE });
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const resetInviteMember = async () => {
    dispatch(showLoading());
    try {
      const params = {
        teamId: Number(id),
        name: '',
        limit: 12,
        page: 1,
      };
      const res = await tournamentApi.getInviteMembers(params);
      const { data } = res;
      setInviteMember(data.data);
      setTotalPage(data.totalPage);
      setCurrentPage(1);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleOnChangeSearch = (value) => {
    setSearchInviteMember(value);
    if (!value) {
      resetInviteMember();
    }
  };
  const handleSearchKeyDown = (event) => {
    if (event.key === EVENT__KEY.ENTER) {
      getInviteMember();
    }
  };
  const handleLoadMore = async () => {
    dispatch(showLoading());
    try {
      const params = {
        teamId: Number(id),
        limit: 12,
        name: searchInviteMember,
        page: Number(currentPage + 1),
      };
      const res = await tournamentApi.getInviteMembers(params);
      const { data } = res;
      setCurrentPage(currentPage + 1);
      setInviteMember([...inviteMember, ...data.data]);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    getCurrentMember();
    getDetail();
  }, []);
  useEffect(() => {
    if (isOwner) {
      getPendingMember();
      getInviteMember();
    }
  }, [isOwner]);
  useEffect(() => {
    if (currentPage === totalPage) {
      setIsChecked(true);
    }
  }, [currentPage]);
  return (
    <NewLayout
      dataConnect={dataConnect}
      title={t('team.updateTeam')}
      type={1}
      link={`/my-team/${id}`}
    >
      <div className="update-team">
        <div className="update-name d-flex">
          <div />
          <TButton title={t('save')} onClick={handleUpdateTeam} className="btn-update" />
        </div>
        <div className="input-name">
          <InputField
            title={t('team.teamName')}
            type="text"
            className="input-update-name"
            value={name}
            onChange={setName}
          />
        </div>
        <TablePlayer
          title={t('team.currentMembers')}
          member={currentMember}
          handleRemove={handleRemoveCurrentMember}
        />
        <TablePlayer
          title={t('team.pendingInvites')}
          member={pendingMember}
          handleRemove={handleRemovePendingMember}
        />
        <SearchCard
          title={t('team.findExistingPlayers')}
          search={searchInviteMember}
          onChange={handleOnChangeSearch}
          onKeyDown={handleSearchKeyDown}
          inviteMember={inviteMember}
          newInviteMember={newInviteMember}
          setNewInviteMember={setNewInviteMember}
          handleSearchIconPress={getInviteMember}
          handleLoadMore={handleLoadMore}
          isChecked={isChecked}
        />
        <div className="invite-email">
          <div className="input-invite-email">
            <InputField
              placeholder={t('referral.enterEmailAddress')}
              title={t('referral.invitePlayersByEmail')}
              value={email}
              onChange={setEmail}
            />
            <TButton onClick={inviteEmail} type="reverse" title={t('referral.invite')} />
          </div>
        </div>
        <div className="btn-my-team-and-delete">
          <TButton
            title={t('team.goMyTeams')}
            onClick={goToMyTeams}
            className="btn-radius btn-my-team"
          />
          <TButton
            title={t('team.deleteTeam')}
            className="btn-delete-team"
            onClick={() => setIsShow(true)}
            type="delete"
          />
        </div>
      </div>
      <ModalDelete
        show={isShow}
        setShow={setIsShow}
        title={t('team.deleteTeams')}
        description={t('team.confirm')}
        handleDelete={handleDelete}
        indexDelete={id}
      />
    </NewLayout>
  );
}

export default UpdateMyTeam;
