import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import JSCookie from 'js-cookie';
import { browserRedirectToIndexAfterSignOut } from '../lib/redirect';

const AuthContext = createContext({
  isLogin: false,
  isCreatedProfile: true,
  isLinkedEmaiAddress: true,
  checkLogin: () => null,
  handleLogOut: () => null,
  checkCreatedProfile: () => null,
  toggleModalLoginOpen: () => null,
  toggleModalLoginClose: () => null,
  isOpenModalLogin: false,
  checkLinkedEmailAddress: () => null,
});

function AuthProvier({ children }) {
  const [isLogin, setIsLogin] = useState(false);
  const [isCreatedProfile, setIsCreatedProfile] = useState(true);
  const [isOpenModalLogin, setIsOpenModalLogin] = useState(false);
  const [isLinkedEmaiAddress, setIsLinkedEmaiAddress] = useState(true);

  const { connector } = useWeb3React();

  const handleLogOut = () => {
    JSCookie.remove('token');
    JSCookie.remove('email');
    JSCookie.remove('walletAccount');
    JSCookie.remove('expiresIn');
    JSCookie.remove('refreshToken');
    JSCookie.remove('isCreateProfile');
    JSCookie.remove('isLinkedWithEmaiAddress');

    if (connector?.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
    localStorage.clear();
    browserRedirectToIndexAfterSignOut();
  };

  const checkLogin = () => {
    const refreshToken = JSCookie.get('refreshToken');
    if (refreshToken) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  };

  const checkCreatedProfile = () => {
    const isCreatedProfile = JSCookie.get('isCreateProfile');
    if (isCreatedProfile === 'false') {
      setIsCreatedProfile(false);
    } else {
      setIsCreatedProfile(true);
    }
  };

  const checkLinkedEmailAddress = () => {
    const isLinkedEmaiAddress = JSCookie.get('isLinkedWithEmaiAddress');
    if (isLinkedEmaiAddress === 'false') {
      setIsLinkedEmaiAddress(false);
    } else {
      setIsLinkedEmaiAddress(true);
    }
  };

  const toggleModalLoginOpen = () => {
    setIsOpenModalLogin(true);
  };

  const toggleModalLoginClose = () => {
    setIsOpenModalLogin(false);
  };

  useEffect(() => {
    checkLogin();
    checkCreatedProfile();
    checkLinkedEmailAddress();
  }, []);

  const contextValue = useMemo(
    () => ({
      isLogin,
      isCreatedProfile,
      isLinkedEmaiAddress,
      isOpenModalLogin,
      checkLogin,
      handleLogOut,
      checkCreatedProfile,
      toggleModalLoginOpen,
      toggleModalLoginClose,
      checkLinkedEmailAddress,
    }),
    [isLogin, isCreatedProfile, isLinkedEmaiAddress, isOpenModalLogin],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

const useAuthContext = () => useContext(AuthContext);

export { AuthContext, AuthProvier, useAuthContext };
