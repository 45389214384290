import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TOURNAMENT } from '../../../../assets/imgs/index';
import { STATUS_TOURNAMENT_DETAIL } from '../../../constants/index';
import './styles.scss';
import { BRACKETS_STRING } from '../../../constants/bracket';
import { getTranslatedValue } from '../../../lib/getTranslatedValue';

function TournamentItem(props) {
  const { tournament } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [status, setStatus] = useState('');
  const [statusClass, setStatusClass] = useState('');

  useEffect(() => {
    switch (tournament.status) {
      case STATUS_TOURNAMENT_DETAIL.Live:
        setStatusClass('live');
        setStatus(t('Live'));
        break;
      case STATUS_TOURNAMENT_DETAIL.Done:
        setStatusClass('past');
        setStatus(t('Done'));
        break;
      case STATUS_TOURNAMENT_DETAIL.New:
        setStatusClass('new');
        setStatus(t('New'));
        break;
      default:
    }
  }, []);

  const renderDate = (date) => {
    const formattedDate = moment(new Date(date)).format('DD MMM YYYY');
    return formattedDate;
  };

  return (
    <tbody className="tournament-body-wrapper">
      <tr className="tournament-row-wrapper">
        <td
          className="wrapper-column tournament"
          data-label={t('header.tournaments')}
          aria-hidden="true"
          onClick={() => history.push(`/tournament/${tournament?.slug}`)}
        >
          <div className="tournament-info-wrapper">
            <div className="tournament-image">
              <img
                src={tournament?.image ?? TOURNAMENT.noImage}
                alt="dgg-network"
                className="game-image"
              />
            </div>
            <h6 className="tournament-title">{getTranslatedValue(tournament, 'name')}</h6>
          </div>
        </td>
        <td className="wrapper-column status" data-label={t('status.default')}>
          <h6 className={`tournament-status text-center ${statusClass}`}>{t(status)}</h6>
        </td>
        <td className="wrapper-column start-date" data-label={t('filter.start')}>
          <h6 className="tournament-startDate text-center">{renderDate(tournament?.start_date)}</h6>
        </td>
        <td className="wrapper-column end-date" data-label={t('filter.end')}>
          <h6 className="tournament-startDate text-center">
            {tournament?.applicationBracket === BRACKETS_STRING.BATTLE_ROYALE
              ? ''
              : renderDate(tournament?.end_date)}
          </h6>
        </td>
        <td className="wrapper-column prize" data-label={t('Network')}>
          <h6 className="tournament-prize text-right">
            {tournament?.applicationNetwork?.network_name || '---'}
          </h6>
        </td>
        <td className="wrapper-column prize" data-label={t('prize')}>
          <h6 className="tournament-prize text-right">
            {`${tournament?.prize_amount || 0} ${tournament?.gamePoint}`}
          </h6>
        </td>
      </tr>
    </tbody>
  );
}

export default TournamentItem;
