import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import NFTRental from './NFTRental';
import RentalRequests from './RentalRequests';
import './styles.scss';

function RentalComponent(props) {
  const { id, account, tokenAccess, getTag, urlMarket, slug } = props;

  const history = useHistory();
  const { t } = useTranslation();
  const [tag, setTag] = useState(1);

  const tags = [
    { name: t('nft.myNFT'), id: 1 },
    { name: t('nft.nftRequest'), id: 2 },
  ];

  useEffect(() => {
    getTag(tag);
  }, [tag]);

  useEffect(() => {
    const query = queryString.parse(history.location.search);
    if (!Number(query.tag)) return;
    setTag(Number(query.tag));
  }, []);

  return (
    <div className="wrap-my-nfts-rental">
      <div className="mb-3 d-flex">
        {tags.map((item) => (
          <h4
            key={item.id}
            className={
              tag === item.id
                ? 'tag-rentals color-primary cursor-pointer tag-active-rentals'
                : 'tag-rentals color-second cursor-pointer'
            }
            onClick={() => setTag(item.id)}
            aria-hidden="true"
          >
            {item.name}
          </h4>
        ))}
      </div>
      {tag === 1 && slug ? (
        <NFTRental
          id={id}
          account={account}
          tokenAccess={tokenAccess}
          urlMarket={urlMarket}
          slug={slug}
        />
      ) : (
        <RentalRequests id={id} account={account} tokenAccess={tokenAccess} />
      )}
    </div>
  );
}

export default RentalComponent;
