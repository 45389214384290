import React from 'react';

function StatisticIcon(props) {
  return (
    <svg
      width={41}
      height={40}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M28.126 3.334h-15.57c-5.675 0-8.89 3.215-8.89 8.889v15.555c0 5.675 3.215 8.89 8.89 8.89h15.57c5.674 0 8.874-3.215 8.874-8.89V12.223c0-5.674-3.2-8.889-8.874-8.889"
        fill="#0FF"
      />
      <path
        d="M12.614 15.615c-.755 0-1.377.623-1.377 1.393V28.46a1.385 1.385 0 002.77 0V17.008c0-.77-.622-1.393-1.393-1.393M20.392 10.148c-.755 0-1.377.623-1.377 1.393v16.918a1.385 1.385 0 002.77 0V11.541c0-.77-.622-1.393-1.393-1.393M28.066 21.66c-.77 0-1.392.622-1.392 1.393v5.407a1.385 1.385 0 002.77 0v-5.407c0-.77-.622-1.393-1.378-1.393"
        fill="#0FF"
      />
    </svg>
  );
}

export default StatisticIcon;
