import React, { useEffect, useRef, useState } from 'react';
import ArrowDownIcon from '../../icons/ArrowDownIcon';
import EnFlagIcon from '../../icons/EnFlagIcon';
import VnFlagIcon from '../../icons/VnFlagIcon';
import './styles.scss';
import { useLanguageContext } from '../../../context/LanguageContext';

const LIST_LANGUAGE_SUPPORTED = [
  { id: 1, name: 'EN', code: 'en', logo: <EnFlagIcon /> },
  { id: 2, name: 'VI', code: 'vi', logo: <VnFlagIcon /> },
];

function SelectLanguageDropdown() {
  const { selectedLanguage } = useLanguageContext();
  const languageNode = useRef();
  const [isOpenLanguageDropdown, setIsOpenLanguageDropdown] = useState(false);

  const toggleOpenLanguageDropdown = () => {
    if (LIST_LANGUAGE_SUPPORTED?.length > 0) {
      setIsOpenLanguageDropdown(!isOpenLanguageDropdown);
    }
  };

  const handleClickOutside = (e) => {
    // @ts-ignore
    if (languageNode.current?.contains(e.target)) {
      return;
    }
    setIsOpenLanguageDropdown(false);
  };

  useEffect(() => {
    if (isOpenLanguageDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenLanguageDropdown]);

  return (
    <div className="select-language-custom" ref={languageNode}>
      <div className="select" onClick={toggleOpenLanguageDropdown} aria-hidden>
        <div className="select-language-wrapper">
          {selectedLanguage?.logo}
          <p className="title-name">{selectedLanguage?.name}</p>
        </div>
        <div className={`${isOpenLanguageDropdown && 'rotate-180'} smooth-transform`}>
          <ArrowDownIcon width={20} className="arrow-down" />
        </div>
      </div>
      <div className="list-item">
        <div className={`dropdown-body ${isOpenLanguageDropdown && 'open'}`}>
          {LIST_LANGUAGE_SUPPORTED?.map((i) => (
            <LanguageItemFilter
              key={i.id}
              data={i}
              showing={selectedLanguage}
              toggleOpen={setIsOpenLanguageDropdown}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default SelectLanguageDropdown;

function LanguageItemFilter({ data, showing, toggleOpen }) {
  const { switchSelectLanguage } = useLanguageContext();
  return (
    <div
      onClick={() => {
        switchSelectLanguage(data);
        toggleOpen(false);
      }}
      aria-hidden
      className={`language-item-wrapper smooth-transform cursor-pointer ${
        data?.code === showing?.code ? 'bg-secondary' : 'bg-transparent'
      }`}
    >
      {data?.logo}
      <p className="language-name">{data?.name}</p>
    </div>
  );
}
