import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import TButton from '../Button/index';
import './styles.scss';

function ModalDelete(props) {
  const { show, setShow, title, handleDelete, indexDelete, description } = props;
  const deleteRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    function handleClickOutside(event) {
      if (deleteRef.current && !deleteRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [deleteRef]);

  const handleConfirm = async () => {
    await handleDelete(indexDelete);
    setShow(false);
  };
  const handleCancel = () => {
    setShow(false);
  };
  return (
    <div className={show ? 'wrap-modal-custom d-block' : 'wrap-modal-custom'} ref={deleteRef}>
      <h6 className="color-primary mb-2">{title}</h6>
      <h6 className="mb-3">{description}</h6>
      <Row>
        <Col>
          <TButton title={t('yes')} onClick={handleConfirm} width="full-width" />
        </Col>
        <Col>
          <TButton title={t('no')} onClick={handleCancel} width="full-width" type="reverse" />
        </Col>
      </Row>
    </div>
  );
}

export default ModalDelete;
