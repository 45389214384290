import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../Input';
import TButton from '../Button';

function ModalDonate(props) {
  const { show, setShow, sendDonate, symbolToken, id } = props;
  const loginRef = useRef(null);
  const [numberToken, setNumberToken] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    function handleClickOutside(event) {
      if (loginRef.current && !loginRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [loginRef]);
  useEffect(() => {
    setNumberToken('');
  }, [id]);
  return (
    <div className={show ? 'wrap-modal-custom donate d-block' : 'wrap-modal-custom'} ref={loginRef}>
      <h6 className="color-primary mb-2" style={{ fontSize: '24px' }}>
        {t('donate.donateToPrizePool')}
      </h6>

      <div className="wrap-group-email modal-donate">
        <div className="d-flex align-items-center wrap-otp mt-3">
          <InputField
            className="input-email-otp"
            title={t('donate.donateAmount')}
            type="number"
            isRequired
            placeholder={t('donate.amount')}
            onChange={setNumberToken}
            value={numberToken}
            maxLength={6}
          />
          <div className="coin-tournament" aria-hidden style={{ width: '100px' }}>
            {symbolToken}
          </div>
        </div>
      </div>

      <TButton
        title={t('donate.default')}
        className="mb-3"
        width="full-width"
        onClick={() => sendDonate(numberToken, setNumberToken)}
      />
      <TButton
        title={t('donate.cancel')}
        className="mb-2"
        width="full-width"
        type="reverse"
        onClick={() => setShow(false)}
      />
    </div>
  );
}

export default ModalDonate;
