import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import './styles.scss';

function ModalConfirm(props) {
  const { show, setShow, title, handleConfirm, indexConfirm, description } = props;
  const confirmRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    function handleClickOutside(event) {
      if (confirmRef.current && !confirmRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [confirmRef]);

  const handleAccept = async () => {
    await handleConfirm(indexConfirm);
    setShow(false);
  };
  const handleCancel = () => {
    setShow(false);
  };
  return (
    <div className={show ? 'wrap-modal-custom d-block' : 'wrap-modal-custom'} ref={confirmRef}>
      <h6 className="color-primary mb-2">{title}</h6>
      <h6 className="mb-3">{description}</h6>
      <Row>
        <Col>
          <div className="btn-stroke-confirm" onClick={handleAccept} aria-hidden>
            {t('yes')}
          </div>
        </Col>
        <Col>
          <div className="btn-stroke-confirm" onClick={handleCancel} aria-hidden>
            {t('no')}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ModalConfirm;
