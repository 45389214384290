import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { CreateRequestStatus } from '../constants';
import useSwitchNetwork from './useSwitchNetwork';
import useActiveWeb3React from './useActiveWeb3React';
import tournamentApi from '../services/tournament';

export default function useCreateRequestRedeem({ setStatus, setRedeemRequest, chainId }) {
  const { switchNetwork } = useSwitchNetwork();
  const { account: signerAddress } = useActiveWeb3React(chainId);

  const createRequestRedeem = useCallback(
    async ({ redeemRequest }) => {
      try {
        if (!redeemRequest) {
          toast.error('Please close modal and try again later');
          return;
        }
        setStatus(CreateRequestStatus.PENDING);
        await switchNetwork(chainId);
        const { data } = await tournamentApi.sendRedeemRequest(redeemRequest);
        setRedeemRequest(data);
        if (data) {
          setStatus(CreateRequestStatus.CREATED);
        } else {
          setStatus(CreateRequestStatus.FAILED);
        }
      } catch (error) {
        console.log('Create Request Redeem Error: ', JSON.stringify(error));
        if (error.message.includes('user rejected transaction')) {
          toast.error('Oops, you have rejected request');
          setStatus(CreateRequestStatus.REJECTED);
        } else {
          toast.error(
            error?.response?.data?.message ||
              error?.data?.message ||
              error?.reason ||
              error?.message ||
              'Failed to create request. Please try again later',
          );
          setStatus(CreateRequestStatus.FAILED);
        }
      }
    },
    [chainId, signerAddress],
  );

  return { createRequestRedeem };
}
