import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';

const maxEmail = 254;
const minPhone = 10;
const maxByte = 3 * 1000 * 1000;

export const validateEmail = (email) => {
  if (isEmpty(email)) {
    return 'Email is empty!';
  }
  if (!isEmail(email)) {
    return 'Invalid email!';
  }
  if (email.length > maxEmail) {
    return 'Email is longer than 254 characters!';
  }
  return '';
};

export const validateEmpty = (name, value) => {
  if (isEmpty(value)) {
    return `${name} is empty!`;
  }
  return '';
};

export const validateText = (name, value, maxLength, isRequired = true) => {
  if (isEmpty(value) && isRequired) {
    return `${name} is empty!`;
  }
  if (value.length > maxLength) {
    return `${name} is longer than 50 characters!`;
  }
  return '';
};

export const validatePhone = (phone) => {
  if (isEmpty(phone)) {
    return 'Phone is empty!';
  }
  if (phone.length < minPhone) {
    return 'Invalid phone';
  }
  return '';
};

export const validateFileImage = (file, isRequired = true) => {
  const types = ['image/png', 'image/jpeg'];
  if (!file && isRequired) {
    return 'File is empty!';
  }
  if (file) {
    if (!file.type.includes(types[0]) && !file.type.includes(types[1])) {
      return 'Upload file in .png .jpeg . format';
    }
    if (file?.size > maxByte) {
      return 'File larger than 3MB';
    }
  }
  return '';
};

export const validateMaxLength = (name, value, length) => {
  if (value.length > length) {
    return `Max length ${name} is ${length}!`;
  }
  return '';
};

const regexEmail =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export function validateNewEmail(email) {
  return RegExp(regexEmail).exec(String(email).toLowerCase());
}

export const validateInvitedCode = (referralCode, invitedCode) => {
  if (referralCode === invitedCode) {
    return "You can't input your referral code";
  }
  return '';
};
