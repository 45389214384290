import i18n from '../i18n/i18n';

export const getTranslatedValue = (obj, fieldTranslate) => {
  try {
    const languageField = `${fieldTranslate}_${i18n.language}`;

    if (Object.hasOwn(obj, languageField)) {
      return obj[languageField];
    }

    return obj[fieldTranslate] || '';
  } catch (error) {
    console.log('error: ', error);
    return obj[fieldTranslate] || '';
  }
};

export default null;
