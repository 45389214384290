import React from 'react';
import { Col, Row } from 'reactstrap';
import './styles.scss';

const GroupBracket = ({ item }) => (
  <div className="group w-50 mb-5">
    <Row className="p-3 title-group">
      <Col style={{ color: '#000', fontWeight: 'bold' }} sm={1}>
        #
      </Col>
      <Col style={{ color: '#000', fontWeight: 'bold' }} sm={3}>
        GROUP
      </Col>
      <Col style={{ color: '#000', fontWeight: 'bold' }} sm={1}>
        W
      </Col>
      <Col style={{ color: '#000', fontWeight: 'bold' }} sm={1}>
        D
      </Col>
      <Col style={{ color: '#000', fontWeight: 'bold' }} sm={1}>
        L
      </Col>
      <Col style={{ color: '#000', fontWeight: 'bold' }} sm={1}>
        PTS
      </Col>
    </Row>
    {item.map((data, index) => (
      <Row className="p-3" key={String(data.teamName + index)}>
        <Col style={{ color: '#fff' }} sm={1}>
          {index + 1}
        </Col>
        <Col style={{ color: '#fff' }} sm={3}>
          {data?.teamName}
        </Col>
        <Col style={{ color: '#fff' }} sm={1}>
          {data?.winMatch}
        </Col>
        <Col style={{ color: '#fff' }} sm={1}>
          {data?.drawMatch}
        </Col>
        <Col style={{ color: '#fff' }} sm={1}>
          {data?.loseMatch}
        </Col>
        <Col style={{ color: '#fff' }} sm={1}>
          {data?.point}
        </Col>
      </Row>
    ))}
  </div>
);

export default GroupBracket;
