import React from 'react';

function CupIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M15.243 17.794h-.917v-.229a1.839 1.839 0 00-1.833-1.833h-2.062v-2.1c-.23.028-.458.037-.688.037-.229 0-.458-.009-.687-.036v2.099H6.994a1.839 1.839 0 00-1.834 1.833v.23h-.916a.692.692 0 00-.688.687c0 .375.312.687.688.687h10.999a.692.692 0 00.687-.687.692.692 0 00-.687-.688zM3.803 9.668a4.484 4.484 0 01-1.558-1.026C1.392 7.697.833 6.57.833 5.25A2.332 2.332 0 013.19 2.895h.513a3.623 3.623 0 00-.376 1.604v2.75c0 .86.165 1.677.477 2.42zM18.653 5.25c0 1.32-.56 2.447-1.412 3.392a4.484 4.484 0 01-1.558 1.026 6.225 6.225 0 00.477-2.42v-2.75c0-.577-.138-1.118-.376-1.603h.513a2.332 2.332 0 012.356 2.355z"
        fill="#fff"
      />
      <path
        d="M12.493.834h-5.5A3.665 3.665 0 003.328 4.5v2.75a6.411 6.411 0 006.416 6.416A6.411 6.411 0 0016.16 7.25V4.5A3.665 3.665 0 0012.493.834zm-.147 5.912l-.568.697a.689.689 0 00-.147.44l.055.898c.037.55-.357.834-.87.632l-.834-.33a.843.843 0 00-.477 0l-.834.33c-.513.202-.908-.082-.87-.632l.054-.898a.689.689 0 00-.147-.44l-.568-.697c-.357-.422-.201-.89.33-1.027l.87-.22a.728.728 0 00.377-.284l.485-.751c.303-.468.78-.468 1.082 0l.486.751c.073.12.238.248.376.284l.87.22c.532.138.688.605.33 1.027z"
        fill="#fff"
      />
      <path
        opacity={0.4}
        d="M12.346 6.748l-.568.697a.688.688 0 00-.147.44l.055.898c.037.55-.357.834-.87.632l-.835-.33a.843.843 0 00-.476 0l-.834.33c-.514.202-.908-.082-.871-.632l.055-.898a.689.689 0 00-.147-.44l-.568-.697c-.358-.421-.202-.889.33-1.026l.87-.22a.728.728 0 00.376-.285l.486-.751c.303-.468.78-.468 1.082 0l.486.751c.073.12.238.248.375.285l.871.22c.532.137.688.604.33 1.026z"
        fill="#fff"
      />
    </svg>
  );
}

export default CupIcon;
