import React from 'react';

function ShareIcon(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.96 7.341a4.675 4.675 0 111.214 3.143l-7.082 4.048a4.675 4.675 0 11-.956-1.607l7.082-4.048a4.668 4.668 0 01-.258-1.536zm4.675-2.805a2.805 2.805 0 100 5.61 2.805 2.805 0 000-5.61zm-14.96 8.727a2.805 2.805 0 100 5.61 2.805 2.805 0 000-5.61z"
        fill="#0FF"
      />
      <path
        opacity={0.4}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.218 23.258l-7.89-4.51.927-1.623 7.92 4.525a4.675 4.675 0 11-.956 1.607zm1.612 1.536a2.805 2.805 0 115.61 0 2.805 2.805 0 01-5.61 0z"
        fill="#0FF"
      />
    </svg>
  );
}

export default ShareIcon;
