import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'react-date-range';
import Modal from 'react-modal';
import TButton from '../Button/index';

Modal.setAppElement(document.getElementById('root'));

function ModalDateRange(props) {
  const { modalIsOpen, closeModal, selectionRange, handleSelect, saveDateRange } = props;

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const mobileStyles = {
    content: {
      top: '20%',
      left: '0',
      right: '0',
      bottom: '0',
    },
  };
  const isMobile = window.innerWidth <= 768;
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={isMobile ? mobileStyles : customStyles}
        contentLabel={t('exampleModal')}
      >
        <div style={{ backgroundColor: '#fff' }}>
          <div className="row py-5">
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelect}
              months={2}
              direction={isMobile ? 'vertical' : 'horizontal'}
              showSelectionPreview
              moveRangeOnFirstSelection={false}
              rangeColors={['#0fb5d1', '#fff', '#fff']}
              className="date-range-picker-wrapper-mobile"
            />
            <TButton title={t('apply')} onClick={saveDateRange} className="mb-3 apply_ranger" />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ModalDateRange;
