import React, { useEffect, useRef } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import SquareCloseDialogIcon from '../../icons/SquareCloseDialogIcon';
import { TICKET_TYPE_IMG } from '../../../../assets/imgs';
import RedeemStatusPanel from './RedeemStatusPanel';
import TButton from '../../common/Button';
import { ClaimTicketSCStatus } from '../../../constants';
import WarningIcon from '../../icons/WarningIcon';

function RedemptionModal(props) {
  const { t } = useTranslation();
  const {
    show,
    setShow,
    createRequestRedeemStatus,
    sendingRequestStatus,
    confirmRequestStatus,
    claimTicketStatus,
    handleClickRetryBtn,
    handleClickCloseBtn,
    handleResetStatus,
    showRetryBtn,
    chainSelected,
    redeemRequestPayload = [],
    selectedGame,
    listTicketType,
  } = props;
  const shareRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (claimTicketStatus !== ClaimTicketSCStatus.CREATED) {
        return;
      }
      if (!shareRef.current.contains(event.target)) {
        setShow(false);
        handleResetStatus();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [shareRef, claimTicketStatus]);

  const LIST_NUMBER_TICKET = listTicketType?.map((ticketType) => {
    const matchedRedeemTicket = redeemRequestPayload.find(
      (ticket) => ticket?.ticketType?.name === ticketType?.name,
    );

    return {
      ...ticketType,
      ticketNumber: matchedRedeemTicket ? matchedRedeemTicket.quantity : 0,
    };
  });

  const totalPoints = redeemRequestPayload.reduce(
    (total, ticket) => total + ticket?.quantity * ticket?.points,
    0,
  );

  const totalTickets = redeemRequestPayload.reduce((total, ticket) => total + ticket?.quantity, 0);

  return (
    <div
      ref={shareRef}
      className={show ? 'modal-redeem show-modal-redeem redemption-modal' : 'modal-redeem'}
    >
      <div className="modal-header">
        <p className="header-title">{t('redeemRewards.redemptionRequest')}</p>
        <div
          className="cursor-pointer"
          onClick={() => {
            setShow(false);
            handleResetStatus();
            handleClickCloseBtn();
          }}
          aria-hidden
        >
          <SquareCloseDialogIcon />
        </div>
      </div>
      <div className="redeem-body">
        <div className="warning-wrapper">
          <WarningIcon width={20} height={18} />
          <p className="warning-title">{t('redeemRewards.require')}</p>
        </div>
        <div className="my-infor">
          <div className="infor-background title infor-chain">
            {t('redeemRewards.chain')}: <span className="chain-name">{chainSelected?.name}</span>
          </div>
          <div className="infor-background title token-infor my-redeem-ticket">
            <p>{t('redeemRewards.tickets')}:</p>
            <div className="icon-title-wrapper">
              <span className="title">{totalTickets || 0}</span>
              <img className="icon-ticket-img" src={selectedGame?.thumnail} alt="redeem-ticket" />
            </div>
          </div>
          <div className="infor-background title token-infor-2 my-redeem-ticket">
            <p>{t('redeemRewards.points')}:</p>
            <div className="icon-title-wrapper">
              <span className="title">{totalPoints || 0}</span>
              <img
                className="icon-ticket-img"
                src={selectedGame?.tokenImage || TICKET_TYPE_IMG.ticketToken}
                alt="game-point"
              />
            </div>
          </div>
        </div>
        <div className="my-ticket">
          {LIST_NUMBER_TICKET.map((i) => (
            <TicketNumberInformation
              key={i?.id}
              imgIcon={i?.image}
              ticketNumber={i?.ticketNumber}
            />
          ))}
        </div>
        <RedeemStatusPanel
          createRequestRedeemStatus={createRequestRedeemStatus}
          sendingRequestStatus={sendingRequestStatus}
          confirmRequestStatus={confirmRequestStatus}
          claimTicketStatus={claimTicketStatus}
        />
      </div>
      <div className="btn-view-ticket-wrapper">
        {
          showRetryBtn && (
            <>
              <TButton
                title={t('alert.retry')}
                className="btn-retry-modal"
                onClick={handleClickRetryBtn}
              />
              <TButton
                title={t('Close')}
                className="btn-close-modal"
                onClick={handleClickCloseBtn}
              />
            </>
          )
          // : (
          //   <TButton
          //     disabled={claimTicketStatus !== ClaimTicketSCStatus.CREATED}
          //     title={t('redeemRewards.viewMyTicket')}
          //     className="btn-view-my-ticket"
          //     onClick={() => {
          //       window.open(`${MARKETPLACE_FRONTEND_URL}profile?tab=my-tickets`, '_blank');
          //     }}
          //   />
          // )
        }
      </div>
    </div>
  );
}

export default RedemptionModal;

function TicketNumberInformation({ imgIcon, ticketNumber, className }) {
  return (
    <div className={`infor-background my-redeem-ticket ${className}`}>
      <img className="icon-ticket-img" src={imgIcon} alt="redeem-ticket" />
      <div className="title">{ticketNumber}</div>
    </div>
  );
}
