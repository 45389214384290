export const setLocalStorage = (name, value) => {
  localStorage.setItem(name, value);
};

export function getLocalStorage(name) {
  return localStorage.getItem(name);
}

export function removeLocalStorage(name) {
  localStorage.removeItem(name);
}
export function clearLocalStorage() {
  localStorage.clear();
}
