import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import ArrowDownIcon from '../../icons/ArrowDownIcon';

function SelectGame(props) {
  const { t } = useTranslation();
  const {
    title,
    items,
    selectedItem,
    setSelectedItem,
    onChange,
    textDefault = t('select'),
  } = props;
  const [isOpen, setOpen] = useState(false);
  const loginRef = useRef(null);
  const handleItemClick = (id) => {
    if (selectedItem === id) {
      onChange(id);
      setOpen(false);
    } else {
      setSelectedItem(id);
      setOpen(false);
    }
  };
  const toggleDropdown = () => setOpen(!isOpen);
  useEffect(() => {
    function handleClickOutside(event) {
      if (loginRef.current && !loginRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [loginRef]);

  const selected = items.find((item) => item.id === selectedItem);
  return (
    <div className="select-custom" ref={loginRef}>
      {title && <h6 className="title">{title}</h6>}
      <div className="select-game-wrapper" onClick={toggleDropdown} aria-hidden>
        <div className="img-thumbnail-wrapper">
          <img src={selected?.thumnail} alt="game-thumbnail" className="img-game-thumbnail" />
          <h6 className="select-default">{selected?.name || textDefault}</h6>
        </div>
        <div className={`${isOpen && 'rotate-180'} smooth-transform`}>
          <ArrowDownIcon />
        </div>
      </div>
      <div className="list-game-item">
        <div className={`dropdown-body ${isOpen && 'open'}`}>
          {items?.map((item) => (
            <div
              className="item-wrapper"
              onClick={() => handleItemClick(item.id)}
              key={item.id}
              aria-hidden
            >
              <img className="item-country" src={item?.thumnail} alt="thumnail" />
              <div className="item-title">{item.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SelectGame;
