import React from 'react';
import Layout from '../../components/common/Layout/index';

const FAQ = () => (
  <Layout title="FAQ">
    <h2>Coming soon!</h2>
  </Layout>
);

export default FAQ;
