import React from 'react';
import Layout from '../../components/common/Layout';
import ComingSoonPage from '../ComingSoon/ComingSoon';

const Ranking = () => (
  <Layout title="Ranking">
    <ComingSoonPage />
  </Layout>
);

export default Ranking;
