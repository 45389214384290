import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TButton from '../../common/Button';
import { ICONS, TOURNAMENT } from '../../../../assets/imgs';
import { getTranslatedValue } from '../../../lib/getTranslatedValue';

function InstantThumbnailTournament(props) {
  const { data } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const goToDetail = (slug) => {
    history.push(`/tournament/${slug}`);
  };

  const renderJoinFee = () => {
    if (data?.ticketCollection) {
      return (
        <div className="bracket-wrapper fee-wrapper">
          {t('fee')} 01 <img className="ticket-icon" src={ICONS.ticketIcon} alt="ticket-icon" /> ≈{' '}
          {data.ticketCollection.ticketType.price} {data.ticketCollection.paymentToken.symbol}
        </div>
      );
    }

    return data.join_fee ? (
      <div className="bracket-wrapper fee-wrapper">
        {t('fee')} {`${data.join_fee || 0} ${data.applicationNetwork?.symbol}`}
      </div>
    ) : (
      <div className="bracket-wrapper">{t('feeToJoin')}</div>
    );
  };

  return (
    <div className="thumbnail-tour-wrapper">
      <div className="img-thumbnail-wrapper">
        <img src={data?.image || TOURNAMENT.noImage} alt="dgg" className="img-thumbnail" />
      </div>
      <div className="gray-wrapper" />
      <div className="absolute-left-wrapper">
        <img className="prize-pool-icon" src={ICONS.prizePoolIcon} alt="prize-pool" />
        <p className="infor-tournament">
          {t('pool.prizePool')}: {data?.prize_amount} {data?.gamePoint}
        </p>
      </div>
      <div className="absolute-right-wrapper">
        <img className="lightning-icon" src={ICONS.lightningIcon} alt="lightning-icon" />
        <p className="infor-tournament">{t('tournament.instantTournament')}</p>
      </div>
      <div className="absolute-bottom-wrapper">
        <div>
          <div className="game-name-infor">{data?.applicationGame?.name}</div>
          <div className="tournament-name-infor">{getTranslatedValue(data, 'name')}</div>
          <div className="bracket-fee-wrapper">
            <div className="bracket-wrapper">{data?.applicationBracket}</div>
            {renderJoinFee()}

            <div className="team-number-wrapper">
              <img
                className="image-icon profile-image-icon"
                src={ICONS.iconProfileTournament}
                alt="dgg-network"
              />
              <div className="number-team">{data?.totalTeams}</div>
            </div>
          </div>
          <TButton
            onClick={() => goToDetail(data.slug)}
            title={t('playNow')}
            className="btn-play-now"
          />
        </div>
        <div className="chain-right-wrapper">
          <img
            className="prize-pool-icon"
            src={data?.ticketCollection?.paymentToken?.chain?.image}
            alt="chain-logo"
          />
          <p className="infor-tournament">{data?.ticketCollection?.paymentToken?.chain?.name}</p>
        </div>
      </div>
    </div>
  );
}

export default InstantThumbnailTournament;
