import React from 'react';

function Loading() {
  return (
    <div className="loading-circle">
      <svg viewBox="24 24 48 48">
        <circle cx="48" cy="48" r="18" />
      </svg>
    </div>
  );
}

export default Loading;
