import React from 'react';
import './styles.scss';

function SocialGroup(props) {
  const { icon, name, title, path } = props;

  const handleLinkSocial = () => {
    window.open(path, '_blank');
  };

  return (
    <div className="social-group" onClick={handleLinkSocial} aria-hidden>
      <div className="icon-social-group">
        <img src={icon} alt="icon" />
      </div>
      <div className="text-social-group">
        <div className="name">{name}</div>
        <div className="title">{title}</div>
      </div>
    </div>
  );
}

export default SocialGroup;
