import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import InputField from '../../common/Input';
import './styles.scss';
import TButton from '../../common/Button/index';

function ModalFilterRequest(props) {
  const { show, setShow, filters, handleGetFilter } = props;
  const filterRef = useRef(null);
  const [status, setStatus] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterRef]);

  const statusList = [
    {
      name: t('status.request'),
      color: '#9B51E0',
      class: 'tag-requesting-status',
      value: 0,
    },
    {
      name: t('status.pending'),
      color: '#E08F17',
      class: 'tag-pending-status',
      value: 1,
    },
    {
      name: t('status.success'),
      color: '#219653',
      class: 'tag-success-status',
      value: 2,
    },
    {
      name: t('status.reject'),
      color: '#EB5757',
      class: 'tag-reject-status',
      value: 3,
    },
  ];

  const handleFilter = () => {
    handleGetFilter.setStartDate(startDate);
    handleGetFilter.setEndDate(endDate);
    handleGetFilter.setCreatedAt(createdAt);
    handleGetFilter.setStatus(status);
    setShow(false);
  };
  const handleRefresh = () => {
    handleGetFilter.setStartDate('');
    handleGetFilter.setEndDate('');
    handleGetFilter.setCreatedAt('');
    handleGetFilter.setStatus(null);
    setStatus(null);
    setStartDate('');
    setEndDate('');
    setCreatedAt('');
  };
  useEffect(() => {
    if (show && filters.status) {
      setStatus(filters.status);
    }
    if (show && filters.startDate) {
      setStartDate(filters.startDate);
    }
    if (show && filters.endDate) {
      setEndDate(filters.endDate);
    }
    if (show && filters.createdAt) {
      setCreatedAt(filters.createdAt);
    }
  }, [show]);

  return (
    <div className={show ? 'wrap-modal-custom d-block' : 'wrap-modal-custom'} ref={filterRef}>
      <h6 className="color-primary mb-3">{t('filter.filterRequest')}</h6>
      <Row className="mb-3">
        <InputField
          title={t('filter.start')}
          type="date"
          onChange={setStartDate}
          className="txtDate"
          value={startDate}
          typeInput="date"
          typeMaxDate
        />
      </Row>
      <Row className="mb-3">
        <InputField
          title={t('filter.end')}
          type="date"
          onChange={setEndDate}
          className="txtDate"
          value={endDate}
          typeInput="date"
          typeMaxDate
        />
      </Row>
      <Row className="mb-3">
        <InputField
          title={t('filter.create')}
          type="date"
          onChange={setCreatedAt}
          className="txtDate"
          value={createdAt}
          typeInput="date"
          typeMaxDate
        />
      </Row>
      <Row className="mb-3">
        <h6 className="mb-2">{t('status.default')}</h6>
        <div className="wrap-tag-status">
          {statusList.map((item) => (
            <div
              className={`tag-status ${item.class}`}
              style={status === item.value ? { backgroundColor: '#00ffff', color: '#000' } : {}}
              key={item.value}
              onClick={() => setStatus(item.value)}
              aria-hidden
            >
              {item.name}
            </div>
          ))}
        </div>
      </Row>
      <Row className="mb-3">
        <Col sm="6">
          <TButton
            type="reverse"
            title={t('filter.refresh')}
            onClick={handleRefresh}
            width="full-width"
          />
        </Col>
        <Col sm="6">
          <TButton title={t('filter.buttonFilter')} onClick={handleFilter} width="full-width" />
        </Col>
      </Row>
    </div>
  );
}

export default ModalFilterRequest;
