import React from 'react';
import moment from 'moment';
import { RiMessage2Fill } from 'react-icons/ri';
import { READ_NOTIFY_STATUS } from '../../../constants';

function NotiItem(props) {
  const { data, onClick } = props;
  const formatDateNotification = 'MMMM Do YYYY, h:mm a';
  return (
    <div
      className={
        data.NotificationUser.read === READ_NOTIFY_STATUS
          ? 'un-read wrap-noti-item'
          : 'wrap-noti-item'
      }
      onClick={onClick}
      aria-hidden
    >
      <div className="noti-icon">
        <RiMessage2Fill />
      </div>
      <div className="noti-content">
        <h6 className="name color-primary">{data.title}</h6>
        <h6 className="create-at color-primary">
          {moment(data.createdAt).format(formatDateNotification)}
        </h6>
        <h6 className="description">{data.message}</h6>
      </div>
    </div>
  );
}

export default NotiItem;
