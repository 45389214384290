import React from 'react';
import Slider from 'react-slick';
import GameCard from '../../components/common/GameCard';
import { NextArrow, PrevArrow } from '../../utils/renderArrow';

function SlideGame(props) {
  const { games, onClick, idActive } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };
  return (
    <div className="slide-game-sm">
      <Slider {...settings}>
        {games.map((game) => (
          <GameCard
            game={game}
            isInfo={false}
            key={game.id}
            onClick={onClick}
            idActive={idActive}
          />
        ))}
      </Slider>
    </div>
  );
}

export default SlideGame;
