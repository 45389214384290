import React from 'react';

function RightArrowConnectIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M12 2c5.515 0 10 4.486 10 10s-4.485 10-10 10C6.486 22 2 17.514 2 12S6.486 2 12 2"
        fill="#0FF"
      />
      <path
        d="M10.557 7.78c.191 0 .383.073.53.219l3.486 3.47a.751.751 0 010 1.063l-3.487 3.472a.749.749 0 11-1.058-1.063L12.982 12l-2.954-2.94a.75.75 0 01.53-1.281"
        fill="#0FF"
      />
    </svg>
  );
}

export default RightArrowConnectIcon;
