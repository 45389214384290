import React from 'react';
import Helmet from 'react-helmet';
import { GTAG_KEY } from '../../../constants';

export default function Seo() {
  const title = 'DGG Network - Next Generation of Gaming';
  // eslint-disable-next-line
  const description =
    'Discover incredible metaverse games, apply scholarships, join tournaments to earn crypto and NFT prizes all in one place.';
  return (
    <Helmet title={title}>
      <meta charSet="utf-8" />
      <html lang="en" />
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <script async src={`https://www.googletagmanager.com/gtag/js?id=G-${GTAG_KEY}`} />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-${GTAG_KEY}');
        `}
      </script>
    </Helmet>
  );
}
