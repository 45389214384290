import React, { useLayoutEffect, useRef, useState } from 'react';
import { Bracket, Seed } from 'react-brackets';
import SeedBracket from '../../common/SeedBracket';
import TitleBracket from '../../common/TitleBracket';

const WiningBracket = ({ rounds: wining, number, height }) => {
  const [width, setWidth] = useState(0);

  const ref = useRef(null);

  useLayoutEffect(() => {
    setWidth(ref?.current?.offsetWidth);
  }, []);

  const roundTitleComponent = (title) => <TitleBracket title={title} />;

  const CustomSeed = ({ seed, roundIndex }) => (
    <Seed ref={ref} key={roundIndex}>
      <SeedBracket
        seed={seed}
        branchWin={roundIndex === wining.length - 2}
        final={roundIndex === wining.length - 1}
        height={height}
      />
    </Seed>
  );

  return (
    <div style={{ marginLeft: number ? width * (number + 1) : 0 }}>
      <Bracket
        mobileBreakpoint={0}
        rounds={wining}
        roundTitleComponent={(title) => roundTitleComponent(title)}
        renderSeedComponent={CustomSeed}
      />
    </div>
  );
};

export default WiningBracket;
