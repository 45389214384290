import React from 'react';
import './styles.scss';

function LoadingNft() {
  return (
    <div className="lds-spinner">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export default LoadingNft;
