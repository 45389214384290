import React from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

export const NextArrow = ({ onClick }) => (
  <div className="arrow next" onClick={onClick} aria-hidden>
    <BiChevronRight size={30} style={{ fill: '#00ffff' }} />
  </div>
);
export const PrevArrow = ({ onClick }) => (
  <div className="arrow prev" onClick={onClick} aria-hidden>
    <BiChevronLeft size={30} style={{ fill: '#00ffff' }} />
  </div>
);
