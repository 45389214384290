import React from 'react';
import { Col, Row } from 'reactstrap';
import './styles.scss';

const Match = ({ item, group }) => (
  <div className="group w-50 mb-5">
    <Row className="p-3 title-group">
      <Col style={{ color: '#000', fontWeight: 'bold' }} sm={3}>
        {group}
      </Col>
    </Row>
    <Row className="p-3 ">
      <Col style={{ color: '#fff' }} sm={1}>
        #
      </Col>
      <Col style={{ color: '#fff' }} sm={4} className="d-flex justify-content-end">
        TEAM
      </Col>
      <Col style={{ color: '#fff' }} sm={2} className="d-flex justify-content-center">
        RESULT
      </Col>
      <Col style={{ color: '#fff' }} sm={4} className="d-flex justify-content-start">
        TEAM
      </Col>
    </Row>
    {item.map((data, index) => (
      <Row className="p-3" key={String(data.teamOne + index)}>
        <Col style={{ color: '#fff' }} sm={1}>
          {index + 1}
        </Col>
        <Col style={{ color: '#fff' }} sm={4} className="d-flex justify-content-end">
          {data.teamOne}
        </Col>
        <Col style={{ color: '#fff' }} sm={2} className="d-flex justify-content-center">
          {`${data.teamOneScore} : ${data.teamTwoScore}`}
        </Col>
        <Col style={{ color: '#fff' }} sm={4} className="d-flex justify-content-start">
          {data.teamTwo}
        </Col>
      </Row>
    ))}
  </div>
);

export default Match;
