import React from 'react';
import { BiZoomIn, BiZoomOut, BiUndo } from 'react-icons/bi';
import './styles.scss';

const ActionZoomBracket = ({ zoomIn, zoomOut, resetTransform }) => (
  <div className="tools d-flex justify-content-end">
    <button type="button" onClick={zoomIn} className="btn-action-bracket btn m-1">
      <BiZoomIn />
    </button>
    <button type="button" onClick={zoomOut} className="btn-action-bracket btn m-1">
      <BiZoomOut />
    </button>
    <button type="button" onClick={resetTransform} className="btn-action-bracket btn m-1">
      <BiUndo />
    </button>
  </div>
);

export default ActionZoomBracket;
