import axios from 'axios';
import JSCookie from 'js-cookie';
import processDataLogin from '../lib/processDataLogin';
import { TIME_OUT_API_MILISEC, refreshAccessTokenUrl } from '../constants';

const olAxios = axios.create({
  baseURL: `${process.env.MARKETPLACE_API_ENDPOINT}api/v1`,
  timeout: TIME_OUT_API_MILISEC,
});

const client = axios.create({
  baseURL: process.env.APP_ENDPOINT,
  headers: {
    'x-api-key': process.env.DGG_API_KEY,
  },
});

const refreshAccessToken = async () => {
  try {
    const accessToken = JSCookie.get('token');
    const refreshToken = JSCookie.get('refreshToken');

    const onSuccess = (response) => {
      const data = response?.data;
      processDataLogin(data);
    };

    const onError = (error) => error;

    const payload = { refreshToken };
    if (refreshToken && !accessToken) {
      client({ method: 'post', url: refreshAccessTokenUrl, data: payload })
        .then(onSuccess)
        .catch(onError);
    }
  } catch (error) {
    openMessageError(error?.response?.data?.message || ERR_MESSAGE);
  }
};

const requestHandler = (request) => {
  const authToken = JSCookie.get('token');
  const acceptLanguage = localStorage.getItem('i18nextLng');
  request.headers = {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-key': process.env.DGG_API_KEY,
  };

  if (!authToken && refreshToken) {
    refreshAccessToken();
  }

  if (authToken) {
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${authToken}`,
    };
  }
  if (acceptLanguage) {
    request.headers = {
      ...request.headers,
      'Accept-Language': acceptLanguage,
    };
  }

  return request;
};

const redirectToError = (response) => {
  switch (response.status) {
    case 401:
      refreshAccessToken();
      break;
    case 403:
      window.location = '/error/404';
      break;
    case 404:
      window.location = '/error/404';
      break;
    case 503:
      window.location = '/maintain';
      break;
    case 504:
      window.location = '/error/504';
      break;
    default:
      break;
  }
};

const responseHandler = (response) => response;

const errorHandler = (error) => {
  const { response } = error;
  redirectToError(response);
  return Promise.reject(error);
};

olAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error),
);

olAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error),
);

export default {
  get: olAxios.get,
  post: olAxios.post,
  put: olAxios.put,
  delete: olAxios.delete,
  patch: olAxios.patch,
};
