import React from 'react';

function CopyIcon({ color = '#4F4F4F', ...props }) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect opacity={0.4} x={4} y={2} width={12.4308} height={17.0923} rx={2} fill={color} />
      <rect x={7.10767} y={5.10767} width={12.4308} height={17.0923} rx={2} fill={color} />
    </svg>
  );
}

export default CopyIcon;
