import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TButton from '../../components/common/Button';
import NewLayout from '../../components/common/NewLayout/NewLayout';
import SearchTable from '../../components/common/Search/SearchTable';
import useMessage from '../../components/common/toast/UseMessage';
import ListMyTeam from '../../components/pages/MyTeam/ListMyTeam';
import { ERR_MESSAGE, EVENT__KEY } from '../../constants';
import tournamentApi from '../../services/tournament';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import './styles.scss';
import UnauthorizePage from '../UnauthorizePage/UnauthorizePage';
import { useAuthContext } from '../../context/AuthContext';

function MyTeam(props) {
  const { dataConnect } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();
  const [listMyTeam, setListMyTeam] = useState([]);
  const itemsPerPage = 5;
  const [totalPage, setTotalPage] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const { isLogin } = useAuthContext();

  const handleCreateTeam = () => {
    history.push('/my-team/create');
  };
  const getMyTeam = async () => {
    dispatch(showLoading());
    const params = {
      name: search,
      limit: itemsPerPage,
      page: currentPage,
    };
    try {
      const { data } = await tournamentApi.getMyTeam(params);
      setListMyTeam(data.data);
      setTotalPage(data.totalPage);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (isLogin) {
      getMyTeam().catch((error) => {
        openMessageError(t('error.error'), error);
      });
    }
  }, [currentPage]);
  const handleSearchKeyDown = (event) => {
    if (event.key === EVENT__KEY.ENTER) {
      getMyTeam().catch((error) => {
        openMessageError(t('error.error'), error);
      });
    }
  };

  const resetMyTeams = async () => {
    dispatch(showLoading());
    const params = {
      name: '',
      limit: itemsPerPage,
      page: '1',
    };
    try {
      const { data } = await tournamentApi.getMyTeam(params);
      setListMyTeam(data.data);
      setCurrentPage(1);
      setTotalPage(data.totalPage);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleOnChangeSearch = (value) => {
    setSearch(value);
    if (!value) {
      resetMyTeams().catch((error) => {
        openMessageError(t('error.error'), error);
      });
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <NewLayout dataConnect={dataConnect} title={t('team.myTeam')} type={1} link="/tournament">
      {isLogin ? (
        <div className="my-team">
          <div className="title-and-button">
            <SearchTable
              placeholder={t('search.search')}
              search={search}
              setSearch={handleOnChangeSearch}
              onKeyDown={handleSearchKeyDown}
              handleSearchIconPress={getMyTeam}
            />
            <TButton
              className="btn-new-team"
              title={t('team.createYourteam')}
              onClick={handleCreateTeam}
            />
          </div>
          {listMyTeam && (
            <ListMyTeam
              itemsPerPage={itemsPerPage}
              totalPage={totalPage}
              currentPage={currentPage}
              listMyTeam={listMyTeam}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      ) : (
        <UnauthorizePage />
      )}
    </NewLayout>
  );
}

export default MyTeam;
