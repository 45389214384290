import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ICONS, TOURNAMENT } from '../../../../assets/imgs/index';
import { DATE_FORMAT, STATUS_TOURNAMENT_DETAIL } from '../../../constants';
import { BRACKETS_STRING } from '../../../constants/bracket';
import convertDate from '../../../utils/convertDate';
import './styles.scss';
import { getTranslatedValue } from '../../../lib/getTranslatedValue';

function CardTournament(props) {
  const { card, hover = true } = props;
  const history = useHistory();

  const goToDetail = (slug) => {
    history.push(`/tournament/${slug}`);
  };
  const { t } = useTranslation();

  const renderDate = () => {
    let date;
    if (card.status === STATUS_TOURNAMENT_DETAIL.New) {
      date = card.registration_deadline;
    } else if (
      card.status === STATUS_TOURNAMENT_DETAIL.Done ||
      card.status === STATUS_TOURNAMENT_DETAIL.Live
    ) {
      date = card.end_date;
    }
    return convertDate(date, DATE_FORMAT.cardTournament);
  };

  const renderJoinFee = () => {
    if (card.ticketCollection) {
      return (
        <div className="wrap-abs-image bottom-left-badge common-info-highlight">
          {t('fee')}: 01 <img className="ticket-icon" src={ICONS.ticketIcon} alt="ticket-icon" /> ≈{' '}
          {card.ticketCollection.ticketType.price} {card.ticketCollection.paymentToken.symbol}
        </div>
      );
    }

    return card.join_fee ? (
      <div className="wrap-abs-image bottom-left-badge common-info-highlight">
        {t('fee')}: {`${card.join_fee || 0} ${card.applicationNetwork?.symbol}`}
      </div>
    ) : (
      <div className="wrap-abs-image bottom-left-badge common-info-highlight">{t('feeToJoin')}</div>
    );
  };

  return (
    <div
      className={hover ? 'wrap-game-card' : 'wrap-game-card wrap-game-card-un-hover'}
      onClick={() => goToDetail(card.slug)}
      aria-hidden
    >
      <div>
        <div className="wrap-card-tournament-image">
          <div className="crop-img">
            <img
              src={card?.image || TOURNAMENT.noImage}
              alt="dgg-network"
              className="image-card-tournament"
            />
          </div>
          <div className="wrap-abs-image top-left-badge common-info-highlight">
            <img
              className="image-icon profile-image-icon"
              src={ICONS.iconProfileTournament}
              alt="dgg-network"
            />
            <div>
              {card?.numberTeam}
              {card?.applicationBracket !== BRACKETS_STRING.BATTLE_ROYALE &&
                `/${card?.totalTeams ?? '-'}`}
            </div>
          </div>
          <div className="chain-right-wrapper">
            <img
              className="prize-pool-icon"
              src={card?.ticketCollection?.paymentToken?.chain?.image}
              alt="chain-logo"
            />
            <p className="infor-tournament">{card?.ticketCollection?.paymentToken?.chain?.name}</p>
          </div>

          {card?.applicationBracket !== BRACKETS_STRING.BATTLE_ROYALE && card.applicationMode && (
            <div className="wrap-abs-image top-right-badge common-info-highlight">
              {card.applicationMode?.name}
            </div>
          )}
          <div className="wrap-abs-image bottom-right-badge common-info-highlight">
            {card.applicationBracket ?? ''}
          </div>
          {renderJoinFee()}
        </div>

        <div className="wrap-card-tournament-body">
          <div className="info-row">
            <div className="title-game">{card.applicationGame?.name}</div>
            <div className="title-host">{t('tournament.host')}</div>
          </div>

          <div className="info-row">
            <div className="title-tournament">{getTranslatedValue(card, 'name')}</div>
            <div className="host-name">{card.applicationUser?.username ?? ''}</div>
          </div>

          <div className="wrap-card-tournament-footer">
            <div className="wrap-tournament-deadline">
              <img
                className="image-icon calendar-image-icon"
                src={ICONS.iconCalendar}
                alt="dgg-network"
              />
              <div>{renderDate()}</div>
            </div>
            <div className="wrap-tournament-prize">
              {card.prize_amount || 0}&nbsp;{card.gamePoint}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardTournament;
