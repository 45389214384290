import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useTranslation } from 'react-i18next';
import { ICONS, SCHOLARSHIP } from '../../../assets/imgs';
import Layout from '../../components/common/Layout';
import NavBar from '../../components/common/NavBar';
import useMessage from '../../components/common/toast/UseMessage';
import ListExplore from '../../components/pages/Tournament/ListExplore';
import ModalFilterExplore from '../../components/pages/Tournament/ModalFilterExplore';
import NoTournament from '../../components/pages/Tournament/NoTournament';
import { ERR_MESSAGE } from '../../constants';
import scholarshipApi from '../../services/scholarship';
import tournamentApi from '../../services/tournament';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import Pagination from '../../components/common/Pagination';

function Explore(props) {
  const { dataConnect } = props;
  const [genre, setGenre] = useState([]);
  const [tournaments, setTournament] = useState([]);
  const [listGameGenre, setListGameGenre] = useState([]);
  const [params, setParams] = useState({});
  const [modalFilterIsOpen, setModalFilterIsOpen] = useState(false);
  const { openMessageError } = useMessage();
  const dispatch = useAppDispatch();
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const { t } = useTranslation();

  const getTournaments = async () => {
    dispatch(showLoading());
    try {
      const res = await tournamentApi.getTournaments({
        ...params,
        limit: itemsPerPage,
        page: currentPage,
      });
      setTournament(res.data.tournaments);
      setTotalPage(res.data.totalPage);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const getListGameGenre = async () => {
    dispatch(showLoading());
    try {
      const { data } = await scholarshipApi.getListGameGenre();
      setListGameGenre(data);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getTournaments();
  }, [currentPage]);

  const applyFilter = () => {
    getTournaments();
    setModalFilterIsOpen(false);
  };

  useEffect(() => {
    if (Object.keys(params).length === 0) {
      getTournaments();
    }
  }, [params]);
  useEffect(() => {
    getListGameGenre();
  }, []);

  const handleSetGenre = (value) => {
    let newGenre = [];
    if (genre.includes(value)) {
      newGenre = genre.filter((e) => e !== value);
    } else {
      newGenre = [...genre, value];
    }
    setGenre(newGenre);
    params.genre = newGenre;
    getTournaments();
  };
  const showPopupExplore = () => {
    setModalFilterIsOpen(true);
  };

  return (
    <Layout dataConnect={dataConnect} title={t('explore.title')} type={1} link="/tournament">
      <div className="wrap-tournament wrap-tournament-explore">
        <div className="wrap-explore-banner mb-5">
          <img src={SCHOLARSHIP.bannerScholarship} alt="dgg-network" />
        </div>

        <NavBar />

        <ModalFilterExplore
          show={modalFilterIsOpen}
          setShow={setModalFilterIsOpen}
          params={params}
          setParams={setParams}
          applyFilter={applyFilter}
          setGenre={setGenre}
          setCurrentPage={setCurrentPage}
        />
        <div className="group-status-remove">
          <div className="list-tag-status">
            <div className="tag-status tag-status-border" aria-hidden onClick={showPopupExplore}>
              <img src={ICONS.filterExplore} alt="dgg-network" />
              {t('filter.buttonFilter')}
            </div>

            {listGameGenre.map((item) => (
              <div
                className={`tag-status ${genre.includes(item.id) ? 'tag-status-active' : ''}`}
                key={item.id}
                onClick={() => handleSetGenre(item.id)}
                aria-hidden
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
        <div className="wrap-tournament">
          {tournaments ? (
            <div>
              <ListExplore tournaments={tournaments} />
              <div className="explore-pagination">
                <Pagination
                  totalPage={totalPage}
                  onChange={handlePageChange}
                  forcePage={currentPage}
                />
              </div>
            </div>
          ) : (
            <NoTournament />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Explore;
