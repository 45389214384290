import React from 'react';

function PrimaryTextArea({
  title = null,
  note = null,
  className = '',
  classNameTextArea = '',
  placeholder = '',
  onChange,
  id = '',
  ...props
}) {
  return (
    <div className={`new-primary-input ${className}`}>
      {title && <div className="title-input">{title}</div>}
      {note && <div className="note-input">{note}</div>}
      <textarea
        {...props}
        onChange={onChange}
        className={`primary-text-area-input ${classNameTextArea}`}
        id={id}
        cols={30}
        rows={10}
        placeholder={placeholder}
      />
    </div>
  );
}

export default PrimaryTextArea;
