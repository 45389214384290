import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import i18n from '../i18n/i18n';
import EnFlagIcon from '../components/icons/EnFlagIcon';
import VnFlagIcon from '../components/icons/VnFlagIcon';

const LanguageContext = createContext({
  selectedLanguage: null,
  checkSelectedLanguage: () => null,
  switchSelectLanguage: () => null,
  changeLanguage: () => null,
});

function LanguageProvier({ children }) {
  const [selectedLanguage, setSelectedLanguage] = useState({
    id: 2,
    name: 'VI',
    code: 'vi',
    logo: <VnFlagIcon />,
  });

  const changeLanguage = () => {
    if (i18n.language === 'en') {
      setSelectedLanguage({ id: 1, name: 'EN', code: 'en', logo: <EnFlagIcon /> });
    } else {
      setSelectedLanguage({ id: 2, name: 'VI', code: 'vi', logo: <VnFlagIcon /> });
    }
  };

  const checkSelectedLanguage = () => {
    try {
      const userLanguage = navigator.language;
      const languageSplit = userLanguage.split('-');
      if (languageSplit) {
        i18n.changeLanguage(languageSplit[0]);
        changeLanguage();
      }
    } catch (error) {
      console.log('Switch language error: ', error);
    }
  };

  const switchSelectLanguage = (language) => {
    if (language) {
      setSelectedLanguage(language);
    }
    i18n.changeLanguage(language?.code);
    changeLanguage();
  };

  useEffect(() => {
    checkSelectedLanguage();
  }, []);

  const contextValue = useMemo(
    () => ({
      selectedLanguage,
      checkSelectedLanguage,
      switchSelectLanguage,
      changeLanguage,
    }),
    [selectedLanguage],
  );
  return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
}

const useLanguageContext = () => useContext(LanguageContext);

export { LanguageContext, LanguageProvier, useLanguageContext };
