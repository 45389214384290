import { QR_CODE_IMG } from '../../assets/imgs';
import { metaMask } from '../connectors/metaMask';
import { walletConnectV2 } from '../connectors/walletConnectV2';

export const supportedChainIds = [process.env.ARBITRUM_CHAIN_ID, process.env.POLYGON_CHAIN_ID].map(
  (chainId) => Number(chainId),
);

export const ConnectorNames = {
  Email: 'Email',
  DggWalletDesktop: 'DGG Wallet Desktop',
  DggWalletMobile: 'DGG Wallet Mobile',
  Metamask: 'Metamask',
  WalletConnect: 'WalletConnect',
};

export const connectorsByName = {
  [ConnectorNames.Email]: null,
  [ConnectorNames.DggWalletDesktop]: walletConnectV2,
  [ConnectorNames.DggWalletMobile]: metaMask,
  [ConnectorNames.Metamask]: metaMask,
  [ConnectorNames.WalletConnect]: walletConnectV2,
};

export function getWalletServiceIcon(name) {
  switch (name) {
    case ConnectorNames.DggWalletDesktop:
      return QR_CODE_IMG.dggWallet;
    case ConnectorNames.DggWalletMobile:
      return QR_CODE_IMG.dggWallet;
    case ConnectorNames.Metamask:
      return QR_CODE_IMG.metamaskConnect;
    case ConnectorNames.WalletConnect:
      return QR_CODE_IMG.walletConnect;
    default:
      return null;
  }
}
