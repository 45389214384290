import React from 'react';

function SquareCloseDialogIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M14.34 0H5.67C2.28 0 0 2.38 0 5.92v8.17C0 17.62 2.28 20 5.67 20h8.67c3.39 0 5.66-2.38 5.66-5.91V5.92C20 2.38 17.73 0 14.34 0z"
        fill="#F13535"
      />
      <path
        d="M13.015 11.77l-1.779-1.778 1.778-1.778a.874.874 0 000-1.237.872.872 0 00-1.237 0L9.998 8.753 8.22 6.974a.877.877 0 00-1.238 0 .877.877 0 000 1.238l1.78 1.78-1.776 1.775a.874.874 0 101.237 1.238l1.776-1.776 1.78 1.78a.876.876 0 001.237-1.238"
        fill="#F13535"
      />
    </svg>
  );
}

export default SquareCloseDialogIcon;
