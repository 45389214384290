import React from 'react';

function QuestionIcon(props) {
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M11.393 1.833h-5.78c-2.26 0-3.78 1.587-3.78 3.947v5.446c0 2.354 1.52 3.94 3.78 3.94h5.78c2.26 0 3.774-1.586 3.774-3.94V5.78c0-2.36-1.514-3.947-3.774-3.947z"
        fill="#fff"
      />
      <path
        d="M8.486 5.167c.602 0 1.088.175 1.458.524.37.35.556.818.556 1.405 0 .655-.197 1.142-.592 1.46-.395.313-.93.469-1.604.469l-.016.53a.37.37 0 01-.74-.002L7.527 8.7a.258.258 0 01.258-.264c.601 0 1.072-.094 1.412-.281.34-.187.51-.54.51-1.058 0-.375-.109-.671-.328-.89-.218-.218-.513-.327-.884-.327-.376 0-.674.106-.892.318-.213.206-.32.49-.32.852s-.783.375-.783 0 .082-.702.246-.983c.164-.287.395-.509.692-.665a2.261 2.261 0 011.048-.234zm-.574 6.667a.538.538 0 01-.4-.169.568.568 0 01-.165-.412.568.568 0 01.565-.58.5.5 0 01.383.168c.11.113.164.25.164.412 0 .162-.055.3-.164.412a.499.499 0 01-.383.169z"
        fill="#fff"
      />
    </svg>
  );
}

export default QuestionIcon;
