import React from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { metaMask, hooks as metaMaskHooks } from '../connectors/metaMask';
import { walletConnectV2, hooks as walletConnectV2Hooks } from '../connectors/walletConnectV2';
import { hooks as networkHooks, network } from '../connectors/network';

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
  [network, networkHooks],
];

function Web3Provider({ children }) {
  return <Web3ReactProvider connectors={connectors}>{children}</Web3ReactProvider>;
}

export default Web3Provider;
