import { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { Network } from '@web3-react/network';
import { getAddChainParameters } from '../connectors/chains';

const useSwitchNetwork = () => {
  const [desiredChainId, setDesiredChainId] = useState(undefined);
  const { connector, chainId } = useWeb3React();
  const [error, setError] = useState(undefined);
  /**
   * When user connects eagerly (`desiredChainId` is undefined) or to the default chain (`desiredChainId` is -1),
   * update the `desiredChainId` value so that <select /> has the right selection.
   */
  useEffect(() => {
    if (chainId && (!desiredChainId || desiredChainId === -1)) {
      setDesiredChainId(chainId);
    }
  }, [desiredChainId, chainId]);

  const switchNetwork = useCallback(
    async (targetChainId) => {
      setDesiredChainId(targetChainId);
      try {
        if (
          // If we're already connected to the desired chain, return
          targetChainId === chainId ||
          // If they want to connect to the default chain and we're already connected, return
          (targetChainId === -1 && chainId !== undefined)
        ) {
          setError(undefined);
          return;
        }

        if (connector instanceof WalletConnectV2 || connector instanceof Network) {
          await connector.activate(targetChainId);
        } else {
          await connector.activate(getAddChainParameters(targetChainId));
        }
        setError(undefined);
      } catch (e) {
        console.log('Error switch: ', e);
        toast.error('Error switch: ', e);
        setError(e);
      }
    },
    [connector, chainId, setError],
  );

  return { switchNetwork, error };
};

export default useSwitchNetwork;
