import React from 'react';
import Slider from 'react-slick';
import { NextArrow, PrevArrow } from '../../../utils/renderArrow';
import CardTournament from './CardTournament';

function InStatusTournament(props) {
  const { data, hover = false } = props;
  const settings = {
    dots: true,
    infinite: data.length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slide-container">
      <Slider {...settings}>
        {data.map((item) => (
          <div key={item.id}>
            <CardTournament card={item} hover={hover} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default InStatusTournament;
