import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePagination, useTable } from 'react-table';
import './style.scss';

function ReferralTable({
  columns,
  data = [],
  pageCount: controlledPageCount = 1,
  pageSizePagination,
  headerTextAlignRight = [],
  headerTextAlignCenter = [],
}) {
  const { getTableProps, headerGroups, rows, prepareRow, page } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: pageSizePagination },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
    },
    usePagination,
  );

  const firstPageRows = rows.slice(0, pageSizePagination);
  const { t } = useTranslation();

  return (
    <div className="table-referral-style hide-scrollbar">
      <table className="" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            // eslint-disable-next-line
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                let columnClassName = '';
                if (headerTextAlignRight.includes(column?.Header)) {
                  columnClassName = 'text-right';
                } else if (headerTextAlignCenter.includes(column?.Header)) {
                  columnClassName = 'text-center';
                }
                return (
                  <td key={column?.id} className={columnClassName}>
                    {column.render('Header')}
                  </td>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {firstPageRows.map((row, i) => {
            let className = 'odd';
            if (i % 2 === 0) {
              className = 'even';
            }
            return (
              prepareRow(row) || (
                <tr key={row?.id} className={className} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    let cellClassName = '';
                    if (headerTextAlignRight.includes(cell.column.Header)) {
                      cellClassName = 'text-right';
                    } else if (headerTextAlignCenter.includes(cell.column.Header)) {
                      cellClassName = 'text-center';
                    }
                    return (
                      <td
                        key={cell?.column?.id}
                        className={cellClassName}
                        data-label={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              )
            );
          })}

          {page.length < 1 && (
            <tr className="odd">
              <td colSpan={100}>{t('myPrize.noData')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ReferralTable;
