import React from 'react';

function PrimaryInputCheckbox({
  className = '',
  onClick = null,
  accessoriesLeft = null,
  accessoriesRight = null,
  checked = false,
  onChange = null,
  ...props
}) {
  return (
    <label className={`primary-input-checkbox ${className}`}>
      {accessoriesLeft && <div className="accessoryLeft">{accessoriesLeft}</div>}
      <input
        type="checkbox"
        className="input-checkbox custom-checkbox"
        {...props}
        onClick={onClick}
        checked={checked}
        onChange={onChange}
      />
      <div className="square-check-box">
        <svg
          className="square-hidden"
          width={8}
          height={6}
          viewBox="0 0 8 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.011 5.706a.727.727 0 01-.516-.213L.518 3.516a.728.728 0 111.03-1.032l1.463 1.462 3.44-3.44a.728.728 0 111.031 1.032L3.527 5.493a.727.727 0 01-.516.213z"
            fill="#00f0fd"
          />
        </svg>
      </div>
      {accessoriesRight && <div className="accessoryRight">{accessoriesRight}</div>}
    </label>
  );
}

export default PrimaryInputCheckbox;
