import moment from 'moment';

const convertDate = (date, format) => {
  if (date) {
    return moment(new Date(date)).format(format);
  }
  return '';
};

export default convertDate;
