import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import tournamentApi from '../../../../services/tournament';
import { useAppDispatch } from '../../../../store';
import { ERR_MESSAGE } from '../../../../constants';
import { TEAM } from '../../../../../assets/imgs/index';
import useMessage from '../../../common/toast/UseMessage';
import { hideLoading, showLoading } from '../../../../store/features/loadingPage';
import TopWinner from './TopWinner';
import './styles.scss';
import converTeamName from '../../../../utils/convertTeamName';
import { getTranslatedValue } from '../../../../lib/getTranslatedValue';

function Winner(props) {
  const { t } = useTranslation();
  const { tournament, id, token, prizePool } = props;
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();
  const [topPrizes, setTopPrizes] = useState([]);
  const [remainingPrizes, setRemainingPrizes] = useState([]);

  const getPrizes = async () => {
    dispatch(showLoading());
    try {
      const res = await tournamentApi.getPrizes(id);
      const data = res.data.tournamentPrizes;

      const topPrizes = data?.slice(0, 3);
      setTopPrizes(topPrizes);

      const remainingPrizes = data?.slice(3, data?.length);
      setRemainingPrizes(remainingPrizes);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const resetData = () => {
    setTopPrizes([]);
    setRemainingPrizes([]);
  };

  useEffect(() => {
    if (id) {
      resetData();
      getPrizes();
    }
  }, [id]);

  return (
    <div className="prizing-tab winner-tab">
      <h1 className="tournament-name d-flex justify-content-center">
        {getTranslatedValue(tournament, 'name')}
      </h1>
      <h3 className="prize-pool d-flex justify-content-center">
        {`${t('PRIZEPOOL')}: ${prizePool ?? 0} MEOW`}
      </h3>
      <Row className="top-prize-wrapper">
        {topPrizes?.map((prize, index) => (
          <TopWinner
            key={prize?.name}
            token={token}
            prize={prize}
            prizeType={index}
            type="winner"
          />
        ))}
      </Row>
      {remainingPrizes?.length > 0 && (
        <Col lg="9" className="table-prizing-wrap">
          <div className="prizing-header-wrap">
            <div className="prizing-header rank">#</div>
            <div className="prizing-header name text-center">{t('player')}</div>
            <div className="prizing-header percentage text-center">{t('percentage')}</div>
            <div className="prizing-header prize text-center">{t('myPrize.reward')}</div>
          </div>
          {remainingPrizes?.map((prize) => (
            <div className="prizing-content-wrap" key={prize.name}>
              <div className="prize-name">
                <h4>{prize.name}</h4>
              </div>
              <div className="name-content d-flex align-items-center justify-content-center">
                <img
                  className="prize-team-img"
                  src={prize.applicationTeam?.avatar ?? TEAM.logoTeamDefault}
                  alt="dgg-network"
                />
                <h5 className="prize-team-name">
                  {prize.applicationTeam?.name ? converTeamName(prize.applicationTeam?.name) : '-'}
                </h5>
              </div>
              <div className="percentage-content text-center">{prize.percentage}%</div>
              <div className="prize-content">
                <h4>
                  {prize.prize} {token}
                </h4>
              </div>
            </div>
          ))}
        </Col>
      )}
    </div>
  );
}

export default Winner;
