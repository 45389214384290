import React from 'react';
import Layout from '../../components/common/Layout';
import ComingSoonPage from '../ComingSoon/ComingSoon';

const Tracking = () => (
  <Layout title="Tracking">
    <ComingSoonPage />
  </Layout>
);

export default Tracking;
