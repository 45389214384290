import React from 'react';
import { useTranslation } from 'react-i18next';
import SuccessTickIcon from '../../icons/SuccessTickIcon';
import LoadingIcon from '../../icons/LoadingIcon';
import {
  ClaimTicketSCStatus,
  ConfirmRequestBackendStatus,
  CreateRequestStatus,
  SendingRequestSCStatus,
} from '../../../constants';
import XIcon from '../../icons/XIcon';

function RedeemStatusPanel(props) {
  const { t } = useTranslation();
  const {
    createRequestRedeemStatus,
    sendingRequestStatus,
    confirmRequestStatus,
    claimTicketStatus,
  } = props;

  const renderCreateRequestStatus = () => {
    if (createRequestRedeemStatus === CreateRequestStatus.CREATED) {
      return <SuccessTickIcon />;
    }
    if (
      createRequestRedeemStatus === CreateRequestStatus.FAILED ||
      createRequestRedeemStatus === CreateRequestStatus.REJECTED
    )
      return <XIcon />;
    return <LoadingIcon className="animate-spin" />;
  };

  const renderSendingRequestStatus = () => {
    if (sendingRequestStatus === SendingRequestSCStatus.CREATED) {
      return <SuccessTickIcon />;
    }
    if (sendingRequestStatus === SendingRequestSCStatus.PENDING) {
      return <LoadingIcon className="animate-spin" />;
    }
    if (
      sendingRequestStatus === SendingRequestSCStatus.FAILED ||
      sendingRequestStatus === SendingRequestSCStatus.REJECTED
    ) {
      return <XIcon />;
    }
    return <LoadingIcon color="#827D9D" />;
  };

  const renderConfirmRequestStatus = () => {
    if (confirmRequestStatus === ConfirmRequestBackendStatus.CREATED) {
      return <SuccessTickIcon />;
    }
    if (confirmRequestStatus === ConfirmRequestBackendStatus.PENDING) {
      return <LoadingIcon className="animate-spin" />;
    }
    if (
      confirmRequestStatus === ConfirmRequestBackendStatus.FAILED ||
      confirmRequestStatus === ConfirmRequestBackendStatus.REJECTED
    ) {
      return <XIcon />;
    }
    return <LoadingIcon color="#827D9D" />;
  };

  const renderClaimTicketStatus = () => {
    if (claimTicketStatus === ClaimTicketSCStatus.CREATED) {
      return <SuccessTickIcon />;
    }
    if (claimTicketStatus === ClaimTicketSCStatus.PENDING) {
      return <LoadingIcon className="animate-spin" />;
    }
    if (
      claimTicketStatus === ClaimTicketSCStatus.FAILED ||
      claimTicketStatus === ClaimTicketSCStatus.REJECTED
    ) {
      return <XIcon />;
    }
    return <LoadingIcon color="#827D9D" />;
  };

  return (
    <div className="redeem-panel-wrapper">
      <div className="redeem-status-wrapper">
        <div className="status-line">
          {renderCreateRequestStatus()}
          <div className="line-between" />
        </div>
        <div>
          <p className="status-title">{t('redeemRewards.createRequest')}</p>
        </div>
      </div>

      <div className="redeem-status-wrapper">
        <div className="status-line">
          {renderSendingRequestStatus()}
          <div className="line-between" />
        </div>
        <div>
          <p className="status-title">{t('redeemRewards.sendingRequest')}</p>
          <p className="status-description">{t('redeemRewards.signRequest')}</p>
        </div>
      </div>
      <div className="redeem-status-wrapper">
        <div className="status-line">
          {renderConfirmRequestStatus()}
          <div className="line-between" />
        </div>
        <div>
          <p className="status-title">{t('redeemRewards.awaitSignature')}</p>
          {confirmRequestStatus === ConfirmRequestBackendStatus.CREATED && (
            <p className="status-description">{t('redeemRewards.systemVerify')}</p>
          )}
        </div>
      </div>

      <div className="redeem-status-wrapper">
        {renderClaimTicketStatus()}
        <div>
          <p className="status-title">{t('redeemRewards.claimTicket')}</p>
          {claimTicketStatus === ClaimTicketSCStatus.CREATED ? (
            <p className="status-description">{t('redeemRewards.claimTicketSuccess')}</p>
          ) : (
            <p className="status-description">{t('redeemRewards.payGasForMint')}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default RedeemStatusPanel;
