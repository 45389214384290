import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { BRACKETS } from '../../../constants/bracket';
import GroupBracket from '../../common/GroupBracket';
import Match from '../../common/Match';
import SingleElimination from '../SingleElimination';
import './styles.scss';

const RoundRobin = ({ dataBracket }) => {
  const [type, setType] = useState(BRACKETS.ROUND_ROBIN);
  const [stage, setStage] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [matchRound, setMatchRound] = useState([]);
  const [roundNumber, setRoundNumber] = useState(0);
  const [showMatch, setShowMatch] = useState(false);

  const getDataStageMatch = () => {
    const dataMatchStage = [];
    Object.keys(dataBracket?.stageResult).forEach((key) => {
      const dataRound = [];
      Object.keys(dataBracket?.stageResult[key]).forEach((k) => {
        const dataMatch = [];
        Object.keys(dataBracket?.stageResult[key][k]).forEach((i) => {
          dataMatch.push(dataBracket?.stageResult[key][k][i]);
        });
        const groupMatch = {
          group: k,
          data: dataMatch,
        };
        dataRound.push(groupMatch);
      });
      dataMatchStage.push(dataRound);
    });
    setMatchRound(dataMatchStage);
  };

  const getDataRound = () => {
    const dataRounds = [];
    for (let i = 0; i < dataBracket?.totalStageRound; i += 1) {
      dataRounds.push(i + 1);
    }
    setRounds(dataRounds);
  };

  const getDataStage = () => {
    const dataStage = [];
    Object.keys(dataBracket?.stage).forEach((key) => {
      const bracket = {
        group: key,
        data: dataBracket?.stage[key],
      };
      dataStage.push(bracket);
    });
    setStage(dataStage);
  };

  useEffect(() => {
    getDataStage();
    getDataRound();
    getDataStageMatch();
  }, [dataBracket]);

  const handleClickRound = (index) => {
    setRoundNumber(index);
    setShowMatch(true);
  };

  const handleClickGroup = () => {
    setType(BRACKETS.ROUND_ROBIN);
    setShowMatch(false);
  };

  const handleClickKnockout = () => {
    setType(BRACKETS.SINGLE_ELIMINATION);
    setShowMatch(false);
  };
  return (
    <div>
      <div>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <Button
              className={`m-2 btn ${
                type === BRACKETS.ROUND_ROBIN && !showMatch ? 'btn-active' : 'btn-inactive'
              }`}
              onClick={handleClickGroup}
            >
              Group
            </Button>
            <Button
              className={`m-2 btn ${
                type === BRACKETS.SINGLE_ELIMINATION && !showMatch ? 'btn-active' : 'btn-inactive'
              }`}
              onClick={handleClickKnockout}
            >
              Knockout
            </Button>
          </div>
          {type === BRACKETS.ROUND_ROBIN && rounds.length > 0 && (
            <div className="d-flex">
              <div className="m-2 d-flex align-items-center" style={{ color: '#fff' }}>
                Rounds
              </div>
              {rounds &&
                rounds.map((item, index) => (
                  <Button
                    className={`m-2 btn ${
                      roundNumber === index && showMatch ? 'btn-active' : 'btn-inactive'
                    }`}
                    key={item}
                    onClick={() => handleClickRound(index)}
                  >
                    {index + 1}
                  </Button>
                ))}
            </div>
          )}
        </div>
        {type === BRACKETS.ROUND_ROBIN && !showMatch && (
          <div className="m-2">
            {stage.map((item, index) => (
              <GroupBracket item={item.data} key={String(item.group + index)} />
            ))}
          </div>
        )}
        {type === BRACKETS.SINGLE_ELIMINATION && !showMatch && (
          <SingleElimination dataBracket={dataBracket.knockOut} />
        )}
        {showMatch &&
          matchRound[roundNumber].map((item, index) => (
            <Match item={item.data} group={item.group} key={String(item.group + index)} />
          ))}
      </div>
    </div>
  );
};

export default RoundRobin;
