import React from 'react';
import './styles.scss';
import i18n from '../../../i18n/i18n';
import { useLanguageContext } from '../../../context/LanguageContext';

function SelectLanguageSwitch() {
  const { selectedLanguage, changeLanguage } = useLanguageContext();

  const handleClickLanguage = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('vi');
    } else {
      i18n.changeLanguage('en');
    }
    changeLanguage();
  };

  return (
    <div className="select-language-custom" onClick={handleClickLanguage} aria-hidden>
      <div className="select-language-mobile">
        {selectedLanguage?.logo}
        <p>{selectedLanguage?.name}</p>
      </div>
    </div>
  );
}
export default SelectLanguageSwitch;
