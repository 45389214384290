import React from 'react';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header/Header';
import HomeContent from '../components/pages/Home/HomeContent';

function Home(props) {
  const { dataConnect } = props;

  return (
    <>
      <Header dataConnect={dataConnect} />
      <section>
        <HomeContent />
      </section>
      <Footer className="home-footer" />
    </>
  );
}

export default Home;
