import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import LosingBracket from './LosingBracket';
import WiningBracket from './WiningBracket';
import ActionZoomBracket from '../../common/ActionZoomBracket';

const DoubleElimination = ({ dataBracket }) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [number, setNumber] = useState();

  useLayoutEffect(() => {
    setHeight(ref?.current?.offsetHeight);
  }, []);

  useEffect(() => {
    if (dataBracket?.losing && dataBracket?.winning) {
      setNumber(dataBracket.losing.length - dataBracket.winning.length);
    }
  }, [dataBracket]);

  return (
    <TransformWrapper initialScale={1} minScale={0.8}>
      {({ zoomIn, zoomOut, resetTransform }) => (
        <>
          <ActionZoomBracket zoomIn={zoomIn} zoomOut={zoomOut} resetTransform={resetTransform} />
          <TransformComponent>
            {dataBracket && (
              <div ref={ref}>
                <WiningBracket rounds={dataBracket?.winning} number={number} height={height} />
                <LosingBracket rounds={dataBracket?.losing} height={height} />
              </div>
            )}
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
};

export default DoubleElimination;
