import JSCookie from 'js-cookie';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../assets/imgs';
import GameCard from '../../components/common/GameCard';
import Layout from '../../components/common/Layout';
import useMessage from '../../components/common/toast/UseMessage';
import RentalComponent from '../../components/pages/Rental';
import { ERR_MESSAGE } from '../../constants';
import scholarshipApi from '../../services/scholarship';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { getLocalStorage, setLocalStorage } from '../../utils/handleLocalStorage';
import SlideGame from './SlideGame';
import './styles.scss';
import { useAuthContext } from '../../context/AuthContext';

function Rental(props) {
  const { dataConnect } = props;
  const [isChecked, setChecked] = useState(false);
  const [isAccepted, setAccept] = useState(false);
  const history = useHistory();
  const [games, setGames] = useState([]);
  const [id, setId] = useState();
  const [tag, setTag] = useState(1);
  const [urlMarket, setUrlMarket] = useState('');
  const { t } = useTranslation();
  const [slug, setSlug] = useState('');
  const getTag = (tagRental) => {
    setTag(tagRental);
  };

  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();

  const getGames = async () => {
    dispatch(showLoading());
    try {
      const res = await scholarshipApi.getListGame();
      const { data } = res;
      setGames(data);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getGames();
  }, []);

  const handleAccept = () => {
    setAccept(true);
    setLocalStorage('isAccepted', true);
  };

  useEffect(() => {
    setAccept(getLocalStorage('isAccepted'));
  }, []);

  const handleClickGame = (game) => {
    setId(game.id);
    setSlug(game.slug);
  };

  const handleClickSlideGame = (gameId) => {
    setId(gameId);
    setSlug(games.filter((e) => e.id === gameId)[0]?.slug);
  };
  const renderItem = (game, key) => (
    <Col xxl="3" md="6" lg="4" sm="12" className="mb-4" key={key}>
      <GameCard game={game} isInfo={false} onClick={() => handleClickGame(game)} idActive={id} />
    </Col>
  );

  useEffect(() => {
    if (!id) return;
    history.replace(`?id=${id}&tag=${tag}`);
  }, [tag, id]);

  useEffect(() => {
    const query = queryString.parse(history.location.search);
    if (!query.id) return;
    setId(query.id);
    if (!Number(query.tag)) return;
    setTag(Number(query.tag));
  }, []);

  useEffect(() => {
    if (id) {
      setUrlMarket(games.filter((e) => e.id === id)[0]?.other?.marketplace);
      setSlug(games.filter((e) => e.id === id)[0]?.slug);
    }
  }, [id, games]);
  const accountEmail = JSCookie.get('email');
  const walletAccount = JSCookie.get('walletAccount');
  const { isLogin } = useAuthContext();

  const handleConnectLinkWallet = () => {
    if (accountEmail) {
      dataConnect.linkWallet();
    } else {
      // setShow(true);
    }
  };

  const renderConditions = () => (
    <div className="wrap-rental-content">
      <h3 className="color-primary mb-3">{t('condition.title')}</h3>
      <div className="rental-content">
        <div className="wrap-term-condition">
          <div className="description">
            <div>
              <h6 className="title">{t('condition.scholarshipCondition.subTitle')}</h6>
              <ul className="ul-terms">
                <li>
                  <h6>{t('condition.scholarshipCondition.condition1')}</h6>
                </li>
                <li>
                  <h6>{t('condition.scholarshipCondition.condition2')}</h6>
                </li>
              </ul>
              <div className="check-term">
                <img
                  src={isChecked ? ICONS.iconChecked : ICONS.iconTick}
                  alt="icon"
                  onClick={() => setChecked(!isChecked)}
                  aria-hidden
                />
                <div className="check-term-text">{t('condition.confirm')}</div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            isChecked
              ? 'btn-stroke-fill mb-5'
              : 'btn-stroke-fill btn-stroke-fill-disable mb-5 btn-brown'
          }
          onClick={isChecked ? handleAccept : null}
          aria-hidden
        >
          {t('condition.accept')}
        </div>
      </div>
    </div>
  );

  return (
    <Layout title={t('asset')} dataConnect={dataConnect}>
      {isAccepted ? (
        <div className="wrap-rental-content">
          <div className="rental-list-game">
            <Row className="list-game-pc">
              {games && <>{games.map((game, key) => renderItem(game, key))}</>}
            </Row>
            <SlideGame games={games} onClick={handleClickSlideGame} idActive={id} />
          </div>
          {id ? (
            <div className="rental-content">
              {Number(walletAccount) ? (
                <RentalComponent
                  id={id}
                  account={walletAccount}
                  tokenAccess={isLogin}
                  getTag={getTag}
                  urlMarket={urlMarket}
                  slug={slug}
                />
              ) : (
                <Row>
                  <div className="wrap-btn-connect-wallet-rental">
                    <h1
                      className="btn-stroke-fill btn-padding"
                      onClick={handleConnectLinkWallet}
                      aria-hidden="true"
                    >
                      {accountEmail ? t('require.linkWallet') : t('require.logIn')}
                    </h1>
                  </div>
                </Row>
              )}
            </div>
          ) : (
            <div className="rental-content">
              <div className="wrap-term-condition">
                <h3 className="mb-3">{t('require.guidelines')}</h3>
                <div className="description guild-line-description">
                  <div>
                    <h6 className="title">{t('condition.scholarshipCondition.subTitle')}</h6>
                    <ul className="ul-terms">
                      <li>
                        <h6>{t('condition.scholarshipCondition.condition1')}</h6>
                      </li>
                      <li>
                        <h6>{t('condition.scholarshipCondition.condition2')}</h6>
                      </li>
                    </ul>
                  </div>
                  <div className="rental-video">
                    <iframe
                      width="429"
                      height="259"
                      src="https://www.youtube.com/embed/SdhAmdqasxU"
                      title="GAME THỦ LMHT KHÓC 1 DÒNG SÔNG KHI BỊ RIOT COI NHƯ “CON GHẺ”"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        renderConditions()
      )}
    </Layout>
  );
}

export default Rental;
