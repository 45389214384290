import olAxios from '../api/marketplaceInterceptors';

const marketPlaceApi = {
  getNumberOfTicket: (ticketCollectionId) =>
    olAxios.get(`assets/ticket-collections/${ticketCollectionId}/tickets`),
  getTicketCollection: (ticketCollectionId) =>
    olAxios.get(`explore/ticket-collections/${ticketCollectionId}`),
  getChains: () => olAxios.get('/explore/chains'),
  getListTicketCollection: (params) => olAxios.get(`/explore/ticket-collections`, { params }),
  getListTicketTypes: () => olAxios.get('/explore/ticket-types'),
};
export default marketPlaceApi;
