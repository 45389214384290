import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useScreenWidth from '../../../hooks/useScreenWidth';
import './styles.scss';
import { TYPE_LANGUAGE_DROPDOWN } from '../../../constants';
import EarthIcon from '../../icons/EarthIcon';
import SelectLanguageDropdown from '../Layout/SelectLanguageDropdown';
import SelectLanguageSwitch from '../Layout/SelectLanguageSwitch';

function Board(props) {
  const { t } = useTranslation();
  const { title, icon, path, imgStyles, subIcon, id, setShow, type = '' } = props;
  const history = useHistory();
  const pathSite = history.location.pathname;
  const isActive = pathSite.includes(path);
  const screenWidth = useScreenWidth();
  const handleMovePage = () => {
    if (title === t('header.docs') || title === t('header.faq')) {
      window.open(path, '_blank');
    } else {
      history.push(`/${path}`);
      if (screenWidth < 768) {
        setShow(false);
      }
    }
  };
  return type === TYPE_LANGUAGE_DROPDOWN ? (
    <div className="language-board-wrapper" id={id}>
      <div className="board">
        <EarthIcon />
        <h6 className="board-title mb-0">{title}</h6>
      </div>
      {screenWidth > 768 ? (
        <div className="language-dropdown-in-nav">
          <SelectLanguageDropdown />
        </div>
      ) : (
        <SelectLanguageSwitch />
      )}
    </div>
  ) : (
    <div onClick={handleMovePage} aria-hidden id={id}>
      <div className="board">
        {icon && (
          <div className="icon-side-bar">
            <img src={isActive ? icon : subIcon} alt="icon" className={imgStyles || ''} />
          </div>
        )}
        <h6 className={isActive ? 'active-title board-title mb-0' : 'board-title mb-0'}>{title}</h6>
      </div>
    </div>
  );
}

export default Board;
