import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS, TEAM } from '../../../../assets/imgs';
import TButton from '../../common/Button';
import SearchTable from '../../common/Search/SearchTable';
import CardInviteMember from './CardInviteMember';
import './styles.scss';

function SearchCard(props) {
  const {
    search,
    onChange,
    onKeyDown,
    title,
    inviteMember,
    newInviteMember,
    setNewInviteMember,
    handleSearchIconPress,
    handleLoadMore,
    isChecked,
  } = props;

  const handleRemoveMember = (id) => {
    const index = newInviteMember.findIndex((input) => input.id === id);
    const newMember = [...newInviteMember];
    newMember.splice(index, 1);
    setNewInviteMember(newMember);
  };
  const handleClearAll = () => {
    setNewInviteMember([]);
  };
  const { t } = useTranslation();
  return (
    <div className="form-search-team">
      <div className="search-invite-member">
        <h5>{title}</h5>
        <div className="search-team">
          <SearchTable
            search={search}
            placeholder={t('search.searchForPlayers')}
            onKeyDown={onKeyDown}
            setSearch={onChange}
            handleSearchIconPress={handleSearchIconPress}
          />
        </div>
      </div>
      {newInviteMember.length > 0 && (
        <div className="remove-card-member-invite">
          <div className="list-invite-member">
            {newInviteMember.map((item) => (
              <div className="invite-member" key={item.id}>
                <div className="avatar-member">
                  <img src={item.avatar || TEAM.avatarUser} alt="dgg-network" />
                </div>
                <p>{item.name}</p>
                <img
                  src={ICONS.iconReject}
                  alt="dgg-network"
                  className="img-close"
                  onClick={() => handleRemoveMember(item.id)}
                  aria-hidden
                />
              </div>
            ))}
          </div>
          <div className="btn-clear">
            <p className="btn-clear-all" onClick={handleClearAll} aria-hidden>
              {t('clearAll')}
            </p>
          </div>
        </div>
      )}
      <div className="list-member">
        {inviteMember.length ? (
          <div className="show  list-card-invite-member">
            {inviteMember.map((member) => (
              <div className="card-invite-member" key={member.id}>
                <CardInviteMember
                  member={member}
                  newInviteMember={newInviteMember}
                  setNewInviteMember={setNewInviteMember}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="no-text">{t('team.thereAreNoPlayers')}</div>
        )}
        {isChecked ? (
          ''
        ) : (
          <div className="load-more">
            <TButton
              title={t('loadMore')}
              className="btn-load-more"
              onClick={handleLoadMore}
              type="reverse"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchCard;
