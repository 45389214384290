import Cookies from 'js-cookie';

export default function processDataLogin(data) {
  Cookies.set('token', data.access_token, {
    expires: new Date(new Date().getTime() + data?.expires_in * 1000),
    path: '/',
  });
  Cookies.set('refreshToken', data.refresh_token, {
    expires: new Date(new Date().getTime() + data?.refresh_expires_in * 1000),
    path: '/',
  });
}
