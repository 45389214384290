import React from 'react';

function EmailIcon(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.667 5a5 5 0 00-5 5v20a5 5 0 005 5h26.667a5 5 0 005-5V10a5 5 0 00-5-5H6.667zm4.4 7.053a1.667 1.667 0 10-2.134 2.56L16.8 21.17a5 5 0 006.402 0l7.866-6.555a1.667 1.667 0 10-2.134-2.561l-7.866 6.555a1.667 1.667 0 01-2.134 0l-7.866-6.555z"
        fill="#F5F5F5"
      />
    </svg>
  );
}

export default EmailIcon;
