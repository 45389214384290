export const NAV__LINKS = [
  {
    display: 'Scholarships',
    url: '/scholarships',
  },
  {
    display: 'Tournaments',
    url: '/tournament',
  },
  {
    display: 'Wallet',
    url: 'https://dgg.network/',
  },
  {
    display: 'Marketplace',
    url: '#',
  },
  {
    display: 'Contact Us',
    url: '/contact-us',
  },
  {
    display: 'Docs',
    url: 'https://docs.dgg.network',
  },
];

export const SUB_NAV_LINKS = [
  {
    display: 'Scholarships',
    url: '/scholarships',
  },
  {
    display: 'Tournament',
    url: '/tournament',
  },
  {
    display: 'My Tournament',
    url: '/my-tournament',
    style: 'children-tournament',
  },
  {
    display: 'My Team',
    url: '/my-team',
    style: 'children-tournament',
  },
  {
    display: 'Pending Team',
    url: '/pending-team',
    style: 'children-tournament',
  },
  {
    display: 'Profile',
    url: '/profile',
  },
  {
    display: 'Docs',
    url: 'https://docs.dgg.network',
  },
];

export const SUB_LINK = [
  { name: 'Description', key: 'description' },
  { name: 'Tokenomics', key: 'tokenomic' },
  { name: 'About Us', key: 'aboutUs' },
  { name: 'Team', key: 'team' },
];

export const SUB_LINK_TOURNAMENT_LIVE_NEW = [
  { name: 'Details', key: 'detail', tab: 0 },
  { name: 'Brackets', key: 'brackets', tab: 1 },
  { name: 'Participants', key: 'participants', tab: 2 },
  { name: 'Prizes', key: 'prizing', tab: 3 },
];

export const SUB_LINK_TOURNAMENT_PAST = [
  { name: 'Details', key: 'detail', tab: 0 },
  { name: 'Brackets', key: 'brackets', tab: 1 },
  { name: 'Participants', key: 'participants', tab: 2 },
  { name: 'Winners', key: 'prizing', tab: 3 },
];

export const SUB_LINK_TOURNAMENT_BATTLE_ROYALE = [
  { name: 'Details', key: 'detail', tab: 0 },
  { name: 'Participants', key: 'participants', tab: 2 },
  { name: 'Prizes', key: 'prizing', tab: 3 },
  { name: 'Statistics', key: 'statistics', tab: 4 },
];

export const SUB_LINK_TOURNAMENT_BATTLE_ROYALE_PAST = [
  { name: 'Details', key: 'detail', tab: 0 },
  { name: 'Participants', key: 'participants', tab: 2 },
  { name: 'Winner', key: 'prizing', tab: 3 },
];

export const SUB_LINK_TOURNAMENT_STATISTICS = [
  { name: 'Ticket masters', key: 'ticket-masters', tab: 0 },
  { name: 'Top Winners', key: 'top-winners', tab: 1 },
];

export const SUB_LINK_TOURNAMENT_DETAILS = {
  Details: 0,
  Brackets: 1,
  Participants: 2,
  Prizing: 3,
  Winners: 3,
  Statistics: 4,
};

export const SUB_NAV_TOURNAMENT_STATISTIC = {
  TicketMasters: 0,
  TopWinners: 1,
};

export const MODE_GAME = {
  AUTO: 1,
  MANUAL: 0,
};

export const MAX_LENGTH_NAME = 50;
export const MAX_LENGTH_NAME_SOCIAL = 100;
export const MAX_LENGTH_NAME_FB = 150;
export const MAX_LENGTH_NAME_NOTE = 100;
export const MAX_LENGTH_EMAIL = 254;
export const MIN_LENGTH_PHONE = 10;

export const RENTAL_UN_LIST = 0;
export const RENTAL_AVAILABLE = 1;
export const RENTAL_PENDING = 2;
export const RENTAL_RENTED = 3;

export const ERR_MESSAGE = 'Something went wrong!';
export const DONATE_SUCCESS_MESSAGE = 'Your donation was successful!';
export const DONATE_ERR_MESSAGE = 'Failed to donate!';
export const JOIN_TOUR_SUCCESS_MESSAGE = 'Payment successful!';
export const JOIN_TOUR_ERR_MESSAGE = 'Failed to join tournament!';
export const TEAM_NOT_ENOUGH_MEMBER_MESSAGE =
  "Your team doesn't have enough players, please invite more players";
export const TEAM_OVER_MAX_SIZE_MESSAGE =
  'The number of players in your team is over the maximum size';
export const DELETE_TEAM_MEMBER_MESSAGE = 'Delete member successfully!';
export const DELETE_TEAM_INVITE_MESSAGE = 'Delete invite successfully!';
export const ERROR_MESSAGE_SEND_NFT = 'Failed to send NFT';

export const APPLY_NOT_SEEN = 0;
export const APPLY_APPROVING = 1;
export const APPLY_APPROVED = 2;
export const APPLY_REJECT = 3;
export const APPLY_FORM_SCHOLARSHIP = 'APPLY_FORM_SCHOLARSHIP';

export const READ_NOTIFY_STATUS = 1;
export const UN_READ_NOTIFY_STATUS = 0;
export const CURRENT_PAGE = 1;

export const TOTAL_RECORD = 100;
export const RECORDS = 10;

export const FILTER = [
  { name: 'GAME', id: '1', key: 'gameId' },
  { name: 'TOKEN', id: '2', key: 'tokenId' },
  { name: 'COUNTRY', id: '3', key: 'countryId' },
];

export const STATUS_TOURNAMENT = [
  { name: 'Live', code: '2', id: '2' },
  { name: 'New', code: '1', id: '1' },
  { name: 'Done', code: '0', id: '0' },
];

export const STATUS_TOURNAMENT_DETAIL = {
  Done: 0,
  New: 1,
  Live: 2,
};
export const STATUS_JOIN_TOURNAMENT = {
  NOT_JOIN: -1,
  REQUEST: 0,
  APPLY: 1,
  REJECT: 2,
  WAITING_FOR_CONFIRM: 3,
  PAID: 4,
  PAID_FALSE: 5,
};
export const TYPE_TOURNAMENT = {
  INSTANT: 1,
};

export const DATE_FORMAT = {
  cardTournament: 'MMM D, h:mm A',
};
export const SELECT_BRACKET = [
  { id: 1, name: 'Single elimination' },
  { id: 2, name: 'Double elimination' },
  { id: 3, name: 'Round robin' },
  { id: 4, name: 'Battle royale' },
];
export const SELECT_SIZE = [
  { id: 2, name: 2 },
  { id: 4, name: 4 },
  { id: 8, name: 8 },
  { id: 16, name: 16 },
  { id: 32, name: 32 },
  { id: 64, name: 64 },
  { id: 128, name: 128 },
  { id: 256, name: 256 },
];

export const MY_TOURNAMENT_SORT = {
  DESC: 'desc',
  ASC: 'asc',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  STATUS: 'status',
  PRIZE: 'prize_amount',
};

export const OTP_COUNTDOWN_TIME = 60;
export const PRIZES = {
  FIRST_PRIZE: 0,
  SECOND_PRIZE: 1,
  THIRD_PRIZE: 2,
};
export const UNIT_TOKEN = {
  1: 'BIT',
  2: 'ETH',
  3: 'BNB',
  4: 'AVAX',
};
export const MIN_AGE = '2004-12-31';
export const MAX_TYPE_DATE = '9999-12-31';

export const MARKETPLACE_FRONTEND_URL = process.env.MARKETPLACE_FRONTEND_ENDPOINT;
export const PRODUCT_ENV = process.env.APP_ENV || 'dev';

export const SupportedChainId = {
  ARBITRUM_ONE: 42161,
  ARBITRUM_GOERLI: 421613,
  POLYGON_MAINNET: 137,
  POLYGON_MUMBAI: 80001,
  ARBITRUM_SEPOLIA: 421614,
};

export const TRANSACTION_FEE_DEFAULT = {
  ARBITRUM: 0.0008,
  POLYGON: 0.1,
};

export const BUY_CRYPTO_LINK = {
  ALINIEX: 'https://aliniex.com/orders?',
  OKX: 'https://www.okx.com/join/88670223 ',
  GATE: 'https://www.gate.io/signup/UVUQUAha?ref_type=103',
};

export const ALINIEX_REF_CODE = 'yRBOQXRzqk';

export const PAYMENT_TOKEN_NAME = {
  USDT: 'usdt',
};

export const CHAIN_NAME_TYPE = {
  ARBITRUM: 'arb',
  POLYGON: 'prc20',
};

export const CONTRACT_BY_CHAIN = {
  [SupportedChainId.ARBITRUM_ONE]: {
    tournamentAddress: process.env.TOURNAMENT_ARBITRUM_CONTRACT_ADDRESS,
  },
  [SupportedChainId.ARBITRUM_GOERLI]: {
    tournamentAddress: process.env.TOURNAMENT_ARBITRUM_CONTRACT_ADDRESS,
  },
  [SupportedChainId.ARBITRUM_SEPOLIA]: {
    tournamentAddress: process.env.TOURNAMENT_ARBITRUM_CONTRACT_ADDRESS,
  },
  [SupportedChainId.POLYGON_MAINNET]: {
    tournamentAddress: process.env.TOURNAMENT_POLYGON_CONTRACT_ADDRESS,
  },
  [SupportedChainId.POLYGON_MUMBAI]: {
    tournamentAddress: process.env.TOURNAMENT_POLYGON_CONTRACT_ADDRESS,
  },
};

export const GAS_PRICE_DEFAULT = {
  ARBITRUM: 100000000,
  POLYGON: 80000000000,
};
export const GAS_PRICE_RATE = {
  ARBITRUM: 1,
  POLYGON: 1.1,
};

export const CHAIN_INFO = {
  [SupportedChainId.ARBITRUM_ONE]: {
    chainId: SupportedChainId.ARBITRUM_ONE,
    chainName: 'Arbitrum One',
    chainImage: '/images/arbitrum-logo-demo.png',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io'],
    AlchemyNetWork: 'arb-mainnet',
    gasPriceDefault: GAS_PRICE_DEFAULT.ARBITRUM,
    gasPriceRate: GAS_PRICE_RATE.ARBITRUM,
    tokenAliniexSupport: PAYMENT_TOKEN_NAME.USDT,
    chainAliniexType: CHAIN_NAME_TYPE.ARBITRUM,
  },
  [SupportedChainId.ARBITRUM_GOERLI]: {
    chainId: SupportedChainId.ARBITRUM_GOERLI,
    chainName: 'Arbitrum Goerli',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    chainImage: '/images/arbitrum-logo-demo.png',
    rpcUrls: ['https://goerli.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://goerli.arbiscan.io'],
    AlchemyNetWork: 'arb-goerli',
    gasPriceDefault: GAS_PRICE_DEFAULT.ARBITRUM,
    gasPriceRate: GAS_PRICE_RATE.ARBITRUM,
    tokenAliniexSupport: PAYMENT_TOKEN_NAME.USDT,
    chainAliniexType: CHAIN_NAME_TYPE.ARBITRUM,
  },
  [SupportedChainId.ARBITRUM_SEPOLIA]: {
    chainId: SupportedChainId.ARBITRUM_SEPOLIA,
    chainName: 'Arbitrum Sepolia',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    chainImage: '/images/arbitrum-logo-demo.png',
    rpcUrls: ['https://sepolia-rollup.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://sepolia.arbiscan.io/'],
    AlchemyNetWork: 'arb-sepolia',
    gasPriceDefault: GAS_PRICE_DEFAULT.ARBITRUM,
    gasPriceRate: GAS_PRICE_RATE.ARBITRUM,
    tokenAliniexSupport: PAYMENT_TOKEN_NAME.USDT,
    chainAliniexType: CHAIN_NAME_TYPE.ARBITRUM,
  },
  [SupportedChainId.POLYGON_MAINNET]: {
    chainId: SupportedChainId.POLYGON_MAINNET,
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'Polygon Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    chainImage: '/images/networks/polygon-logo.png',
    rpcUrls: ['https://polygon-mainnet.infura.io'],
    blockExplorerUrls: ['https://polygonscan.com/'],
    AlchemyNetWork: 'polygon-mainnet',
    gasPriceDefault: GAS_PRICE_DEFAULT.POLYGON,
    gasPriceRate: GAS_PRICE_RATE.POLYGON,
    tokenAliniexSupport: PAYMENT_TOKEN_NAME.USDT,
    chainAliniexType: CHAIN_NAME_TYPE.POLYGON,
  },
  [SupportedChainId.POLYGON_MUMBAI]: {
    chainId: SupportedChainId.POLYGON_MUMBAI,
    chainName: 'Polygon Mumbai',
    nativeCurrency: {
      name: 'Polygon Mumbai Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    chainImage: '/images/networks/polygon-logo.png',
    rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
    AlchemyNetWork: 'polygon-mumbai',
    gasPriceDefault: GAS_PRICE_DEFAULT.POLYGON,
    gasPriceRate: GAS_PRICE_RATE.POLYGON,
    tokenAliniexSupport: PAYMENT_TOKEN_NAME.USDT,
    chainAliniexType: CHAIN_NAME_TYPE.POLYGON,
  },
};

export const WALLET_APPSTORE_URL = 'https://apps.apple.com/vn/app/dgg-wallet/id1659618218';
export const WALLET_GOOGLEPLAY_URL =
  'https://play.google.com/store/apps/details?id=network.dgg.wallet';

export const ERROR_CLOSE_MODAL_CONNECT_WALLET = 'Modal closed by user';

export const REFERRAL_REWARD_TYPE = {
  EARNING: 'Earned',
  RECEIVED: 'Received',
};

export const WALLET_CONNECT_BRIDGE = 'https://bridge.walletconnect.org';

export const ACTIVATE_STATUS = {
  ACTIVATE: 'activated',
  DEACTIVATE: 'deactivated',
};

export const METAMASK_REDIRECT_TO_APP_LINK = 'https://metamask.app.link/dapp/';

export const DGG_REDIRECT_TO_APP_LINK = 'https://dggnetwork.app.link/dapp?url=';

export const RPC_URL = 'https://arb-goerli.g.alchemy.com/v2/IurXn4sxlKYzVPmBt1WALRj3AlzNHGTF';

export const DECIMAL_ID = 10;

export const ERROR_MISSING_CHAIN_IN_METAMASK = 4902;

export const ERROR_DISCONNECTED_PROVIDER = 4901;

export const refreshAccessTokenUrl = '/auth/refresh-token';

export const DGG_PRIVACY_POLICY_URL = 'https://docs.dgg.network/help-and-feedback/privacy-policy';

export const TIME_OUT_API_MILISEC = 1000000;

export const DGG_FAQ_URL = 'https://docs.dgg.network/help-and-feedback/faq';

export const DGG_DOCS_URL = 'https://docs.dgg.network';

export const SEARCH_TYPE = 'search';

export const GAME_TYPE = 'game';

export const ROUTER__LINK = {
  SCHOLARSHIPS: '/scholarships',
  TOURNAMENT: '/tournament',
};

export const EVENT__KEY = {
  ENTER: 'Enter',
};

export const COPY_TIME_INTERVAL = 2500;

export const TIME_SECOND_TO_MILISECOND = 1000;

export const REQUEST_TIMEOUT_MILISECOND = 3000;

export const BRIDGE_WALLETCONNECT_URL = 'https://bridge.walletconnect.org';

export const METAMASK_DOWNLOAD_URL = 'https://metamask.io/download.html';

export const CreateRequestStatus = {
  INIT: 'Init', // initial state
  CREATED: 'Created',
  FAILED: 'Failed',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  SIGNING: 'Signing',
};

export const SendingRequestSCStatus = {
  INIT: 'Init', // initial state
  CREATED: 'Created',
  FAILED: 'Failed',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  SIGNING: 'Signing',
};

export const ConfirmRequestBackendStatus = {
  INIT: 'Init', // initial state
  CREATED: 'Created',
  FAILED: 'Failed',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  SIGNING: 'Signing',
};

export const ClaimTicketSCStatus = {
  INIT: 'Init', // initial state
  CREATED: 'Created',
  FAILED: 'Failed',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  SIGNING: 'Signing',
};

export const GAS_LIMIT = 2_000_000;

export const PANCAKE_SWAP_GUIDE = 'https://docs.pancakeswap.finance/get-started/connection-guide';

export const TESTNET = 'testnet';

export const DGG_SOCIAL_NETWORK_LINK = {
  FACEBOOK: 'https://www.facebook.com/dggnetwork',
  TWITTER: 'https://twitter.com/DggNetwork',
  DISCORD: 'https://discord.gg/KnfTAmydgK',
  TELEGRAM: 'https://t.me/dgg_network',
};

export const MEDIUM_SCREEN_WIDTH = 768;

export const APP_ENV_LIST = {
  PRODUCTION: 'production',
  DEVELOP: 'dev',
};

export const GTAG_KEY = process.env.GTAG_ID;

export const TYPE_LANGUAGE_DROPDOWN = 'type_language';

export const VN_FLAG_IMG = 'https://flagicons.lipis.dev/flags/1x1/vn.svg';

export const WALLET_ERROR_REASON = {
  INSUFFICIENT_FUND: 'insufficient funds for intrinsic transaction cost',
  INVALID_CHAIN: 'underlying network changed',
  CANNOT_ESTIMATE: 'cannot estimate gas; transaction may fail or may require manual gas limit',
  USER_REJECT: 'user rejected transaction',
};

export const WALLET_ERROR_RESPONSE = {
  INSUFFICIENT_FUND:
    'Please make sure to have ETH (Arbitrum) or MATIC (Polygon) to pay the gas fee.',
};

export const ARBITRUM_CHAIN_ID = process.env.ARBITRUM_CHAIN_ID;

export const POLYGON_CHAIN_ID = process.env.POLYGON_CHAIN_ID;
