import './styles.scss';
import React from 'react';

function PrimaryInput({
  title = null,
  className = '',
  placeholder = '',
  type = '',
  onChange = null,
  classNameInput = '',
  id = '',
  message = '',
  note = null,
  accessoriesLeft = null,
  accessoriesRight = null,
  value = undefined,
  disabled = false,
  ...props
}) {
  return (
    <div className={`${className} new-primary-input`}>
      {title && <div className="title-input">{title}</div>}
      {note && <div className="note-input">{note}</div>}
      <div className="input-wrapper">
        {accessoriesLeft && <div className="accessory-left">{accessoriesLeft}</div>}

        <input
          id={id}
          placeholder={placeholder}
          {...props}
          type={type}
          onChange={onChange}
          value={value}
          disabled={disabled}
          className={`primary-input ${accessoriesLeft && 'padding-left'} ${
            accessoriesRight && 'padding-right'
          }
              ${classNameInput}`}
        />
        <span className="form-message">{message}</span>
        {accessoriesRight && <div className="accessory-right">{accessoriesRight}</div>}
      </div>
    </div>
  );
}

export default PrimaryInput;
