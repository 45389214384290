import React from 'react';

function MinusBorderIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M14.666 0H5.332C1.928 0 0 1.929 0 5.333v9.334c0 3.395 1.92 5.333 5.333 5.333h9.334c3.404 0 5.333-1.938 5.333-5.333V5.333C19.999 1.93 18.07 0 14.666 0z"
        fill="#0FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.666 10a1 1 0 011-1h6.667a1 1 0 110 2H6.666a1 1 0 01-1-1z"
        fill="#0FF"
      />
    </svg>
  );
}

export default MinusBorderIcon;
