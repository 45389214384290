import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import TButton from '../Button';
import VIcon from '../../icons/VIcon';
import { COPY_TIME_INTERVAL } from '../../../constants';

function ModalShare(props) {
  const { t } = useTranslation();
  const { show, setShow, slug } = props;
  const shareRef = useRef(null);
  const [hrefShare, setHrefShare] = useState('');
  const [copiedText, setCopiedText] = useState(false);

  useEffect(() => {
    const resetState = setInterval(() => {
      setCopiedText(false);
    }, COPY_TIME_INTERVAL);
    return () => clearInterval(resetState);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (shareRef.current && !shareRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [shareRef]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHrefShare(window.location.href);
    }
  }, []);

  return (
    <div ref={shareRef} className={show ? 'modal-share show-modal-share' : 'modal-share'}>
      <h1 className="share-title">{t('shareSocial')}</h1>
      <div className="list-social-wrapper">
        <FacebookShareButton url={hrefShare} quote="Join our tournament!" hashtag={`#${slug}`}>
          <FacebookIcon size={48} round="true" />
        </FacebookShareButton>
        <TwitterShareButton url={hrefShare} title="Join our tournament!">
          <TwitterIcon size={48} round="true" />
        </TwitterShareButton>
        <TelegramShareButton url={hrefShare} title="Join our tournament!">
          <TelegramIcon size={48} round="true" />
        </TelegramShareButton>
      </div>
      <div className="copy-link-wrapper">
        <p className="title-copy-link">{t('copy.orCopyLink')}</p>
        <div className="shareable-wrapper">
          <div className="shareable-link">{hrefShare}</div>
          <CopyToClipboard text={hrefShare} onCopy={() => setCopiedText(true)}>
            <TButton
              title={copiedText ? <VIcon color="#00FFFF" /> : t('copy.default')}
              id="copy-shareable-link"
              className="btn-modal-share"
            />
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}

export default ModalShare;
