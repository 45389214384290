import React from 'react';
import { Col } from 'reactstrap';
import { SCHOLARSHIP } from '../../../../assets/imgs';
import Layout from '../Layout';
import NavBar from '../NavBar';

function NewLayout(props) {
  const { dataConnect, title, type, link } = props;
  return (
    <Layout dataConnect={dataConnect} title={title} type={type} link={link}>
      <Col>
        <div className="scholarship-banner">
          <img src={SCHOLARSHIP.bannerScholarship} alt="banner" />
        </div>
      </Col>
      <NavBar />
      <div>{props.children}</div>
    </Layout>
  );
}

export default NewLayout;
