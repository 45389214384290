import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS, TOURNAMENT } from '../../../../assets/imgs';
import { getTranslatedValue } from '../../../lib/getTranslatedValue';

function InstantCardTournament({ data, activeSlide = false }) {
  const { t } = useTranslation();
  const renderJoinFee = () => {
    if (data?.ticketCollection) {
      return (
        <div className="bracket-wrapper fee-wrapper">
          {t('fee')}: 01 <img className="ticket-icon" src={ICONS.ticketIcon} alt="ticket-icon" /> ≈{' '}
          {data.ticketCollection.ticketType.price} {data.ticketCollection.paymentToken.symbol}
        </div>
      );
    }

    return data.join_fee ? (
      <div className="bracket-wrapper fee-wrapper">
        {t('fee')}: {`${data.join_fee || 0} ${data.applicationNetwork?.symbol}`}
      </div>
    ) : (
      <div className="bracket-wrapper">{t('feeToJoin')}</div>
    );
  };

  return (
    <div className="thumbnail-tour-wrapper">
      <div className="img-tournament-wrapper">
        <img src={data?.image || TOURNAMENT.noImage} alt="dgg" className="img-tournament" />
      </div>
      <div className={!activeSlide ? 'black-wrapper' : 'gray-wrapper'} />
      <div className="absolute-card-left-wrapper">
        <img
          className="image-icon profile-image-icon"
          src={ICONS.iconProfileTournament}
          alt="dgg-network"
        />
        <div className="number-card-team">{data?.totalTeams}</div>
      </div>
      <div className="absolute-card-right-chain">
        <img
          className="image-icon"
          src={data?.ticketCollection?.paymentToken?.chain?.image || ''}
          alt="chain-logo"
        />
      </div>

      <div className="absolute-bottom-tournament">
        <div className="bottom-tournament-wrapper">
          <div className="name-game-uppercase">{data?.applicationGame?.name}</div>
          <div className="name-game">{getTranslatedValue(data, 'name')}</div>
        </div>
        <div className="bottom-tournament-wrapper">
          {renderJoinFee()}
          <div className="bracket-wrapper">{data?.applicationBracket}</div>
        </div>
      </div>
    </div>
  );
}

export default InstantCardTournament;
