import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import TButton from '../../../components/common/Button';
import InputField from '../../../components/common/Input';
import NewLayout from '../../../components/common/NewLayout/NewLayout';
import useMessage from '../../../components/common/toast/UseMessage';
import AddInputEmail from '../../../components/pages/MyTeam/AddInputEmail';
import SearchCard from '../../../components/pages/MyTeam/SearchCard';
import { ERR_MESSAGE, EVENT__KEY, MAX_LENGTH_NAME } from '../../../constants';
import tournamentApi from '../../../services/tournament';
import { useAppDispatch } from '../../../store';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import { validateEmail } from '../../../utils/validator';
import './styles.scss';

function CreateMyTeam(props) {
  const { dataConnect } = props;
  const { t } = useTranslation();
  const [teamName, setTeamName] = useState('');
  const [listEmail, setListEmail] = useState([
    {
      id: v4(),
      email: '',
    },
  ]);
  const [searchInviteMember, setSearchInviteMember] = useState('');
  const dispatch = useAppDispatch();
  const [inviteMember, setInviteMember] = useState([]);
  const [newInviteMember, setNewInviteMember] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const { openMessageError, openMessage } = useMessage();
  const history = useHistory();
  const getInviteMember = async () => {
    dispatch(showLoading());
    try {
      const params = {
        limit: 12,
        page: currentPage,
        name: searchInviteMember,
      };
      const res = await tournamentApi.getInviteMembers(params);
      const { data } = res;
      setInviteMember(data.data);
      setTotalPage(data.totalPage);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    getInviteMember();
  }, []);
  useEffect(() => {
    if (currentPage === totalPage) {
      setIsChecked(true);
    }
  }, [currentPage]);
  const handleAddEmail = () => {
    setListEmail((v) => [
      ...v,
      {
        id: v4(),
        email: '',
      },
    ]);
  };
  const handleCreateTeam = async () => {
    if (!teamName) return;
    dispatch(showLoading());
    const payloadName = {
      name: teamName,
      description: '',
    };
    let isValidate = true;
    const userIds = newInviteMember.filter((item) => item.id).map((item) => item.id);
    const emails = listEmail.filter((item) => item.email).map((item) => item.email);
    for (let i = 0; i < emails.length; i += 1) {
      if (validateEmail(emails[i])) {
        isValidate = false;
        break;
      }
    }
    try {
      if (isValidate) {
        const { data } = await tournamentApi.createMyTeam(payloadName);
        const payloadMember = {
          teamId: data.idNewTeam,
          userIds,
        };
        const payloadEmail = {
          teamId: data.idNewTeam,
          emails,
        };
        if (emails.length) {
          await tournamentApi.inviteEmail(payloadEmail);
        }
        if (userIds.length) {
          await tournamentApi.inviteMember(payloadMember);
        }
        openMessage({ message: t('success.createTeamSuccessful') });
        history.push('/my-team');
      } else {
        openMessageError(t('warning.validateEmail.default'));
      }
    } catch (error) {
      openMessageError(error.response.data.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const resetInviteMember = async () => {
    dispatch(showLoading());
    try {
      const params = {
        name: '',
        limit: 12,
        page: 1,
      };
      const res = await tournamentApi.getInviteMembers(params);
      const { data } = res;
      setInviteMember(data.data);
      setTotalPage(data.totalPage);
      setCurrentPage(1);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleOnChangeSearch = (value) => {
    setSearchInviteMember(value);
    if (!value) {
      resetInviteMember();
    }
  };
  const handleSearchKeyDown = (event) => {
    if (event.key === EVENT__KEY.ENTER) {
      getInviteMember();
    }
  };
  const handleLoadMore = async () => {
    dispatch(showLoading());
    try {
      const params = {
        limit: 12,
        name: searchInviteMember,
        page: Number(currentPage + 1),
      };
      const res = await tournamentApi.getInviteMembers(params);
      const { data } = res;
      setCurrentPage(currentPage + 1);
      setInviteMember([...inviteMember, ...data.data]);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  return (
    <NewLayout dataConnect={dataConnect} title={t('team.createNewTeam')} type={1} link="/my-team">
      <div className="create-my-team">
        <div className="title-new-team">
          <TButton
            title={t('team.createNewTeam')}
            className="btn-create-team"
            onClick={handleCreateTeam}
          />
        </div>
        <div className="form-create-team">
          <InputField
            type="text"
            placeholder={t('team.enterATeamName')}
            value={teamName}
            onChange={setTeamName}
            maxLength={MAX_LENGTH_NAME}
            title={t('team.teamName')}
            isRequired
          />
          <h6>{t('warning.invitePlayersByEmail')}</h6>
          <AddInputEmail
            listEmail={listEmail}
            setListEmail={setListEmail}
            handleAddEmail={handleAddEmail}
          />
          <SearchCard
            title={t('team.findExistingPlayers')}
            search={searchInviteMember}
            onChange={handleOnChangeSearch}
            onKeyDown={handleSearchKeyDown}
            inviteMember={inviteMember}
            newInviteMember={newInviteMember}
            setNewInviteMember={setNewInviteMember}
            handleSearchIconPress={getInviteMember}
            handleLoadMore={handleLoadMore}
            isChecked={isChecked}
          />
        </div>
      </div>
    </NewLayout>
  );
}

export default CreateMyTeam;
