import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import DonutChart from '../DonutChart';

function ReferralOverview({ referralOverview }) {
  const { t } = useTranslation();
  return (
    <div className="referral-overview">
      <div className="total-referral">
        <div className="total-ref-value">{referralOverview?.totalReferrals}</div>
        <p className="referral-small-title">{t('referral.totalReferrals')}</p>
      </div>
      <DonutChart
        amountData={referralOverview?.summaries}
        totalNumber={referralOverview?.totalReferrals}
        title={t('referral.referralTiers')}
      />
    </div>
  );
}

export default ReferralOverview;
