import { useEffect, useRef, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { simpleRpcProvider } from '../lib/providers';

/**
 * The function `useActiveWeb3React` returns the active Web3React provider, with the option to
 * prioritize a specific network ID.
 * @param {number} [priorityNetworkId] - The `priorityNetworkId` parameter is an optional number that
 * represents the network ID of the priority network. It is used to determine the priority provider for
 * the `useActiveWeb3React` hook.
 * @returns The function `useActiveWeb3React` returns an object that includes the properties and
 * methods from the `web3React` object, as well as a `provider` property.
 */
const useActiveWeb3React = (priorityNetworkId = undefined) => {
  const { hooks, ...web3React } = useWeb3React();

  const { usePriorityProvider } = hooks;

  const priorityProvider = usePriorityProvider(priorityNetworkId);

  const refEth = useRef(priorityProvider);

  const [provider, setProvider] = useState(priorityProvider || simpleRpcProvider);

  useEffect(() => {
    if (priorityProvider !== refEth.current) {
      setProvider(priorityProvider || simpleRpcProvider);
      refEth.current = priorityProvider;
    }
  }, [priorityProvider]);

  return {
    ...web3React,
    provider,
  };
};

export default useActiveWeb3React;
