import React, { useEffect, useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import {
  ERR_MESSAGE,
  SUB_LINK_TOURNAMENT_STATISTICS,
  SUB_NAV_TOURNAMENT_STATISTIC,
} from '../../../../constants';
import ProfileIcon from '../../../icons/ProfileIcon';
import FlagIcon from '../../../icons/FlagIcon';
import StatisticIcon from '../../../icons/StatisticIcon';
import TicketMasterTab from './TicketMasterTab';
import TopWinnerTab from './TopWinnerTab';
import { useAppDispatch } from '../../../../store';
import { hideLoading, showLoading } from '../../../../store/features/loadingPage';
import tournamentApi from '../../../../services/tournament';
import useMessage from '../../../common/toast/UseMessage';
import formatPointNumber from '../../../../lib/formatPointNumber';

function StatisticsTab(props) {
  const { tournament } = props;
  const { t } = useTranslation();
  const { openMessageError } = useMessage();
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState(0);
  const handleClickTab = (index) => {
    setTab(index);
  };
  const [tournamentStatistic, setTournamentStatistic] = useState();

  const getTournamentStatisticDetail = async () => {
    dispatch(showLoading());
    try {
      if (tournament?.id) {
        const res = await tournamentApi.getTournamentStatistic(tournament?.id);
        setTournamentStatistic(res.data);
      }
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    if (tournament?.id) {
      getTournamentStatisticDetail();
    }
  }, [tournament?.id]);

  return (
    <div>
      <div className="statistic-summarize-wrapper">
        <SummaryItem
          icon={<ProfileIcon />}
          title="Total gamers"
          value={tournamentStatistic?.totalGamers || '0'}
        />
        <SummaryItem
          icon={<FlagIcon />}
          title="Completed Matches"
          value={tournamentStatistic?.completedMatches || '0'}
        />
        <SummaryItem
          icon={<StatisticIcon />}
          title="Volume"
          value={
            tournamentStatistic?.volume ? formatPointNumber(tournamentStatistic?.volume, 4) : '0'
          }
          valueUnit="$"
        />
      </div>
      <div className="top-ticket-wrapper">
        <div className="list-info-wrapper hide-scrollbar">
          {SUB_LINK_TOURNAMENT_STATISTICS.map((link) => (
            <div
              key={link.tab}
              className={`info-item ${link.tab === tab ? 'info-tab-active' : 'info-tab-hover'}`}
            >
              <h4 onClick={() => handleClickTab(link.tab)} aria-hidden="true">
                {t(link.name)}
              </h4>
            </div>
          ))}
        </div>
        <TabContent activeTab={tab}>
          <TabPane tabId={SUB_NAV_TOURNAMENT_STATISTIC.TicketMasters}>
            <TicketMasterTab tournament={tournament} />
          </TabPane>
          <TabPane tabId={SUB_NAV_TOURNAMENT_STATISTIC.TopWinners}>
            <TopWinnerTab tournament={tournament} />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}

export default StatisticsTab;

function SummaryItem({ icon, title = '', value = '', valueUnit = null }) {
  const { t } = useTranslation();
  return (
    <div className="item-wrapper">
      <div className="item-content">
        <div className="item-value">
          {value} {valueUnit}
        </div>
        <div className="item-title">{t(title)}</div>
      </div>
      <div className="icon-item">{icon}</div>
    </div>
  );
}
