import React, { useState, useEffect } from 'react';
import { Col, Row, Table } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/common/Layout';
import TournamentItem from '../../components/pages/MyTournament/TournamentItem';
import NoTournament from '../../components/pages/MyTournament/NoTournament';
import tournamentApi from '../../services/tournament';
import { useAppDispatch } from '../../store';
import SearchTable from '../../components/common/Search/SearchTable';
import { ERR_MESSAGE, EVENT__KEY, MY_TOURNAMENT_SORT } from '../../constants';
import { SCHOLARSHIP } from '../../../assets/imgs/index';
import useMessage from '../../components/common/toast/UseMessage';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import Pagination from '../../components/common/Pagination';
import SortIcon from '../../components/pages/MyTournament/SortIcon';
import './style.scss';
import TButton from '../../components/common/Button';
import UnauthorizePage from '../UnauthorizePage/UnauthorizePage';
import { useAuthContext } from '../../context/AuthContext';

function MyTournament(props) {
  const { dataConnect } = props;
  const { t } = useTranslation();
  const [tournaments, setTournament] = useState([]);
  const { openMessageError } = useMessage();
  const dispatch = useAppDispatch();
  const itemsPerPage = 5;
  const [totalPage, setTotalPage] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const history = useHistory();
  const [sortBy, setSortBy] = useState();
  const [sortType, setSortType] = useState();
  const { ASC, DESC, START_DATE, END_DATE, STATUS, PRIZE } = MY_TOURNAMENT_SORT;
  const { isLogin } = useAuthContext();

  const getMyTournaments = async () => {
    dispatch(showLoading());
    const params = {
      page: currentPage,
      limit: itemsPerPage,
      name: search,
      sort_by: sortBy,
      sort_type: sortType,
    };
    try {
      const res = await tournamentApi.getMyTournaments(params);
      setTournament(res.data.tournaments);
      setTotalPage(res.data.totalPage);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === EVENT__KEY.ENTER) {
      getMyTournaments().catch((error) => {
        openMessageError('Error: ', error);
      });
    }
  };

  const resetMyTournaments = async () => {
    dispatch(showLoading());
    const params = {
      page: 1,
      limit: itemsPerPage,
      name: '',
      sort_by: sortBy,
      sort_type: sortType,
    };
    try {
      const res = await tournamentApi.getMyTournaments(params);
      setCurrentPage(1);
      setTournament(res.data.tournaments);
      setTotalPage(res.data.totalPage);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleChangeSearch = (value) => {
    setSearch(value);
    if (!value) {
      resetMyTournaments().catch((error) => {
        openMessageError('Error: ', error);
      });
    }
  };

  useEffect(() => {
    getMyTournaments().catch((error) => {
      openMessageError('Error: ', error);
    });
  }, [currentPage, sortType, sortBy]);

  const handleSort = (name) => {
    if (name === sortBy) {
      setSortType(sortType === DESC ? ASC : DESC);
    } else {
      setSortType(ASC);
    }
    setSortBy(name);
  };

  return (
    <Layout
      title={t('tournament.myTournament')}
      dataConnect={dataConnect}
      type={1}
      link="/tournament"
    >
      {isLogin ? (
        <div className="wrap-tournament">
          <Col>
            <div className="scholarship-banner">
              <img src={SCHOLARSHIP.bannerScholarship} alt="banner" />
            </div>
          </Col>
          <Row className="action-button-wrapper">
            <Col lg="4" md="4" sm="12">
              <TButton
                title={t('tournament.exploreTournaments')}
                className="explore-button"
                onClick={() => history.push('/tournament/explore')}
              />
            </Col>
            <Col lg="5" md="5" sm="12">
              <SearchTable
                search={search}
                setSearch={handleChangeSearch}
                onKeyDown={handleSearchKeyDown}
                placeholder={t('search.searchTournament')}
                handleSearchIconPress={getMyTournaments}
              />
            </Col>
          </Row>
          {tournaments.length ? (
            <div className="wrap-list-tournament">
              <div>
                <div className="tournament-table-wrapper">
                  <Table className="tournament-table">
                    <thead className="tournament-header-wrapper">
                      <tr>
                        <th className="title-wrapper">
                          <h6>{t('header.tournaments')}</h6>
                        </th>
                        <th className="title-wrapper text-center">
                          <h6 onClick={() => handleSort(STATUS)} aria-hidden>
                            {t('status.default')}
                            <SortIcon sortBy={sortBy} sortType={sortType} currentSortBy={STATUS} />
                          </h6>
                        </th>
                        <th className="title-wrapper text-center">
                          <h6 onClick={() => handleSort(START_DATE)} aria-hidden>
                            {t('filter.start')}
                            <SortIcon
                              sortBy={sortBy}
                              sortType={sortType}
                              currentSortBy={START_DATE}
                            />
                          </h6>
                        </th>
                        <th className="title-wrapper text-center">
                          <h6 onClick={() => handleSort(END_DATE)} aria-hidden>
                            {t('filter.end')}
                            <SortIcon
                              sortBy={sortBy}
                              sortType={sortType}
                              currentSortBy={END_DATE}
                            />
                          </h6>
                        </th>
                        <th className="title-wrapper text-center">
                          <h6 aria-hidden>{t('Network')}</h6>
                        </th>
                        <th className="title-wrapper text-center">
                          <h6 onClick={() => handleSort(PRIZE)} aria-hidden>
                            {t('prize')}
                            <SortIcon sortBy={sortBy} sortType={sortType} currentSortBy={PRIZE} />
                          </h6>
                        </th>
                      </tr>
                    </thead>
                    {tournaments.map((tournament) => (
                      <TournamentItem tournament={tournament} key={tournament.id} />
                    ))}
                  </Table>
                </div>
                <Pagination
                  totalPage={totalPage}
                  onChange={handlePageChange}
                  forcePage={currentPage}
                />
              </div>
            </div>
          ) : (
            <NoTournament />
          )}
        </div>
      ) : (
        <UnauthorizePage />
      )}
    </Layout>
  );
}

export default MyTournament;
