import React, { useState, useEffect } from 'react';
import { BRACKETS } from '../../../../constants/bracket';
import DoubleElimination from '../../DoubleElimination';
import RoundRobin from '../../RoundRobin';
import SingleElimination from '../../SingleElimination';
import './styles.scss';

function Bracket(props) {
  const { dataBracket, tournament } = props;
  const [bracket, setBracket] = useState(0);
  useEffect(() => {
    setBracket(tournament.bracket ?? 0);
  }, [tournament]);

  const renderBracket = () => {
    if (dataBracket && bracket) {
      if (BRACKETS.SINGLE_ELIMINATION === bracket) {
        return <SingleElimination dataBracket={dataBracket} />;
      }
      if (BRACKETS.DOUBLE_ELIMINATION === bracket) {
        return <DoubleElimination dataBracket={dataBracket} />;
      }
      if (BRACKETS.ROUND_ROBIN === bracket) {
        return <RoundRobin dataBracket={dataBracket} />;
      }
    }
    return null;
  };

  return <div style={{ width: '100%', minHeight: '360px' }}>{renderBracket()}</div>;
}

export default Bracket;
