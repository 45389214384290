import React from 'react';

function PlusBorderIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M14.667 0H5.333C1.93 0 0 1.929 0 5.333v9.334C0 18.062 1.92 20 5.333 20h9.334C18.07 20 20 18.062 20 14.667V5.333C20 1.93 18.071 0 14.667 0z"
        fill="#0FF"
      />
      <path
        d="M13.32 10.708h-2.57v2.549c0 .41-.336.743-.75.743a.746.746 0 01-.75-.743v-2.549H6.68A.753.753 0 016 9.961c0-.385.293-.707.68-.747h2.562v-2.54A.755.755 0 019.996 6c.388 0 .713.29.753.674v2.54h2.571c.387.04.68.362.68.747a.753.753 0 01-.68.747z"
        fill="#0FF"
      />
    </svg>
  );
}

export default PlusBorderIcon;
