/* eslint-disable import/no-useless-path-segments */
import iconNotify from '../imgs/icons/icon-notify.png';
import iconFacebook from '../imgs/icons/icon-facebook.png';
import iconDiscord from '../imgs/icons/icon-discord.png';
import iconTelegram from '../imgs/icons/icon-telegram.png';
import iconTwitter from '../imgs/icons/icon-twitter.png';
import iconGame from '../imgs/icons/icon-game.png';
import iconBack from '../imgs/icons/icon-back.png';
import iconUpload from '../imgs/icons/icon-upload.png';
import iconCalendar from '../imgs/icons/icon-calendar.png';
import iconDropdown from '../imgs/icons/icon-dropdown.png';
import iconTick from '../imgs/icons/icon-tick.png';
import iconChecked from '../imgs/icons/icon-checked.png';
import iconCopy from '../imgs/icons/icon-copy.png';
import iconUser from '../imgs/icons/icon-user.png';
import iconSuccess from '../imgs/icons/icon-success.png';
import iconClose from '../imgs/icons/icon-close.png';
import iconChart from '../imgs/icons/icon-chart.png';
import iconRental from '../imgs/icons/icon-rental.png';
import iconNftTest from '../imgs/icons/icon-nfts-test.png';
import iconFilter from '../imgs/icons/icon-filter.png';
import iconEdit from '../imgs/icons/icon-edit.png';
import iconDelete from '../imgs/icons/icon-delete.png';
import iconReject from '../imgs/icons/icon-reject.png';
import iconDoc from '../imgs/icons/icon-doc.png';
import iconDocActive from './icons/icon-doc-active.png';
import iconReturn from '../imgs/icons/icon-return.png';
import subIconGame from '../imgs/icons/sub-icon-game.png';
import subIconRental from '../imgs/icons/sub-icon-rental.png';
import iconProfile from '../imgs/icons/icon-profile.png';
import iconProfileSub from '../imgs/icons/icon-profile-sub.png';
import iconSetting from '../imgs/icons/icon-setting.png';
import iconProfileTournament from '../imgs/icons/icon-profile-tournament.png';
import iconUploadImgProfile from '../imgs/icons/icon-upload-img-profile.png';
import iconSearch from '../imgs/icons/icon-search.png';
import bgHome from './bg-home-page.png';
import dggNetwork from './dgg_network_text.svg';
import bannerScholarship from './banner-scholarship.png';
import logo from './dgg_network_big.svg';
import rentalVideo from './rental-video.png';
import dggFormSuccess from './dgg-form-success.png';
import noAvatar from './avatar.png';
import iconHide from './icons/icon-hide.png';
import iconShow from './icons/icon-show.png';
import iconProfileHeader from './icons/icon-profile-header.png';
import noImage from './no-image.png';
import infoSquare from './Info-square.png';
import avatarUser from './icons/icon-avatar-user.png';
import iconCloseTeam from './icons/icon-close-red.png';
import bgTeam from './bg-team-default.png';
import logoNoText from './logo-no-text.png';
import logoTeamDefault from './logo-team-default.png';
import iconMenu from './icons/icon-menu.png';
import logoSideBar from './logo-with-text.svg';
import logoSideBarMobile from './logo-without-text.svg';
import iconLeaderBoard from './icons/icon-leader-board.png';
import subIconLeaderBoard from './icons/sub-icon-leader-board.png';
import iconTournament from './icons/icon-tournament.png';
import subIconTournament from './icons/sub-icon-tournament.png';
import firstPrize from './firstPrize.png';
import secondPrize from './secondPrize.png';
import thirdPrize from './thirdPrize.png';
import filterExplore from './Filter.png';
import deleteExplore from './delete.png';
import pageNotFound from '../imgs/pageNotFound.png';
import appleStore from './applestore.svg';
import googlePlay from './googleplay.svg';
import ticket from './ticket.png';
import nft from './nft.png';
import ticketIcon from './ticket_icon.svg';
import referralIcon from './icon-referral.png';
import referralIconActive from './icon-referral-active.png';
import referralBg from '../imgs/referral-bg.png';
import cardCommission from '../imgs/card-commission.png';
import tournamentPrize from '../imgs/banner-prize.png';
import dggWallet from '../imgs/qrcode-logo/dgg-wallet.svg';
import walletConnect from '../imgs/qrcode-logo/wallet-connect.svg';
import walletConnectIcon from '../imgs/qrcode-logo/walletConnectIcon.svg';
import metamaskConnect from '../imgs/qrcode-logo/metamask.png';
import unauthorPage from '../imgs/unauthor.png';
import lightningIcon from './icons/icon-lightning.png';
import iconEditProfile from './icons/icon-edit-profile.png';
import instantBanner from '../imgs/instant-banner.jpg';
import bronzeType from '../imgs/ticket-type/bronze.png';
import goldType from '../imgs/ticket-type/gold.png';
import platinumType from '../imgs/ticket-type/platinum.png';
import silverType from '../imgs/ticket-type/silver.png';
import ticketToken from '../imgs/ticket-type/ticketToken.png';
import iconRedeem from '../imgs/icons/icon-redeem.png';
import iconRedeemSub from '../imgs/icons/icon-redeem-sub.png';
import iconFaq from '../imgs/icons/icon-faq.png';
import iconFaqSub from '../imgs/icons/icon-faq-sub.png';
import iconContact from '../imgs/icons/icon-contact.png';
import iconContactSub from '../imgs/icons/icon-contact-sub.png';
import noTicketGame from '../imgs/no-ticket-game.png';
import iConResubmit from './icons/re-submit-icon.png';
import plusIcon from './icons/icon-plus.png';
import prizePoolIcon from './icons/prize-pool-icon.svg';
import iconLogout from '../imgs/icons/icon-logout.png';
import gameIcon1 from '../imgs/game-icon/game-icon-1.png';
import gameIcon2 from '../imgs/game-icon/game-icon-2.png';
import gameIcon3 from '../imgs/game-icon/game-icon-3.png';
import sendUsIcon from '../imgs/game-icon/send-us-icon.png';
import bgContactUs from '../imgs/game-icon/bg-contact-us.png';
import contactUsSuccess from '../imgs/game-icon/contact-us-success.png';
import updateRequire from '../imgs/updateOsRequire.png';
import comingSoonImg from '../imgs/comingsoon.svg';
import badgetTop1 from '../imgs/statistics/top-1.png';
import badgetTop2 from '../imgs/statistics/top-2.png';
import badgetTop3 from '../imgs/statistics/top-3.png';
import serviceUnavailableImg from '../imgs/service-unavailable.svg';
import planet1 from '../imgs/planet1.svg';
import land from '../imgs/land.svg';
import robo from '../imgs/robo.svg';
import planet2 from '../imgs/planet2.svg';
import planet3 from '../imgs/planet3.svg';
import redeemSuccess from '../imgs/redeem-success.png';

const ICONS = {
  iconNotify,
  iconFacebook,
  iconDiscord,
  iconTelegram,
  iconTwitter,
  iconGame,
  iconBack,
  iconUpload,
  iconCalendar,
  iconDropdown,
  iconTick,
  iconChecked,
  iconCopy,
  iconUser,
  iconSuccess,
  iconClose,
  iconChart,
  iconRental,
  iconNftTest,
  iconFilter,
  iconEdit,
  iconDelete,
  iconReject,
  subIconGame,
  subIconRental,
  iconDoc,
  iconDocActive,
  iconReturn,
  iconProfile,
  iconProfileTournament,
  iconUploadImgProfile,
  iconProfileSub,
  iconSetting,
  iconHide,
  iconShow,
  iconProfileHeader,
  iconSearch,
  infoSquare,
  iconCloseTeam,
  iconMenu,
  iconLeaderBoard,
  subIconLeaderBoard,
  iconTournament,
  subIconTournament,
  filterExplore,
  deleteExplore,
  ticketIcon,
  referralIcon,
  referralIconActive,
  lightningIcon,
  iconEditProfile,
  iconRedeem,
  iconRedeemSub,
  iconFaq,
  iconFaqSub,
  iConResubmit,
  plusIcon,
  prizePoolIcon,
  iconLogout,
  iconContact,
  iconContactSub,
};
const HOME = {
  bgHome,
  dggNetwork,
  logo,
  logoNoText,
  logoSideBar,
  logoSideBarMobile,
  pageNotFound,
  appleStore,
  googlePlay,
  unauthorPage,
  updateRequire,
  comingSoonImg,
};
const SCHOLARSHIP = {
  bannerScholarship,
  dggFormSuccess,
};
const RENTAL = {
  rentalVideo,
};
const PROFILE = {
  noAvatar,
};
const TOURNAMENT = {
  noImage,
  instantBanner,
};
const TEAM = {
  avatarUser,
  bgTeam,
  logoTeamDefault,
};
const IMG_PRIZES = {
  firstPrize,
  secondPrize,
  thirdPrize,
};
const JOIN_TICKET = {
  ticket,
  nft,
};
const REFERRAL = {
  referralBg,
  cardCommission,
};
const TOURNAMENT_PRIZE = {
  tournamentPrize,
  noTicketGame,
};

const QR_CODE_IMG = {
  dggWallet,
  walletConnect,
  metamaskConnect,
  walletConnectIcon,
};

const TICKET_TYPE_IMG = {
  bronzeType,
  silverType,
  platinumType,
  goldType,
  ticketToken,
};

const CONTACT_US_IMG = {
  gameIcon1,
  gameIcon2,
  gameIcon3,
  sendUsIcon,
  bgContactUs,
  contactUsSuccess,
};

const TOURNAMENT_STATISTIC = {
  badgetTop1,
  badgetTop2,
  badgetTop3,
};

const SERVICE_UNAVAILABLE_IMG = {
  serviceUnavailableImg,
  planet1,
  land,
  robo,
  planet2,
  planet3,
};

const REDEEM_IMG = {
  redeemSuccess,
};

export {
  ICONS,
  HOME,
  SCHOLARSHIP,
  RENTAL,
  PROFILE,
  TOURNAMENT,
  TEAM,
  IMG_PRIZES,
  JOIN_TICKET,
  REFERRAL,
  TOURNAMENT_PRIZE,
  QR_CODE_IMG,
  TICKET_TYPE_IMG,
  CONTACT_US_IMG,
  TOURNAMENT_STATISTIC,
  SERVICE_UNAVAILABLE_IMG,
  REDEEM_IMG,
};
