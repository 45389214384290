import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../common/Input';
import TButton from '../../common/Button';

function ModalPayTokenJoinTournament(props) {
  const { t } = useTranslation();
  const { show, setShow, joinFee, symbolToken, sendDonate } = props;
  const loginRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (loginRef.current && !loginRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [loginRef]);
  return (
    <div
      className={show ? 'wrap-modal-custom join-tournament d-block' : 'wrap-modal-custom'}
      ref={loginRef}
    >
      <div className="d-flex align-items-center gap-3 wrap-otp mt-3 mb-3">
        <InputField
          className="input-amount"
          title={t('donate.payAmount')}
          type="number"
          disabled
          value={joinFee}
          maxLength={6}
        />
        <div className="coin-tournament" aria-hidden style={{ width: '100px' }}>
          {symbolToken}
        </div>
      </div>
      <div className="d-flex align-items-center gap-3">
        <TButton
          className="mb-2"
          title={t('donate.cancel')}
          type="secondary"
          width="full-width"
          onClick={() => setShow(false)}
        />
        <TButton
          className="mb-2"
          title={t('donate.pay')}
          width="full-width"
          onClick={() => sendDonate(String(joinFee))}
        />
      </div>
    </div>
  );
}

export default ModalPayTokenJoinTournament;
