import FormData from 'form-data';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import { ICONS } from '../../../../assets/imgs';
import {
  MIN_AGE,
  APPLY_APPROVING,
  ERR_MESSAGE,
  MAX_LENGTH_EMAIL,
  MAX_LENGTH_NAME,
  MAX_LENGTH_NAME_FB,
  MAX_LENGTH_NAME_NOTE,
  MAX_LENGTH_NAME_SOCIAL,
  MIN_LENGTH_PHONE,
} from '../../../constants';
import countryApi from '../../../services/country';
import scholarshipApi from '../../../services/scholarship';
import { useAppDispatch } from '../../../store';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import {
  validateEmail,
  validateEmpty,
  validateFileImage,
  validateMaxLength,
  validatePhone,
  validateText,
} from '../../../utils/validator';
import InputField from '../../common/Input';
import ModalSuccess from '../../common/Modal/ModalSuccess';
import SelectCustom from '../../common/SelectCustom';
import useMessage from '../../common/toast/UseMessage';
import './styles.scss';

function Form(props) {
  const { account, id, setStatusApply, mode } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [discord, setDiscord] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [country, setCountry] = useState(3);
  const [countries, setCountries] = useState([]);
  const [frontImg, setFrontImg] = useState(null);
  const [backImg, setBackImg] = useState(null);
  const [telegram, setTelegram] = useState('');
  const [phone, setPhone] = useState('');
  const [typeKYC, setType] = useState(1);
  const [twitter, setTwitter] = useState('');
  const [facebook, setFacebook] = useState('');
  const [note, setNote] = useState('');

  const [isPlayBefore, setPlay] = useState(true);

  const clearState = () => {
    setFirstName('');
    setLastName('');
    setDiscord('');
    setEmail('');
    setDate('');
    setCountry(3);
    setFrontImg(null);
    setBackImg(null);
    setTelegram('');
    setPhone('');
    setType(1);
    setTwitter('');
    setFacebook('');
    setPlay('');
    setNote('');
  };

  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const optionKYC = [
    { id: 1, name: t('scholarship.idCard') },
    { id: 2, name: t('scholarship.passport') },
  ];

  const convertTitleUpload = (type) => {
    let resultTitle = '';
    switch (Number(type)) {
      case optionKYC[0].id:
        resultTitle = t('scholarship.uploadIdCard');
        break;
      case optionKYC[1].id:
        resultTitle = t('scholarship.uploadPassport');
        break;
      default:
        break;
    }
    return resultTitle;
  };

  const handleChangeFrontImg = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFrontImg(e.target.files[0]);
    }
  };
  const handleChangeBackImg = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setBackImg(e.target.files[0]);
    }
  };

  const [validationMsg, setValidationMsg] = useState({});

  const validateInputs = () => {
    const error = {
      firstName: '',
      lastName: '',
      discord: '',
      email: '',
      date: '',
      country: '',
      phone: '',
      telegram: '',
      frontImg: '',
      backImg: '',
      twitter: '',
      facebook: '',
      note: '',
    };
    error.email = validateEmail(email);
    error.firstName = validateText('FirstName', firstName, MAX_LENGTH_NAME);
    error.lastName = validateText('LastName', lastName, MAX_LENGTH_NAME);
    error.discord = validateText('Discord', discord, MAX_LENGTH_NAME_SOCIAL);
    error.telegram = validateText('Telegram', telegram, MAX_LENGTH_NAME_SOCIAL);
    error.date = validateEmpty('Date', date);
    error.country = validateEmpty('Country', String(country));
    error.phone = validatePhone(phone);
    error.frontImg = validateFileImage(frontImg);
    error.backImg = validateFileImage(backImg);
    error.twitter = validateMaxLength('twitter', twitter, MAX_LENGTH_NAME_SOCIAL);
    error.facebook = validateMaxLength('facebook', facebook, MAX_LENGTH_NAME_FB);
    error.note = validateMaxLength('note', note, MAX_LENGTH_NAME_NOTE);
    setValidationMsg(error);
    for (let i = 0; i < Object.keys(error).length; i += 1) {
      if (error[Object.keys(error)[i]] !== '') {
        return false;
      }
    }
    return true;
  };

  const [isSubmit, setSubmit] = useState(false);
  const { openMessageError } = useMessage();

  const handleToast = () => {
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  const handleSubmit = async () => {
    const isValid = await validateInputs();
    if (!isValid) return;
    dispatch(showLoading());
    setSubmit(true);
    const dataForm = new FormData();
    dataForm.append('firstName', firstName);
    dataForm.append('lastName', lastName);
    dataForm.append('birthday', date);
    dataForm.append('telegram', telegram);
    dataForm.append('discord', discord);
    dataForm.append('country', String(country));
    dataForm.append('walletAddress', account);
    dataForm.append('isplayed', isPlayBefore ? 1 : 0);
    dataForm.append('typeKYC', Number(typeKYC));
    dataForm.append('phoneNumber', phone);
    dataForm.append('kycBefore', frontImg);
    dataForm.append('email', email);
    dataForm.append('kycAfter', backImg);
    dataForm.append('gameId', id);
    dataForm.append('twitter', twitter);
    dataForm.append('facebook', facebook);
    dataForm.append('guild', note);
    try {
      await scholarshipApi.post(dataForm);
      clearState();
      handleToast();
      setStatusApply(APPLY_APPROVING);
    } catch (error) {
      setSubmit(false);
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
      setSubmit(false);
    }
  };
  const getListCountry = async () => {
    dispatch(showLoading());
    try {
      const { data } = await countryApi.getListCountry();
      setCountries(data);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    getListCountry();
  }, []);
  useEffect(() => {
    if (
      firstName &&
      discord &&
      lastName &&
      phone &&
      telegram &&
      email &&
      date &&
      frontImg &&
      backImg
    ) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [firstName, discord, lastName, phone, telegram, email, date, frontImg, backImg]);
  return (
    <div className="form-scholarship">
      <Row className="mb-4">
        <Col md="6" className="first-field">
          <InputField
            title={t('scholarship.firstName')}
            type="text"
            isRequired
            placeholder={t('scholarship.firstName')}
            onChange={setFirstName}
            value={firstName}
            maxLength={MAX_LENGTH_NAME}
            error={!!validationMsg.firstName}
            message={validationMsg.firstName}
          />
        </Col>
        <Col md="6" className="show-discord-pc">
          <InputField
            title="Discord*"
            type="text"
            isRequired
            placeholder="Discord"
            onChange={setDiscord}
            value={discord}
            maxLength={MAX_LENGTH_NAME_SOCIAL}
            error={!!validationMsg.discord}
            message={validationMsg.discord}
          />
        </Col>
        <Col md="6" className="show-last-name-sp">
          <InputField
            title={t('scholarship.lastName')}
            type="text"
            isRequired
            placeholder={t('scholarship.lastName')}
            onChange={setLastName}
            value={lastName}
            maxLength={MAX_LENGTH_NAME}
            error={!!validationMsg.lastName}
            message={validationMsg.lastName}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md="6" className="show-discord-sp first-field">
          <InputField
            title="Discord*"
            type="text"
            isRequired
            placeholder="Discord"
            onChange={setDiscord}
            value={discord}
            maxLength={MAX_LENGTH_NAME_SOCIAL}
            error={!!validationMsg.discord}
            message={validationMsg.discord}
          />
        </Col>
        <Col md="6" className="first-field show-last-name-pc">
          <InputField
            title={t('scholarship.lastName')}
            type="text"
            isRequired
            placeholder={t('scholarship.lastName')}
            onChange={setLastName}
            value={lastName}
            maxLength={MAX_LENGTH_NAME}
            error={!!validationMsg.lastName}
            message={validationMsg.lastName}
          />
        </Col>
        <Col md="6">
          <InputField
            title="Telegram*"
            type="text"
            isRequired
            placeholder="Telegram"
            onChange={setTelegram}
            value={telegram}
            maxLength={MAX_LENGTH_NAME_SOCIAL}
            error={!!validationMsg.telegram}
            message={validationMsg.telegram}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md="6" className="first-field">
          <InputField
            title="Twitter"
            type="text"
            placeholder="Twitter"
            onChange={setTwitter}
            value={twitter}
            maxLength={MAX_LENGTH_NAME_SOCIAL}
            error={!!validationMsg.twitter}
            message={validationMsg.twitter}
          />
        </Col>
        <Col md="6">
          <InputField
            title="Facebook"
            type="text"
            placeholder="Facebook"
            onChange={setFacebook}
            value={facebook}
            maxLength={MAX_LENGTH_NAME_FB}
            error={!!validationMsg.facebook}
            message={validationMsg.facebook}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md="6" className="first-field">
          <InputField
            typeInput="Number"
            title={t('scholarship.phone')}
            type="text"
            isRequired
            placeholder={t('scholarship.phone')}
            onChange={setPhone}
            minLength={MIN_LENGTH_PHONE}
            value={phone}
            error={!!validationMsg.phone}
            message={validationMsg.phone}
          />
        </Col>
        <Col md="6" className="show-kyc-pc">
          <SelectCustom
            title="KYC*"
            items={optionKYC}
            selectedItem={typeKYC}
            setSelectedItem={setType}
          />
        </Col>
        <Col md="6" className="show-email-sp">
          <InputField
            title="Email*"
            type="email"
            isRequired
            placeholder="Email"
            onChange={setEmail}
            value={email}
            maxLength={MAX_LENGTH_EMAIL}
            error={!!validationMsg.email}
            message={validationMsg.email}
            createMode={mode}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md="6" className="first-field">
          <div className="show-email-pc">
            <InputField
              title="Email*"
              type="email"
              isRequired
              placeholder="Email"
              onChange={setEmail}
              className="mb-4"
              value={email}
              maxLength={MAX_LENGTH_EMAIL}
              error={!!validationMsg.email}
              message={validationMsg.email}
              createMode={mode}
            />
          </div>

          <InputField
            title={t('scholarship.dateOfBirth')}
            type="date"
            onChange={setDate}
            className="txtDate show-date-sp"
            value={date}
            typeInput="date"
            error={!!validationMsg.date}
            message={validationMsg.date}
            typeMaxDate
            ageLimit={MIN_AGE}
          />
          <div className="show-kyc-sp">
            <SelectCustom
              title="KYC*"
              items={optionKYC}
              selectedItem={typeKYC}
              setSelectedItem={setType}
            />
          </div>
        </Col>
        <Col md="6">
          <h6 className="mb-2 color-primary">{convertTitleUpload(typeKYC)}</h6>
          <Row>
            <Col className="p-relative">
              <label htmlFor="upload-front-kyc" className="w-100">
                <div className={frontImg ? 'wrap-upload wrap-upload-data' : 'wrap-upload'}>
                  {frontImg && (
                    <img
                      src={window.URL.createObjectURL(frontImg)}
                      alt="Thumb"
                      className="img-upload-kyc"
                    />
                  )}
                  <div className="group-upload">
                    <div className="title">{t('scholarship.uploadFrontPage')}</div>
                    <img src={ICONS.iconUpload} alt="icon" />
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleChangeFrontImg}
                    id="upload-front-kyc"
                  />
                </div>
              </label>
              {validationMsg.frontImg && (
                <div className="waring-upload">
                  <h6>{validationMsg.frontImg}</h6>
                </div>
              )}
            </Col>
            <Col className="p-relative">
              <label htmlFor="upload-back-kyc" className="w-100">
                <div className={backImg ? 'wrap-upload wrap-upload-data' : 'wrap-upload'}>
                  {backImg && (
                    <img
                      src={window.URL.createObjectURL(backImg)}
                      alt="Thumb"
                      className="img-upload-kyc"
                    />
                  )}
                  <div className="group-upload">
                    <div className="title">{t('scholarship.uploadBackPage')}</div>
                    <img src={ICONS.iconUpload} alt="icon" />
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleChangeBackImg}
                    id="upload-back-kyc"
                  />
                </div>
              </label>
              {validationMsg.backImg && (
                <div className="waring-upload">
                  <h6>{validationMsg.backImg}</h6>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md="6" className="first-field">
          <SelectCustom
            title={t('scholarship.country')}
            items={countries}
            selectedItem={country}
            setSelectedItem={setCountry}
          />
        </Col>
        <Col md="6">
          <h6 className="color-primary">{t('scholarship.question')}</h6>
          <Row className="wrap-tick-content">
            <Col>
              <div className="wrap-tick">
                <img
                  src={isPlayBefore ? ICONS.iconChecked : ICONS.iconTick}
                  alt="img"
                  onClick={() => setPlay(!isPlayBefore)}
                  aria-hidden
                />
                <span>{t('yes')}</span>
              </div>
            </Col>
            <Col>
              <div className="wrap-tick">
                <img
                  src={isPlayBefore ? ICONS.iconTick : ICONS.iconChecked}
                  alt="img"
                  onClick={() => setPlay(!isPlayBefore)}
                  aria-hidden
                />
                <span>{t('no')}</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="align-items-sm-end">
        <Col md="6" className="first-field">
          <InputField
            title={t('scholarship.note')}
            type="text"
            placeholder={t('scholarship.note')}
            onChange={setNote}
            value={note}
            maxLength={MAX_LENGTH_NAME_NOTE}
            error={!!validationMsg.note}
            message={validationMsg.note}
          />
        </Col>
      </Row>
      <Row className="btn-submit-application">
        <Col md="12">
          <div
            className={`btn-stroke-fill ${isChecked ? '' : 'btn-disabled btn-brown'}`}
            onClick={handleSubmit}
            aria-hidden
          >
            {isSubmit && (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            )}
            {t('scholarship.submitApplication')}
          </div>
        </Col>
      </Row>
      <ModalSuccess show={show} setShow={setShow} message={t('success.submit')} />
    </div>
  );
}

export default Form;
