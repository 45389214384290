import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Bracket, Seed } from 'react-brackets';
import TitleBracket from '../../common/TitleBracket';
import SeedBracket from '../../common/SeedBracket';
import ActionZoomBracket from '../../common/ActionZoomBracket';

const SingleElimination = ({ dataBracket }) => {
  const roundTitleComponent = (title) => <TitleBracket title={title} />;

  const CustomSeed = ({ seed }) => (
    <Seed>
      <SeedBracket seed={seed} />
    </Seed>
  );

  return (
    <TransformWrapper initialScale={1} minScale={0.8}>
      {({ zoomIn, zoomOut, resetTransform }) => (
        <>
          <ActionZoomBracket zoomIn={zoomIn} zoomOut={zoomOut} resetTransform={resetTransform} />
          <TransformComponent>
            <Bracket
              mobileBreakpoint={0}
              rounds={dataBracket ?? []}
              roundTitleComponent={(title) => roundTitleComponent(title)}
              renderSeedComponent={CustomSeed}
            />
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
};

export default SingleElimination;
