import FormData from 'form-data';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import JSCookie from 'js-cookie';
import { toast } from 'react-toastify';
import { useWeb3React } from '@web3-react/core';
import { format, parseISO, subYears } from 'date-fns';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Tooltip } from 'react-tooltip';
import { ICONS, PROFILE } from '../../../assets/imgs';
import TButton from '../../components/common/Button';
import InputField from '../../components/common/Input';
import Layout from '../../components/common/Layout';
import ModalLinkEmail from '../../components/common/Modal/ModalLinkEmail';
import SelectCustom from '../../components/common/SelectCustom/index';
import useMessage from '../../components/common/toast/UseMessage';
import {
  ERR_MESSAGE,
  MAX_LENGTH_EMAIL,
  MAX_LENGTH_NAME,
  MAX_LENGTH_NAME_SOCIAL,
  COPY_TIME_INTERVAL,
  MEDIUM_SCREEN_WIDTH,
} from '../../constants';
import countryApi from '../../services/country';
import scholarshipApi from '../../services/scholarship';
import { useAppDispatch, useAppSelector } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { getProfile } from '../../store/features/login';
import {
  validateEmail,
  validateFileImage,
  validateInvitedCode,
  validateText,
} from '../../utils/validator';
import './styles.scss';
import CopyableComponent from '../../components/common/CopyableComponent/CopyableComponent';
import TelegramIcon from '../../components/icons/TelegramIcon';
import DiscordIcon from '../../components/icons/DiscordIcon';
import FBIcon from '../../components/icons/FBIcon';
import TwitterIcon from '../../components/icons/TwiterIcon';
import WebsiteIcon from '../../components/icons/WebsiteIcon';
import UnauthorizePage from '../UnauthorizePage/UnauthorizePage';
import { useAuthContext } from '../../context/AuthContext';
import ModalConnectWallet from '../../components/common/ModalConnectWallet/ModalConnectWallet';
import ShieldIcon from '../../components/icons/ShieldIcon';
import QuestionIcon from '../../components/icons/QuestionIcon';
import shortenAddress from '../../utils/shortenAddress';
import CopyIcon from '../../components/icons/CopyIcon';
import VIcon from '../../components/icons/VIcon';
import useScreenWidth from '../../hooks/useScreenWidth';

function Profile(props) {
  const { dataConnect } = props;
  const { openMessageError, openMessage } = useMessage();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [countries, setCountries] = useState([]);
  const { state } = history.location;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [country, setCountry] = useState(3);
  const [website, setWebsite] = useState('');
  const [telegram, setTelegram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [twitter, setTwitter] = useState('');
  const [discord, setDiscord] = useState('');
  const [image, setImage] = useState(PROFILE.noAvatar);
  const [validationMsg, setValidationMsg] = useState({});
  const [showLinkEmail, setShowLinkEmail] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [newInviteCode, setNewInviteCode] = useState('');
  const { isLogin, isCreatedProfile, isLinkedEmaiAddress, checkCreatedProfile } = useAuthContext();
  const { account: activeAccount, provider } = useWeb3React();
  const [showModalConnectWallet, setShowModalConnectWallet] = useState(false);
  const walletAccount = JSCookie.get('walletAccount');
  const linkEmail = JSCookie.get('email');
  const [isEditableProfile, setIsEditableProfile] = useState(false);
  const { profile } = useAppSelector((state) => state.login);
  const refreshExpireIn = JSCookie.get('expiresIn');
  // people over 13 years-old can use app
  const maxDobSelected = format(subYears(new Date(), 13), 'yyyy-MM-dd');
  const { t } = useTranslation();

  const validateInputs = () => {
    const error = {
      firstName: '',
      lastName: '',
      email: '',
      image: '',
      invitedCode: '',
    };
    error.email = validateEmail(email);
    error.firstName = validateText('FirstName', firstName, MAX_LENGTH_NAME);
    error.lastName = validateText('LastName', lastName, MAX_LENGTH_NAME);
    error.website = validateText('Website', website, MAX_LENGTH_NAME_SOCIAL, false);
    error.telegram = validateText('Telegram', telegram, MAX_LENGTH_NAME_SOCIAL, false);
    error.facebook = validateText('Facebook', facebook, MAX_LENGTH_NAME_SOCIAL, false);
    error.twitter = validateText('Twitter', twitter, MAX_LENGTH_NAME_SOCIAL, false);
    error.discord = validateText('Discord', discord, MAX_LENGTH_NAME_SOCIAL, false);
    error.invitedCode = validateInvitedCode(profile?.referralCode, newInviteCode);
    if (typeof image === 'object') {
      error.image = validateFileImage(image, false);
    }

    setValidationMsg(error);
    for (let i = 0; i < Object.keys(error).length; i += 1) {
      if (error[Object.keys(error)[i]] !== '') {
        return false;
      }
    }
    return true;
  };

  const handleChangeProfileImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };
  const getCountries = async () => {
    dispatch(showLoading());
    try {
      const { data } = await countryApi.getListCountry();
      setCountries(data);
    } catch (error) {
      openMessageError(error?.response?.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (isLogin) {
      dispatch(getProfile());
      getCountries().catch((error) => {
        openMessageError(t('error.error'), error);
      });
    }
  }, [isLogin]);
  useEffect(() => {
    if (firstName && lastName && (email || linkEmail)) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [firstName, lastName, email, linkEmail]);

  useEffect(() => {
    if (profile) {
      setFirstName(profile?.firstName || '');
      setLastName(profile?.lastName || '');
      setEmail(profile?.contactEmail || '');
      setDate(profile?.birthday || '');
      setCountry(profile?.country || 3);
      setTelegram(profile?.website || '');
      setTelegram(profile?.telegram || '');
      setFacebook(profile?.facebook || '');
      setTwitter(profile?.twitter || '');
      setDiscord(profile?.discord || '');
      setImage(profile?.avatar || PROFILE.noAvatar);
    }
  }, [profile]);
  const handleSubmit = async () => {
    const isValid = await validateInputs();
    if (!isValid) return;
    if (date && new Date(date) > new Date(maxDobSelected)) {
      toast.error(t('profile.noti'));
      return;
    }
    dispatch(showLoading());
    const dataForm = new FormData();
    dataForm.append('firstName', firstName);
    dataForm.append('lastName', lastName);
    if (date) {
      dataForm.append('birthday', date);
    }
    dataForm.append('website', website);
    dataForm.append('telegram', telegram);
    dataForm.append('facebook', facebook);
    dataForm.append('twitter', twitter);
    dataForm.append('discord', discord);
    dataForm.append('country', country);
    if (email) {
      dataForm.append('email', email);
    }
    if (!email && linkEmail) {
      dataForm.append('email', linkEmail);
    }
    if (typeof image === 'object') {
      dataForm.append('image', image);
    }
    if (newInviteCode && !profile?.referrerCode) {
      dataForm.append('referrerCode', newInviteCode);
    }
    try {
      await scholarshipApi.postProfile(dataForm);
      const expiresInMilliseconds = parseInt(refreshExpireIn, 10);
      const dateExpire = new Date(expiresInMilliseconds);

      JSCookie.set('isCreateProfile', true, { expires: dateExpire });
      openMessage({ message: t('success.updateProfile') });

      checkCreatedProfile();
      dispatch(getProfile());
    } catch (error) {
      if (typeof error?.response?.data?.errors !== 'string') {
        toast.error(error?.response?.data?.errors[0]?.msg || ERR_MESSAGE);
      } else {
        openMessageError(error?.response?.data?.message || error?.response?.data || ERR_MESSAGE);
      }
    } finally {
      dispatch(hideLoading());
      setIsEditableProfile(false);
    }
  };

  const isCheckImg = typeof image === 'string';
  useEffect(() => {
    if (isLogin && !isCreatedProfile) {
      toast.warn(t('profile.upDate'));
    }
  }, []);

  const [walletCopiedText, setWalletCopiedText] = useState(false);

  useEffect(() => {
    const resetState = setInterval(() => {
      setWalletCopiedText(false);
    }, COPY_TIME_INTERVAL);
    return () => clearInterval(resetState);
  }, []);

  useEffect(() => {
    if (activeAccount && provider && !Number(walletAccount) && isLogin) {
      dataConnect.linkWallet().catch((error) => {
        openMessageError(t('error.error'), error);
        // eslint-disable-next-line
        console.log('handleLinkWallet function error: ', error);
      });
    }
  }, [activeAccount, provider]);

  const renderBtnAndTextConnectWallet = () => {
    if (!Number(walletAccount)) {
      return (
        <>
          <TButton
            title={t('require.link')}
            className="btn-link-wallet mb-3"
            onClick={() => setShowModalConnectWallet(true)}
          />
          <ModalConnectWallet
            showDialog={showModalConnectWallet}
            setShowDialog={setShowModalConnectWallet}
          />
        </>
      );
    }
    return null;
  };

  const selectedCountry = countries?.find((item) => item.id === profile?.country);
  const screenWidth = useScreenWidth();

  return (
    <Layout
      title={t('profile.title')}
      dataConnect={dataConnect}
      link="/assets"
      value={state?.data}
      query={state?.query}
      type={state?.data ? 1 : 0}
    >
      <ModalLinkEmail
        title={t('require.linkEmail')}
        show={showLinkEmail}
        setShow={setShowLinkEmail}
      />
      {isLogin ? (
        <div>
          {!isLinkedEmaiAddress && (!linkEmail || linkEmail === 'null') && (
            <div className="warning-profile-wrapper">
              <ShieldIcon />
              <p className="warning-title">{t('warning.updateProfile')}</p>
            </div>
          )}
          {!isLinkedEmaiAddress && linkEmail && linkEmail !== 'null' && (
            <div className="warning-profile-wrapper">
              <ShieldIcon />
              <p className="warning-title">{t('warning.updateProfile1')}</p>
            </div>
          )}
          <div className="body-profile-wrapper">
            <div className="left-body-profile">
              <div className="image-name-infor">
                <div className="wrap-image-profile">
                  <div className="image-profile mb-2">
                    {(isEditableProfile || screenWidth <= MEDIUM_SCREEN_WIDTH) && (
                      <label htmlFor="ip-upload-image-profile">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleChangeProfileImage}
                          id="ip-upload-image-profile"
                        />
                        <img
                          src={ICONS.iconUploadImgProfile}
                          alt="dgg-network"
                          className="icon-image-upload-profile"
                        />
                      </label>
                    )}

                    {image && (
                      <img
                        src={isCheckImg ? image : window.URL.createObjectURL(image)}
                        alt="dgg-network"
                        className="img-image-profile"
                      />
                    )}
                  </div>
                  {validationMsg.image && (
                    <div style={{ textAlign: 'center' }}>
                      <h6 style={{ color: 'red', fontSize: '12px' }}>{validationMsg.image}</h6>
                    </div>
                  )}
                </div>

                <div className="name-country-infor">
                  <div className="name">
                    {profile?.firstName && profile?.lastName
                      ? `${profile.firstName} ${profile.lastName}`
                      : t('dggMember')}
                  </div>
                  <div className="dob-country">
                    {profile?.birthday && (
                      <p>
                        {profile?.birthday
                          ? format(parseISO(profile?.birthday), 'dd/MM/yyyy')
                          : 'xx/xx/xxxx'}
                      </p>
                    )}
                    {selectedCountry && <p>{selectedCountry?.name}</p>}
                  </div>
                </div>
              </div>
              <div className="line-section" />
              <div className="referral-code-wrapper">
                <div>
                  <p className="mb-2">{t('referral.referralCode')}</p>
                  <CopyableComponent className="referral-copyable" text={profile?.referralCode} />
                </div>
                <div>
                  <p className="mb-2">{t('referral.invitedCode')}</p>
                  <CopyableComponent
                    className="referral-copyable"
                    text={profile?.referrerCode || ''}
                    defaultText={t('referral.noInvitedCode')}
                  />
                </div>
              </div>
              <div className="line-section" />
              <div className="link-email-wrapper">
                <div className="email-title-wrapper" id="tooltip-link-email">
                  <p>
                    Email<span className="text-dangerous">*</span>
                  </p>
                  <QuestionIcon />
                </div>
                <Tooltip
                  anchorSelect="#tooltip-link-email"
                  content={t('toolTip.inProfile')}
                  place="top-start"
                  className="tooltip-maxwidth"
                />
                {profile?.email ? (
                  <InputField
                    className="input-email-otp"
                    placeholder="youremail@gmail.com"
                    value={profile?.email || ''}
                    readonly
                  />
                ) : (
                  <TButton
                    title={t('require.linkEmail')}
                    className="btn-link-wallet"
                    onClick={() => setShowLinkEmail(true)}
                  />
                )}
              </div>
              <div className="link-email-wrapper mt-4">
                <div className="email-title-wrapper" id="tooltip-link-wallet">
                  <p>
                    {t('header.wallet')}
                    <span className="text-dangerous">*</span>
                  </p>
                  <QuestionIcon />
                </div>
                <Tooltip
                  anchorSelect="#tooltip-link-wallet"
                  content={t('toolTip.inProfile')}
                  place="top-start"
                  className="tooltip-maxwidth"
                />
                {profile?.walletAddress ? (
                  <InputField
                    className="input-email-otp"
                    value={profile?.walletAddress ? shortenAddress(profile?.walletAddress, 12) : ''}
                    readonly
                    accessoriesRight={
                      <CopyToClipboard
                        onCopy={() => setWalletCopiedText(true)}
                        text={profile?.walletAddress}
                      >
                        <div className="copy-icon-wrapper">
                          {walletCopiedText ? (
                            <VIcon color="#00FFFF" />
                          ) : (
                            <CopyIcon color="#00FFFF" />
                          )}
                        </div>
                      </CopyToClipboard>
                    }
                  />
                ) : (
                  renderBtnAndTextConnectWallet()
                )}
              </div>
              {isEditableProfile && screenWidth > MEDIUM_SCREEN_WIDTH && (
                <div className="left-gray-wrapper" />
              )}
            </div>
            <div className="right-body-profile">
              <div className="edit-profile-wrapper">
                <InputField
                  title={
                    <div>
                      {t('scholarship.firstName')}
                      <span className="text-dangerous">*</span>
                    </div>
                  }
                  type="text"
                  isRequired
                  placeholder={t('scholarship.firstName')}
                  onChange={setFirstName}
                  value={firstName}
                  maxLength={MAX_LENGTH_NAME}
                  error={validationMsg.firstName}
                  message={validationMsg.firstName}
                />
                <InputField
                  title={
                    <div>
                      {t('scholarship.lastName')}
                      <span className="text-dangerous">*</span>
                    </div>
                  }
                  type="text"
                  isRequired
                  placeholder={t('scholarship.lastName')}
                  onChange={setLastName}
                  value={lastName}
                  maxLength={MAX_LENGTH_NAME}
                  error={validationMsg.lastName}
                  message={validationMsg.lastName}
                />
                <div>
                  <InputField
                    title={
                      <div id="tooltip-contact-email" className="contact-email-wrapper">
                        {t('scholarship.contactEmail')}
                        <span className="text-dangerous">*</span>
                      </div>
                    }
                    type="email"
                    placeholder="youremail@gmail.com"
                    onChange={setEmail}
                    value={email || linkEmail}
                    maxLength={MAX_LENGTH_EMAIL}
                    error={validationMsg.email}
                    message={validationMsg.email}
                    isRequired
                  />
                  <Tooltip
                    anchorSelect="#tooltip-contact-email"
                    content={t('contactUs.contactEmail')}
                    place="top"
                    className="tooltip-maxwidth"
                  />
                </div>
                <InputField
                  title={
                    <>
                      <Tooltip
                        anchorSelect="#tooltip-dob"
                        content={t('profile.noti')}
                        place="top"
                        className="tooltip-maxwidth"
                      />
                      <div className="dob-tooltip-wrapper" id="tooltip-dob">
                        {t('scholarship.dateOfBirth')} <QuestionIcon />
                      </div>
                    </>
                  }
                  type="date"
                  onChange={setDate}
                  className="txtDate show-date-sp"
                  value={date}
                  typeInput="date"
                  typeMaxDate
                  ageLimit={maxDobSelected}
                />
                {countries.length && (
                  <SelectCustom
                    title={t('scholarship.country')}
                    selectedItem={country}
                    items={countries}
                    setSelectedItem={setCountry}
                    type="search"
                    classNameTitle="country-title"
                    classNameDropdown="country-padding"
                  />
                )}
                <InputField
                  title={t('referral.invitedCode')}
                  type="text"
                  placeholder={t('referral.enterInvitedCode')}
                  onChange={setNewInviteCode}
                  value={profile?.referrerCode ? profile?.referrerCode : newInviteCode || ''}
                  maxLength={MAX_LENGTH_NAME}
                  disabled={profile?.referrerCode}
                  error={validationMsg.invitedCode}
                  message={validationMsg.invitedCode}
                />
                <div className="website-wrapper">
                  <InputField
                    title="Website"
                    type="text"
                    placeholder="http://yoursite.com"
                    onChange={setWebsite}
                    value={website}
                    maxLength={MAX_LENGTH_NAME}
                    accessoriesLeft={<WebsiteIcon />}
                  />
                </div>

                <InputField
                  title="Facebook"
                  type="text"
                  isRequired
                  placeholder={t('profile.facebookUrl')}
                  onChange={setFacebook}
                  value={facebook}
                  maxLength={MAX_LENGTH_NAME_SOCIAL}
                  message={validationMsg.facebook}
                  accessoriesLeft={<FBIcon />}
                />
                <InputField
                  title="X"
                  type="text"
                  isRequired
                  placeholder={t('profile.twitterUrl')}
                  onChange={setTwitter}
                  value={twitter}
                  maxLength={MAX_LENGTH_NAME_SOCIAL}
                  message={validationMsg.twitter}
                  accessoriesLeft={<TwitterIcon />}
                />
                <InputField
                  title="Telegram"
                  type="text"
                  isRequired
                  placeholder={t('profile.telegramUrl')}
                  onChange={setTelegram}
                  value={telegram}
                  maxLength={MAX_LENGTH_NAME_SOCIAL}
                  message={validationMsg.telegram}
                  accessoriesLeft={<TelegramIcon />}
                />
                <InputField
                  title="Discord"
                  type="text"
                  isRequired
                  placeholder={t('profile.discordUrl')}
                  onChange={setDiscord}
                  value={discord}
                  maxLength={MAX_LENGTH_NAME_SOCIAL}
                  message={validationMsg.discord}
                  accessoriesLeft={<DiscordIcon />}
                />
                {(isEditableProfile || screenWidth <= MEDIUM_SCREEN_WIDTH) && (
                  <TButton
                    title={t('save')}
                    className={`mt-3 btn-save ${isChecked ? '' : 'btn-brown btn-disabled'}`}
                    onClick={handleSubmit}
                  />
                )}

                {!isEditableProfile && screenWidth > MEDIUM_SCREEN_WIDTH && (
                  <TButton
                    title={t('edit')}
                    className="mt-3 btn-edit"
                    onClick={() => {
                      setIsEditableProfile(!isEditableProfile);
                    }}
                  />
                )}
              </div>
              {!isEditableProfile && screenWidth > MEDIUM_SCREEN_WIDTH && (
                <div className="right-gray-wrapper" />
              )}
            </div>
          </div>
        </div>
      ) : (
        <UnauthorizePage />
      )}
    </Layout>
  );
}

export default Profile;
