import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/imgs';
import { ERR_MESSAGE, SELECT_BRACKET, STATUS_TOURNAMENT } from '../../../constants';
import scholarshipApi from '../../../services/scholarship';
import { useAppDispatch } from '../../../store';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import ModalDateRange from '../../common/Modal/ModalDateRange';
import SelectCustom from '../../common/SelectCustom';
import useMessage from '../../common/toast/UseMessage';
import TButton from '../../common/Button/index';

function ModalFilterExplore(props) {
  const { applyFilter, show, setShow, params, setParams, setGenre, setCurrentPage } = props;
  const [typeGame, setTypeGame] = useState('');
  const [typeRegion, setTypeRegion] = useState('');
  const [typeBracket, setTypeBracket] = useState('');
  const [typeStatus, setTypeStatus] = useState('');
  const [listGame, setListGame] = useState([]);
  const [listRegion, setListRegion] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isCalendar, setIsCalendar] = useState(false);
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState(t('filter.dateRange'));
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const { openMessageError } = useMessage();
  const dispatch = useAppDispatch();
  const SELECT_FILTERS = [
    {
      name: t('explore.game'),
      options: listGame,
      selected: typeGame,
    },
    {
      name: t('explore.region'),
      options: listRegion,
      selected: typeRegion,
    },
    {
      name: t('explore.bracket'),
      options: SELECT_BRACKET,
      selected: typeBracket,
    },
    {
      name: t('explore.status'),
      options: STATUS_TOURNAMENT,
      selected: typeStatus,
    },
  ];
  const getListGame = async () => {
    const res = await scholarshipApi.getListGame();
    setListGame(res.data);
  };

  const getListRegion = async () => {
    const res = await scholarshipApi.getListRegion();
    setListRegion(res.data);
  };
  const getData = async () => {
    dispatch(showLoading());
    try {
      await Promise.all([getListGame(), getListRegion()]);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handleClearFilter = () => {
    setGenre([]);
    setIsCalendar(false);
    setTypeBracket('');
    setTypeGame('');
    setTypeRegion('');
    setTypeStatus('');
    setDateRange(t('filter.dateRange'));
    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    });
    setParams({});
    setCurrentPage(1);
  };
  const onChange = (type, value) => {
    const parameter = { ...params };
    switch (type) {
      case t('explore.game'): {
        setTypeGame(value);
        parameter.game = value;
        break;
      }
      case t('explore.region'): {
        setTypeRegion(value);
        parameter.region = value;
        break;
      }
      case t('explore.bracket'): {
        setTypeBracket(value);
        parameter.bracket = value;
        break;
      }
      case t('explore.status'): {
        setTypeStatus(value);
        parameter.status = value;
        break;
      }
      default: {
        break;
      }
    }
    setParams(parameter);
  };
  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };
  const selectDateRange = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const saveDateRange = () => {
    const parameter = { ...params };
    parameter.start_time = moment(selectionRange.startDate).format('X') * 1000;
    parameter.end_time = moment(selectionRange.endDate).format('X') * 1000 + 24 * 60 * 60 * 1000;
    setDateRange(
      `${moment(selectionRange.startDate).format('DD/MM/YYYY')}-${moment(
        selectionRange.endDate,
      ).format('DD/MM/YYYY')}`,
    );
    setParams(parameter);
    setModalIsOpen(false);
    setIsCalendar(true);
  };

  return (
    <div className={show ? 'wrap-modal-custom explore-filter d-block' : 'wrap-modal-custom'}>
      <div className="list-select">
        <div className="header-modal">
          <h6 className="color-primary" style={{ fontSize: '24px' }}>
            {t('filter.buttonFilter')}
          </h6>
          <img
            onClick={() => setShow(false)}
            aria-hidden
            className="deleteModal btn"
            src={ICONS.deleteExplore}
            alt="dgg-network"
          />
        </div>
        <div className="list-select-filter">
          {SELECT_FILTERS.map((item) => (
            <SelectCustom
              key={item.name}
              title={item.name}
              items={item.options}
              selectedItem={item.selected}
              setSelectedItem={(value) => onChange(item.name, value)}
              onChange={(value) => onChange(item.name, value)}
            />
          ))}
          <div className="my-input-group my-select-group">
            <h6 className="mt-2">{t('filter.dateRange')}</h6>
            <div
              className={`btn-select-date-range ${isCalendar ? 'text-center' : ''}`}
              onClick={selectDateRange}
              aria-hidden
            >
              {dateRange}
              {!isCalendar && (
                <img src={ICONS.iconCalendar} alt="dgg-network" className="icon-calendar" />
              )}
            </div>
          </div>
        </div>
        <TButton
          className="mb-3"
          title={t('filter.applyFilter')}
          width="full-width"
          onClick={applyFilter}
        />
        <TButton
          className="mb-2"
          type="reverse"
          title={t('filter.reset')}
          width="full-width"
          onClick={handleClearFilter}
        />
      </div>
      <ModalDateRange
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        selectionRange={selectionRange}
        handleSelect={handleSelect}
        saveDateRange={saveDateRange}
      />
    </div>
  );
}

export default ModalFilterExplore;
