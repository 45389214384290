import React from 'react';
import ReactPaginate from 'react-paginate';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

import './styles.scss';

function Pagination(props) {
  const { totalPage, onChange, forcePage = 1 } = props;

  const handleChangePage = (page) => {
    onChange(page.selected + 1);
  };

  return (
    totalPage > 1 && (
      <div>
        <ReactPaginate
          nextLabel={<AiOutlineRight />}
          previousLabel={<AiOutlineLeft />}
          breakLabel="..."
          forcePage={forcePage - 1}
          pageCount={totalPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handleChangePage}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          activeClassName="active"
        />
      </div>
    )
  );
}

export default Pagination;
