import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { HOME } from '../../../assets/imgs';

function ComingSoonPage() {
  const { t } = useTranslation();
  return (
    <div className="coming-soon-container">
      <div className="ring-container">
        <img className="lds-dual-ring" src={HOME.comingSoonImg} alt="coming-soon" />
        <div className="left-container-ring">
          <img className="left-ring" src={HOME.comingSoonImg} alt="coming-soon" />
        </div>
      </div>
      <div className="coming-soon-title">{t('comingSoon')}</div>
      <p className="coming-soon-description">{t('notification.noti3')}</p>
    </div>
  );
}

export default ComingSoonPage;
