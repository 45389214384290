import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { useAuthContext } from '../../../context/AuthContext';
import useDevice from '../../../hooks/useDevice';
import { ConnectorNames, connectorsByName, getWalletServiceIcon } from '../../../lib/connectors';
import {
  DGG_REDIRECT_TO_APP_LINK,
  METAMASK_REDIRECT_TO_APP_LINK,
  PANCAKE_SWAP_GUIDE,
  PRODUCT_ENV,
} from '../../../constants';
import QuestionMarkIcon from '../../icons/QuestionMarkIcon';
import RightArrowConnectIcon from '../../icons/RightArrowConnectIcon';
import CloseDialogIcon from '../../icons/CloseDialogIcon';
import ModalScanQrDggWallet from './ModalScanQrDggWallet';

function ModalConnectWallet(props) {
  const { showDialog, setShowDialog } = props;

  const context = useWeb3React();
  const { connector, isActive } = context;
  const isMobile = useDevice();
  const { isLogin } = useAuthContext();
  const loginRef = useRef(null);
  const { t } = useTranslation();
  const [showModalScanQr, setShowModalScanQr] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (!loginRef.current.contains(event.target)) {
        setShowDialog(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [loginRef]);

  useEffect(() => {
    if (isLogin) {
      const connectorSelected = localStorage.getItem('connector');
      if (connectorSelected === ConnectorNames.Email) return;
      if (connectorSelected) {
        connectorsByName[connectorSelected].connectEagerly().catch(() => {
          console.debug(`Failed to connect eagerly to ${connectorSelected}`);
        });
      }
    }
  }, [isLogin]);

  const handleConnectDggWallet = () => {
    const { ethereum } = window;
    const hostName = window.location.hostname;
    if (isMobile && ethereum) {
      connectorsByName[ConnectorNames.DggWalletMobile].activate();
      localStorage.setItem('connector', ConnectorNames.DggWalletMobile);
    } else {
      window.open(`${DGG_REDIRECT_TO_APP_LINK}${hostName}`, '_blank', 'noopener,noreferrer');
    }
  };

  const handleConnectMetaMask = () => {
    const { ethereum } = window;
    const hostName = window.location.hostname;
    if (ethereum) {
      connectorsByName[ConnectorNames.Metamask].activate();
      localStorage.setItem('connector', ConnectorNames.Metamask);
    }
    if (!ethereum) {
      window.open(`${METAMASK_REDIRECT_TO_APP_LINK}${hostName}`, '_blank', 'noopener,noreferrer');
    }
  };

  const handleConnectWalletConnect = () => {
    connectorsByName[ConnectorNames.WalletConnect].activate();
  };

  return (
    <div
      style={{ zIndex: '1000' }}
      ref={loginRef}
      className={
        showDialog ? 'wrap-modal-custom model-connect-wallet d-block' : 'wrap-modal-custom'
      }
    >
      <div className="motion-dialog">
        <div className="connect-wallet-wrapper">
          <div>
            <div className="header-connect-wrapper">
              <p className="header-title">{t('connectYourWallet')}</p>
              <CloseDialogIcon onClick={() => setShowDialog(false)} className="cursor-pointer" />
            </div>

            <div className="list-card-wrapper">
              <ConnectWalletBtn
                className="show-in-desktop"
                name={ConnectorNames.DggWalletDesktop}
                buttonName={t('wallet.defaut')}
                onClick={() => setShowModalScanQr(true)}
              />
              {showModalScanQr && (
                <ModalScanQrDggWallet
                  showDialog={showModalScanQr}
                  setShowDialog={setShowModalScanQr}
                />
              )}

              <ConnectWalletBtn
                className="show-in-mobile"
                onClick={handleConnectDggWallet}
                name={ConnectorNames.DggWalletMobile}
                buttonName={t('wallet.defaut')}
              />
              {PRODUCT_ENV === 'dev' && (
                <>
                  <ConnectWalletBtn
                    onClick={handleConnectMetaMask}
                    name={ConnectorNames.Metamask}
                  />
                  <ConnectWalletBtn
                    onClick={handleConnectWalletConnect}
                    name={ConnectorNames.WalletConnect}
                    className="hidden"
                  />
                </>
              )}
              <a
                href={PANCAKE_SWAP_GUIDE}
                target="_blank"
                className="connect-guide"
                rel="noreferrer"
              >
                <QuestionMarkIcon />
                <p className="how-to-connect">{t('learnHowToConnect')}</p>
              </a>
            </div>

            <div className="deactive-wrapper">
              {isActive && (
                <button
                  type="button"
                  className="btn-deactive"
                  style={{
                    backgroundColor: '#b81412',
                  }}
                  onClick={() => {
                    if (connector?.deactivate) {
                      connector.deactivate();
                    } else {
                      connector.resetState();
                    }
                  }}
                >
                  {t('deactivate')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalConnectWallet;

function ConnectWalletBtn({ onClick = null, name, buttonName = '', className = '' }) {
  return (
    <button type="button" className={`btn-connect-wallet-wrapper ${className}`} onClick={onClick}>
      <div className="btn-display">
        <div className="btn-name-wrapper">
          <div className="btn-icon">
            <img className="btn-icon" src={getWalletServiceIcon(name)} alt={name} />
          </div>
          <div className="btn-name">{buttonName || name}</div>
        </div>
        <RightArrowConnectIcon />
      </div>
    </button>
  );
}
