import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';

function NoTournament() {
  const { t } = useTranslation();
  return (
    <div className="no-tournament-wrapper">
      <Col className="no-tournament-content">
        <h1 className="no-tournament-title">{t('tournament.noTournament')}</h1>
      </Col>
    </div>
  );
}

export default NoTournament;
