import React from 'react';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import ExploreItem from './ExploreItem';

function ListExplore(props) {
  const { tournaments } = props;
  const { t } = useTranslation();
  return (
    <div className="tournament-explore-table-wrapper">
      <Table className="tournament-table">
        <thead className="tournament-header-wrapper">
          <tr>
            <th className="title-wrapper">
              <h6>{t('header.tournaments')}</h6>
            </th>
            <th className="title-wrapper text-center">
              <h6>{t('explore.game')}</h6>
            </th>
            <th className="title-wrapper text-center">
              <h6>{t('explore.bracketType')}</h6>
            </th>
            <th className="title-wrapper text-center">
              <h6>{t('filter.start')}</h6>
            </th>
            <th className="title-wrapper text-center">
              <h6>{t('Network')}</h6>
            </th>
            <th className="title-wrapper text-center">
              <h6>{t('prize')}</h6>
            </th>
          </tr>
        </thead>
        {tournaments.map((tournament) => (
          <ExploreItem tournament={tournament} key={tournament.id} />
        ))}
      </Table>
    </div>
  );
}

export default ListExplore;
