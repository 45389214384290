import { initializeConnector } from '@web3-react/core';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { CHAINS } from './chains';

const [mainnet, ...optionalChains] = Object.keys(CHAINS).map(Number);

const DGG_PREFIX = 'dgg://';

export const [walletConnectV2, hooks] = initializeConnector(
  (actions) =>
    new WalletConnect({
      actions,
      options: {
        projectId: process.env.WALLET_CONNECT_PROJECT_ID,
        chains: [mainnet],
        optionalChains: [mainnet, ...optionalChains],
        showQrModal: false,
        metadata: {
          name: 'DGG Network - Next Generation of Gaming',
          description: `Discover incredible metaverse games, apply scholarships, join tournaments to earn crypto and NFT prizes all in one place. #${DGG_PREFIX}`,
          url: `https://dgg.network/#${DGG_PREFIX}`,
          icons: ['https://app.dgg.network/favicon.ico'],
        },
      },
    }),
);
