import React from 'react';

function RewardIcon(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 42h36V16H6v26z" fill="url(#paint0_linear_1237_1656)" />
      <path
        d="M14 16h8v8H6v4h16v14h4V28h16v-4H26v-8h8v-4h-5.172l3.586-3.586-2.828-2.828L26 9.172V4h-4v5.172l-3.586-3.586-2.828 2.828L19.172 12H14v4z"
        fill="#76808F"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1237_1656"
          x1={24}
          y1={42}
          x2={24}
          y2={16}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04ABAB" />
          <stop offset={1} stopColor="#0FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default RewardIcon;
