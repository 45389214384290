import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/imgs';
import './styles.scss';

function ModalDuration(props) {
  const { show, setShow } = props;
  const [tag, setTag] = useState(0);
  const [time, setTime] = useState();
  const [isChecked, setChecked] = useState(true);
  const modalRef = useRef(null);
  const { t } = useTranslation();
  const tagDurations = [
    { name: 'Unlimited', value: 0 },
    { name: '07 days', value: 7 },
    { name: '30 days', value: 30 },
    { name: '90 days', value: 90 },
  ];

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div className={show ? 'wrap-modal-custom d-block' : 'wrap-modal-custom'} ref={modalRef}>
      <h6 className="color-primary mb-3">{t('setting.settingTime')}</h6>
      <Row className="mb-3">
        {tagDurations.map((item) => (
          <Col lg="3" key={item.value}>
            <h6
              className={tag === item.value ? 'tag-active tag-duration' : 'tag-duration'}
              onClick={() => setTag(item.value)}
              aria-hidden
            >
              {item.name}
            </h6>
          </Col>
        ))}
      </Row>
      <h6 className="mb-3">{t('time.realTime')}</h6>
      <Row>
        <div className="my-input-group mb-3">
          <div className="wrap-input-custom">
            <input
              className="input-custom"
              type="text"
              placeholder={t('time.enterNunber')}
              value={time}
              onChange={(e) => setTime(e.target.value.replace(/\D/g, ''))}
            />
          </div>
        </div>
      </Row>
      <div className="mb-3">
        <img
          src={isChecked ? ICONS.iconChecked : ICONS.iconTick}
          alt="icon"
          onClick={() => setChecked(!isChecked)}
          className="cursor-pointer"
          aria-hidden
        />{' '}
        <span className="text-default-setting">{t('setting.defautSetting')}</span>
      </div>
      <div className="btn-stroke-fill">{t('nft.confirm.default')}</div>
    </div>
  );
}

export default ModalDuration;
