import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import JSCookie from 'js-cookie';
import { useWeb3React } from '@web3-react/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/imgs';
import './styles.scss';
import TButton from '../Button';
import { toggleModalOpen } from '../../../store/features/openModalLogin';
import { useAppDispatch } from '../../../store';
import { useAuthContext } from '../../../context/AuthContext';
import { browserRedirectToIndexAfterSignOut } from '../../../lib/redirect';

function ButtonWallet() {
  const [isConnect, setConnect] = useState(false);
  const [isCopy, setCopy] = useState(false);
  const walletRef = useRef(null);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const context = useWeb3React();
  const { connector } = context;
  const { isLogin } = useAuthContext();
  const { t } = useTranslation();

  useEffect(() => {
    function handleClickOutside(event) {
      if (walletRef.current && !walletRef.current.contains(event.target)) {
        if (isLogin) {
          setConnect(false);
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [walletRef, isConnect]);

  const goToEditProfile = () => {
    history.push('/profile');
  };

  const handleLogOut = () => {
    setConnect(false);
    JSCookie.remove('token');
    JSCookie.remove('email');
    JSCookie.remove('walletAccount');
    JSCookie.remove('expiresIn');
    JSCookie.remove('accessToken');
    JSCookie.remove('refreshToken');
    JSCookie.remove('isCreateProfile');
    JSCookie.remove('isLinkedWithEmaiAddress');

    if (connector?.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
    localStorage.clear();
    browserRedirectToIndexAfterSignOut();
  };

  const handleCopy = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 500);
  };

  const handleShow = () => {
    if (isLogin) {
      setConnect(true);
    } else {
      dispatch(toggleModalOpen());
    }
  };

  const accountEmail = JSCookie.get('email');
  const walletAccount = JSCookie.get('walletAccount');

  const accountShow = accountEmail !== 'null' || !accountEmail ? accountEmail : walletAccount;
  return (
    <>
      <div className="btn-custom-wallet" ref={walletRef} onClick={handleShow} aria-hidden>
        <TButton
          title={accountShow ? t('profile.title') : t('login.loginButton')}
          leftIcon={accountShow ? ICONS.iconProfileHeader : null}
          className="btn-custom-wallet"
        />
        <div
          className={isConnect ? 'd-block wrap-sub-wallet' : 'wrap-sub-wallet'}
          aria-hidden="true"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="title">{t('profile.yourAddress')} </div>
          <CopyToClipboard text={accountShow} onCopy={handleCopy}>
            <div className="d-flex align-items-center mb-2" id="id-copy-tooltip">
              <h6 id="copyId">{accountShow}</h6>
              <img src={ICONS.iconCopy} alt="icon" />
            </div>
          </CopyToClipboard>

          <Row className="justify-content-center">
            <div className="custom-btn-wrapper">
              <TButton
                title={t('profile.edit')}
                leftIcon={ICONS.iconEditProfile}
                className="btn-custom-edit-profile"
                onClick={goToEditProfile}
              />
              <TButton
                title={t('profile.logOut')}
                leftIcon={ICONS.iconLogout}
                className="btn-back-ground-logout"
                onClick={handleLogOut}
              />
            </div>
          </Row>
          <div className="line" />
        </div>
      </div>
      <Tooltip target="id-copy-tooltip" placement="top" isOpen={isCopy}>
        {t('copy.copied')}
      </Tooltip>
    </>
  );
}

export default ButtonWallet;
