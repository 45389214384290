import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import JSCookie from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ICONS } from '../../../../assets/imgs';
import { ERR_MESSAGE, MAX_LENGTH_EMAIL, OTP_COUNTDOWN_TIME } from '../../../constants';
import AuthApi from '../../../services/authen';
import { useAppDispatch } from '../../../store';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import InputField from '../Input';
import useMessage from '../toast/UseMessage';
import './styles.scss';
import TButton from '../Button/index';
import { useAuthContext } from '../../../context/AuthContext';
import { getProfile } from '../../../store/features/login';

function ModalLinkEmail(props) {
  const { show, setShow, title } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();
  const loginRef = useRef(null);
  const history = useHistory();
  const { pathname } = history.location;
  const path = '/';

  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [isCountDown, setCountDown] = useState(false);
  const [isChecked, setChecked] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (loginRef.current && !loginRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [loginRef]);

  const [numCount, setNumCount] = useState(OTP_COUNTDOWN_TIME);

  const { checkLinkedEmailAddress } = useAuthContext();

  const countDownNft = () => {
    setCountDown(true);
    let count = OTP_COUNTDOWN_TIME;
    const countDown = setInterval(() => {
      count -= 1;
      if (count < 0) {
        clearInterval(countDown);
        setCountDown(false);
        setNumCount(OTP_COUNTDOWN_TIME);
      }
      if (count < 0) {
        setNumCount(OTP_COUNTDOWN_TIME);
      } else {
        setNumCount(count);
      }
    }, 1000);
  };

  const handleSendOtp = async () => {
    if (isCountDown) return;
    dispatch(showLoading());
    try {
      const formData = {
        email,
      };
      await AuthApi.register(formData);
      countDownNft();
    } catch (error) {
      openMessageError(error?.response?.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleLoginEmail = async () => {
    if (!otp || !email || !isChecked) return;
    dispatch(showLoading());
    try {
      const formData = {
        email,
        token: otp,
      };
      const res = await AuthApi.connectEmail(formData);
      const refreshExpireIn = JSCookie.get('expiresIn');
      if (res?.status >= 200 && res?.status < 300) {
        const expiresInMilliseconds = parseInt(refreshExpireIn, 10);
        const dateExpire = new Date(expiresInMilliseconds);

        JSCookie.set('email', email, { expires: dateExpire });
        JSCookie.set('isLinkedWithEmaiAddress', true, { expires: dateExpire });
        checkLinkedEmailAddress();
        setShow(false);
        dispatch(getProfile());
        toast.success(t('success.linkEmailSuccessful'));
      }
    } catch (error) {
      openMessageError(error?.response?.data?.message || ERR_MESSAGE);
    } finally {
      setOtp('');
      dispatch(hideLoading());
    }
  };

  const isCheckLoginEmail = otp && email;

  return (
    <div
      className={show ? 'wrap-modal-custom d-block' : 'wrap-modal-custom'}
      ref={loginRef}
      style={path === pathname ? { left: '50%', zIndex: '1000' } : { zIndex: '1000' }}
    >
      <h6 className="color-primary mb-2" style={{ fontSize: '24px' }}>
        {title}
      </h6>

      <div className="wrap-group-email">
        <InputField
          className="input-email-otp"
          title={t('profile.yourEmail')}
          type="email"
          isRequired
          placeholder="Email"
          onChange={setEmail}
          value={email}
          maxLength={MAX_LENGTH_EMAIL}
          readonly={isCountDown}
        />
        <div className="d-flex align-items-center gap-2 wrap-otp mt-3">
          <InputField
            typeInput={t('profile.number')}
            className="input-email-otp"
            title="OTP"
            placeholder={t('login.withEmail.sendOTP', { seconds: `${OTP_COUNTDOWN_TIME}s` })}
            onChange={setOtp}
            value={otp}
            maxLength={6}
          />
          <div
            className={
              isCountDown
                ? 'btn-stroke-fill btn-send-otp btn-filter-disable'
                : 'btn-stroke-fill btn-send-otp'
            }
            onClick={handleSendOtp}
            aria-hidden
          >
            {isCountDown ? `${numCount}s` : t('login.withEmail.OTP')}
          </div>
        </div>
      </div>

      <div className="d-flex mt-3 mb-3">
        <img
          src={isChecked ? ICONS.iconChecked : ICONS.iconTick}
          alt="dgg-netWork"
          style={{ width: '20px', height: '20px', marginRight: '10px' }}
          onClick={() => setChecked(!isChecked)}
          aria-hidden
        />
        <div>
          <p style={{ fontSize: '12px' }}>{t('privacy.linkEmail')}</p>
          <p className="color-primary cursor-pointer">{t('privacy.policy')}</p>
        </div>
      </div>

      <TButton
        title={t('require.linkEmail')}
        className={isCheckLoginEmail && isChecked ? 'mb-3' : 'mb-3 btn-filter-disable'}
        onClick={handleLoginEmail}
        width="full-width"
      />
    </div>
  );
}

export default ModalLinkEmail;
