import React from 'react';

function EyeDeactiveIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.78 5.038c1.423 1.098 2.634 2.707 3.504 4.72a.607.607 0 010 .476c-1.74 4.024-4.837 6.432-8.285 6.432h-.008c-3.439 0-6.536-2.408-8.276-6.432a.607.607 0 010-.477c1.74-4.025 4.837-6.424 8.276-6.424H10c1.724 0 3.358.598 4.78 1.705zM6.747 10c0 1.777 1.456 3.224 3.252 3.224 1.789 0 3.244-1.447 3.244-3.224A3.24 3.24 0 0010 6.767 3.241 3.241 0 006.747 10z"
        fill="#0FF"
      />
      <path
        opacity={0.4}
        d="M12.026 9.997c0 1.107-.91 2.012-2.025 2.012A2.026 2.026 0 017.97 9.997c0-.138.016-.266.04-.395h.041c.902 0 1.634-.712 1.667-1.601.09-.016.187-.024.284-.024 1.114 0 2.025.905 2.025 2.02"
        fill="#0FF"
      />
    </svg>
  );
}

export default EyeDeactiveIcon;
