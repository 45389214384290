import React from 'react';

function MetamaskIcon(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M39.073 1L21.91 13.7l3.192-7.484L39.073 1z" fill="#E17726" />
      <path
        d="M.967 1.016l13.935 5.201 3.03 7.583L.967 1.016zM32.167 28.008l7.586.144-2.651 9.007-9.257-2.549 4.322-6.602zM7.834 28.008l4.306 6.602L2.9 37.16.264 28.151l7.57-.144z"
        fill="#E27625"
      />
      <path
        d="M17.521 11.867l.31 10.013-9.276-.422 2.638-3.98.034-.039 6.294-5.572zM22.383 11.758l6.39 5.684.034.038 2.639 3.98-9.275.423.212-10.125zM12.41 28.04l5.066 3.946-5.884 2.84.819-6.787zM27.59 28.04l.802 6.787-5.868-2.84 5.066-3.948z"
        fill="#E27625"
      />
      <path
        d="M22.654 31.613l5.955 2.883-5.54 2.633.058-1.74-.473-3.776zM17.346 31.613l-.455 3.746.038 1.767-5.552-2.63 5.969-2.883z"
        fill="#D5BFB2"
      />
      <path
        d="M15.626 23.188l1.556 3.27-5.297-1.552 3.741-1.718zM24.373 23.188l3.76 1.717-5.316 1.552 1.556-3.27z"
        fill="#233447"
      />
      <path
        d="M12.817 28.004l-.856 7.037-4.59-6.883 5.446-.154zM27.184 28.004l5.446.154-4.607 6.884-.84-7.038zM31.58 21.063l-3.963 4.039-3.056-1.397-1.463 3.076-.96-5.289 9.442-.43zM8.418 21.063l9.443.43-.96 5.288-1.463-3.075-3.04 1.396-3.98-4.04z"
        fill="#CC6228"
      />
      <path
        d="M8.152 20.23l4.485 4.55.155 4.493-4.64-9.043zM31.856 20.223l-4.648 9.058.175-4.5 4.473-4.558zM17.642 20.508l.18 1.136.447 2.83-.287 8.691-1.356-6.982v-.072l1.016-5.603zM22.357 20.492l1.02 5.619-.001.072-1.36 7-.053-1.751-.212-7.01.606-3.93z"
        fill="#E27525"
      />
      <path
        d="M27.78 24.602l-.152 3.902-4.73 3.686-.957-.676 1.072-5.52 4.767-1.392zM12.236 24.602l4.75 1.391 1.072 5.521-.956.676-4.73-3.686-.136-3.902z"
        fill="#F5841F"
      />
      <path
        d="M10.472 33.633l6.052 2.867-.026-1.224.506-.445h5.99l.525.443-.039 1.224 6.014-2.858-2.926 2.418-3.539 2.43h-6.073l-3.536-2.44-2.948-2.415z"
        fill="#C0AC9D"
      />
      <path
        d="M22.22 31.23l.856.605.501 4-.725-.612h-5.7l-.712.625.485-4.013.856-.605h4.44z"
        fill="#161616"
      />
      <path
        d="M37.94 1.352L40 7.532l-1.287 6.25.917.707-1.24.946.932.72-1.234 1.123.757.549-2.01 2.348-8.245-2.401-.072-.039-5.942-5.012L37.94 1.352zM2.06 1.352l15.364 11.371-5.942 5.012-.071.039-8.246 2.4-2.01-2.347.757-.548-1.233-1.125.93-.718-1.259-.949.951-.707L0 7.533l2.06-6.181z"
        fill="#763E1A"
      />
      <path
        d="M28.187 17.25l8.737 2.544 2.838 8.747h-7.488l-5.16.066 3.752-7.314-2.679-4.043zM11.813 17.25l-2.68 4.043 3.753 7.314-5.157-.066H.254l2.822-8.747 8.737-2.544zM25.529 6.172l-2.444 6.6-.519 8.916-.198 2.794-.016 7.139h-4.705l-.015-7.125-.2-2.81-.518-8.914-2.443-6.6h11.058z"
        fill="#F5841F"
      />
    </svg>
  );
}

export default MetamaskIcon;
