import React from 'react';
import { Bracket, Seed, SingleLineSeed } from 'react-brackets';
import SeedBracket from '../../common/SeedBracket';
import TitleBracket from '../../common/TitleBracket';

const LosingBracket = ({ rounds: losing, height }) => {
  const roundTitleComponent = (title) => <TitleBracket title={title} />;

  const customSeed = ({ seed, roundIndex }) => {
    const isLineConnector =
      losing[roundIndex].seeds.length === losing[roundIndex + 1]?.seeds.length;
    const Wrapper = isLineConnector ? SingleLineSeed : Seed;
    return (
      <Wrapper>
        <SeedBracket seed={seed} branchLose={roundIndex === losing.length - 1} height={height} />
      </Wrapper>
    );
  };

  return (
    <div>
      <Bracket
        mobileBreakpoint={0}
        rounds={losing}
        roundTitleComponent={(title) => roundTitleComponent(title)}
        renderSeedComponent={customSeed}
      />
    </div>
  );
};

export default LosingBracket;
