import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/common/Layout';
import { HOME } from '../../../assets/imgs/index';
import TButton from '../../components/common/Button';
import './styles.scss';

function PageNotFound(props) {
  const { dataConnect } = props;
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Layout title="404 NOT FOUND" dataConnect={dataConnect}>
      <div className="page-not-found-container">
        <img className="page-not-found-img" src={HOME.pageNotFound} alt="page-not-found" />
        <h1>{t('notification.whoops')}</h1>
        <h5>{t('notification.noti2')}</h5>
        <TButton
          className="go-back-btn"
          title={t('navigate.goBack')}
          onClick={() => history.push('/')}
        />
      </div>
      ;
    </Layout>
  );
}

export default PageNotFound;
