import React from 'react';
import './styles.scss';

const GameCard = (props) => {
  const { game, isInfo = true, onClick, idActive, goToDetail } = props;
  return (
    <div
      className={isInfo ? 'wrap-game-card' : 'wrap-game-card cursor-pointer'}
      onClick={
        isInfo
          ? () => {
              goToDetail(game.id);
            }
          : () => onClick(game.id)
      }
      style={idActive === game.id ? { border: '1px solid #00ffff' } : {}}
      aria-hidden
    >
      <div className="crop-img-scholarship">
        <img src={game.thumnail} className="image-game" alt="img" />
        <div className="shading" />
      </div>
      <div className="content-game-card">
        <div className="info-title">
          <div className="name">{game.name}</div>
          <div className="genre">{game.genre}</div>
        </div>
      </div>
    </div>
  );
};

export default GameCard;
