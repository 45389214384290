import React from 'react';

function ReferralStep3() {
  return (
    <svg width={41} height={40} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity={0.4}
        d="M37.451 15.998V32c0 6-2.105 8-8.422 8H12.186c-6.317 0-8.422-2-8.422-8V15.998H37.45z"
        fill="#0FF"
      />
      <path
        d="M40.67 9.996v2c0 2.2-1.115 4.001-4.21 4.001H4.878c-3.221 0-4.211-1.8-4.211-4v-2c0-2.2.99-4 4.21-4H36.46c3.095 0 4.21 1.8 4.21 4z"
        fill="#0FF"
      />
      <path
        opacity={0.4}
        d="M19.913 5.996H8.29a1.806 1.806 0 01.063-2.6l2.99-2.84c.779-.741 2.063-.741 2.842 0l5.727 5.44zM33.027 5.996H21.405l5.727-5.44c.779-.741 2.063-.741 2.842 0l2.99 2.84c.758.72.779 1.86.063 2.6z"
        fill="#0FF"
      />
      <path
        opacity={0.6}
        d="M14.225 15.998v10.281c0 1.6 1.853 2.54 3.264 1.68l1.979-1.24a2.196 2.196 0 012.316 0l1.874 1.2c1.39.88 3.263-.06 3.263-1.66V15.998H14.225z"
        fill="#0FF"
      />
    </svg>
  );
}

export default ReferralStep3;
