export const BRACKETS = {
  SINGLE_ELIMINATION: 1,
  DOUBLE_ELIMINATION: 2,
  ROUND_ROBIN: 3,
  BATTLE_ROYALE: 4,
};

export const BRACKETS_STRING = {
  SINGLE_ELIMINATION: 'Single elimination',
  DOUBLE_ELIMINATION: 'Double elimination',
  ROUND_ROBIN: 'Round robin',
  BATTLE_ROYALE: 'Battle royale',
};
