import React from 'react';

function TelegramIcon({ color = '#00FFFF', ...props }) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4_112)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20zm-19.283-5.235c-1.946.809-5.833 2.484-11.664 5.024-.947.376-1.443.745-1.488 1.105-.076.609.686.848 1.724 1.175.141.044.288.09.438.139 1.02.332 2.394.72 3.108.735.648.014 1.371-.253 2.17-.8 5.447-3.678 8.259-5.537 8.435-5.577.125-.028.298-.063.415.04.117.104.105.301.093.354-.076.322-3.068 3.104-4.616 4.543-.483.449-.825.767-.895.84-.157.163-.317.317-.47.465-.95.914-1.66 1.6.039 2.72.817.538 1.47.983 2.122 1.427.712.485 1.422.969 2.341 1.571.234.154.458.313.676.468.829.591 1.573 1.122 2.493 1.037.534-.05 1.086-.552 1.366-2.05.663-3.542 1.965-11.217 2.266-14.38a3.528 3.528 0 00-.033-.787c-.027-.155-.082-.377-.285-.541-.24-.195-.61-.236-.775-.233-.752.013-1.906.415-7.46 2.725z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_4_112">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TelegramIcon;
