import React from 'react';

function ProfileIcon(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.582 24.764c-6.606 0-12.249 1.02-12.249 5.1C3.333 33.944 8.94 35 15.583 35c6.606 0 12.248-1.02 12.248-5.1 0-4.082-5.607-5.137-12.249-5.137"
        fill="#0FF"
      />
      <path
        opacity={0.4}
        d="M15.582 20.875c4.5 0 8.105-3.531 8.105-7.938C23.687 8.531 20.082 5 15.582 5c-4.498 0-8.105 3.531-8.105 7.937 0 4.407 3.607 7.938 8.105 7.938M26.956 13.082c0 2.244-.689 4.337-1.895 6.076-.126.18-.015.42.204.459.303.05.614.08.932.086 3.164.082 6.003-1.913 6.788-4.917 1.162-4.458-2.25-8.46-6.595-8.46-.472 0-.923.048-1.364.136-.06.013-.125.041-.158.093-.042.065-.011.15.031.206a10.69 10.69 0 012.057 6.32"
        fill="#0FF"
      />
      <path
        d="M36.298 25.282c-.578-1.209-1.976-2.038-4.103-2.445-1.003-.24-3.72-.58-6.245-.532-.038.005-.06.03-.063.047-.004.025.007.064.056.09 1.168.566 5.68 3.026 5.112 8.213a.345.345 0 00.39.386c1.11-.155 3.968-.757 4.853-2.628.491-.99.491-2.14 0-3.13"
        fill="#0FF"
      />
    </svg>
  );
}

export default ProfileIcon;
