import React from 'react';
import './styles.scss';
import Slider from 'react-slick';
import CardTournament from '../Tournament/CardTournament';

function TournamentList(props) {
  const { tournaments, type } = props;
  const settings = {
    dots: false,
    infinite: tournaments.length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    useTransform: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          infinite: tournaments.length > 3,
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 1188,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: tournaments.length > 2,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: tournaments.length > 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: tournaments.length > 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {tournaments.map((tournament) => (
        <div key={tournament.id}>
          <CardTournament className="tournament-item" card={tournament} hover={false} type={type} />
        </div>
      ))}
    </Slider>
  );
}

export default TournamentList;
