import React from 'react';

function ReferralStep2() {
  return (
    <svg width={41} height={40} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.334 25.817v11.346a2.83 2.83 0 01-.838 2.005 2.871 2.871 0 01-2.02.832H3.191c-.758-.001-1.484-.3-2.02-.832a2.83 2.83 0 01-.838-2.005V25.817c.001-.752.303-1.473.838-2.005a2.87 2.87 0 012.02-.832h9.653l4.459 4.427a4.302 4.302 0 003.03 1.247 4.302 4.302 0 003.031-1.247l4.459-4.427h9.653c.758.001 1.484.3 2.02.832a2.83 2.83 0 01.837 2.005zM33.19 31.49c0-.42-.126-.832-.361-1.182a2.157 2.157 0 00-3.297-.322 2.124 2.124 0 00-.465 2.318c.162.389.437.721.79.955a2.154 2.154 0 002.705-.265 2.12 2.12 0 00.628-1.504z"
        fill="#0FF"
        opacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.978 17.305l-8.642 8.688c-.53.534-1.39.534-1.92 0l-8.643-8.688a1.37 1.37 0 010-1.93 1.353 1.353 0 011.92 0l6.324 6.357V1.365c0-.754.608-1.365 1.358-1.365.75 0 1.358.611 1.358 1.365v20.367l6.324-6.357a1.353 1.353 0 011.92 0 1.37 1.37 0 010 1.93z"
        fill="#0FF"
      />
    </svg>
  );
}

export default ReferralStep2;
