import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { useTranslation } from 'react-i18next';
import web3 from 'web3';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, Tooltip } from 'reactstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ICONS, SCHOLARSHIP } from '../../../assets/imgs';
import Layout from '../../components/common/Layout';
import { useAppDispatch } from '../../store';
import scholarshipApi from '../../services/scholarship';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { ERROR_MESSAGE_SEND_NFT, ERR_MESSAGE } from '../../constants';
import useMessage from '../../components/common/toast/UseMessage';
import './styles.scss';
import LoadingNft from '../../components/common/Loading/LoadingNft';

const STATUS_NFT_SUCCESS = 3;
const STATUS_NFT_PENDING = 2;
const CHAIN_ID_RONIN = 2020;

function Transfer(props) {
  const { t } = useTranslation();
  const { dataConnect } = props;
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();
  const [isCopy, setCopy] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [dataNft, setDataNft] = useState([]);
  const [walletAddress, setWalletAddress] = useState(null);
  const [statusRequest, setStatus] = useState();
  const [slug, setSlug] = useState('');
  const [guild, setGuild] = useState('');
  const [network, setNetword] = useState({});
  const [tokenNftId, setTokenNftId] = useState('');

  const REQUEST_REJECT = 3;
  const REQUEST_SUCCESS = 2;

  const getListNft = async () => {
    const params = {
      walletAddress,
    };
    const res = await scholarshipApi.getNftsByAddress(params, slug);
    return res.data;
  };

  const getRequestDetails = async () => {
    dispatch(showLoading());
    try {
      const res = await scholarshipApi.getRequestDetail(id);
      const { data } = res;
      if (data.nfts.length) {
        const arrayStatus = data.nfts.filter(
          (el) => parseInt(el.status, 10) === STATUS_NFT_SUCCESS,
        );
        if (arrayStatus.length === data.nfts.length) {
          setDisableBtn(false);
        }
      }
      setDataNft(data.nfts);
      setStatus(data?.status);
      setWalletAddress(data.walletAddress);
      setSlug(data.slug);
      setGuild(data?.guildName);
      setNetword(data?.network);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getRequestDetails().catch((error) => {
      openMessageError(t('error.error'), error);
    });
  }, [id]);
  const changeNftTransfer = async (idNft) => {
    try {
      const data = {
        nftId: Number(idNft),
        requestId: Number(id),
      };
      await scholarshipApi.changeStatusNftTransfer(data, slug);
    } catch (error) {
      openMessageError(error?.response?.data?.message || ERROR_MESSAGE_SEND_NFT);
    }
  };

  function createCustomTimeout(seconds) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, seconds * 1000);
    });
  }

  const checkNft = async (idNft) => {
    await createCustomTimeout(3);
    if (dataNft.length) {
      let count = 0;
      const listNft = [...dataNft];
      const nft = await getListNft();
      dataNft.forEach(async (item, index) => {
        const newArray = nft.filter((el) => parseInt(el.id, 10) === parseInt(item.id, 10));
        if (newArray.length) {
          listNft[index].status = STATUS_NFT_SUCCESS;
          count += 1;
        }
      });
      await changeNftTransfer(idNft);
      setDataNft(listNft);
      if (count === dataNft.length) {
        setDisableBtn(false);
      }
    }
  };
  const updateNft = async (idNft) => {
    if (dataNft.length) {
      let count = 0;
      const listNft = [...dataNft];
      let newArray;
      const nft = await getListNft();
      dataNft.forEach(async (item, index) => {
        newArray = nft.filter((el) => item.status === STATUS_NFT_PENDING && el.id === item.id);
        if (newArray.length) {
          listNft[index].status = STATUS_NFT_SUCCESS;
          count += 1;
        }
        if (newArray.length) {
          await changeNftTransfer(idNft);
          setDataNft(listNft);
        }
        if (count === dataNft.length) {
          setDisableBtn(false);
        }
      });
    }
  };

  const handleCopy = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  const [isSpin, setSpin] = useState(false);

  const handleClickTransfer = async (tokenId, status, e, idNft) => {
    e.stopPropagation();
    setTokenNftId(tokenId);
    setSpin(true);
    if (Number(status) !== STATUS_NFT_PENDING) return;
    if (isSpin) return;
    try {
      if (network.chain_id !== CHAIN_ID_RONIN) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: web3.utils.numberToHex(network.chain_id),
              rpcUrls: [network.url_rpc],
              chainName: network.chain_name,
              nativeCurrency: {
                name: network.symbol,
                symbol: network.symbol,
                decimals: 18,
              },
              blockExplorerUrls: [network.block_explorer_url],
            },
          ],
        });
      }
      const data = {
        requestId: Number(id),
        nftId: tokenId,
      };
      const res = await scholarshipApi.getTransactionTransferNft(data);
      const { tx } = res.data.data;
      let provider;
      if (network.chain_id === CHAIN_ID_RONIN) {
        provider = new ethers.providers.Web3Provider(window.ronin.provider);
        await provider.send('eth_accounts', []);
      } else {
        provider = new ethers.providers.Web3Provider(window.ethereum, network.chain_id);
        await provider.send('eth_requestAccounts', []);
      }
      const signer = provider.getSigner();
      const txTransaction = await signer.sendTransaction(tx);
      await txTransaction.wait();
      await checkNft(idNft);
      setSpin(false);
    } catch (error) {
      openMessageError(error?.response?.data?.message || ERROR_MESSAGE_SEND_NFT);
    }
  };

  const [isTooltipMarket, setIsTooltip] = useState(true);

  const handleHover = () => {
    setIsTooltip(false);
  };
  const handleHoverOut = () => {
    setIsTooltip(true);
  };
  useEffect(() => {
    if (slug && walletAddress) {
      dataNft.map((item) => updateNft(item.nftId));
    }
  }, [slug, walletAddress]);

  const renderItem = (item, index) => (
    <Col
      lg="3"
      md="4"
      sm="6"
      className={`mb-4 px-0 ${isTooltipMarket && 'tooltip-market'} wrap-nft-transfer-card`}
      key={index}
      onClick={() => window.open(item.market_url, '_blank')}
      aria-hidden
    >
      <div className="wrap-nft-transfer-item mb-3">
        {item.type_media === 'video' ? (
          <video autoPlay playsInline loop width="100%">
            <track kind="captions" />
            <source src={item.img} type="video/mp4" />
          </video>
        ) : (
          <img src={item.img} alt="axies" style={{ width: '100%' }} />
        )}

        <div
          className={
            item.status === STATUS_NFT_PENDING
              ? 'btn-stroke-fill btn-transfer-nft my-3'
              : 'btn-stroke-fill btn-stroke-fill-disable btn-transfer-nft my-3'
          }
          style={
            Number(statusRequest) === REQUEST_SUCCESS || Number(statusRequest) === REQUEST_REJECT
              ? { display: 'none' }
              : {}
          }
          onClick={(e) => handleClickTransfer(item.id, item.status, e, item.nftId)}
          onMouseOver={handleHover}
          onFocus={() => ''}
          onBlur={() => ''}
          onMouseOut={handleHoverOut}
          aria-hidden
        >
          {t('nft.sendNFT')}
        </div>

        {Number(statusRequest) !== REQUEST_REJECT && (
          <div className="spinner-nft-transfer">
            {item.status === STATUS_NFT_SUCCESS || Number(statusRequest) === REQUEST_SUCCESS ? (
              <div className="ticker" />
            ) : (
              isSpin && item.id === tokenNftId && <LoadingNft />
            )}
          </div>
        )}
        <div
          className="wrap-nft-transfer-sub"
          style={
            Number(statusRequest) === REQUEST_SUCCESS || Number(statusRequest) === REQUEST_REJECT
              ? { bottom: '0', padding: '10px 10px' }
              : {}
          }
        >
          <h6>{`#${item.id}`}</h6>
          <h6>{item.name}</h6>
        </div>
      </div>
    </Col>
  );

  const handleClickFinish = async () => {
    dispatch(showLoading());
    try {
      await scholarshipApi.updateRequestStatus(id, slug);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
      history.goBack();
    }
  };

  return (
    <Layout title={t('nft.transfer')} dataConnect={dataConnect} type={1} link="/assets">
      <Col>
        <div className="scholarship-banner">
          <img src={SCHOLARSHIP.bannerScholarship} alt="banner" />
        </div>
      </Col>
      <Col className="mb-2">
        <div className="wrap-text">
          <h3>{t('nft.receiveNft')}</h3>
          <p>{t('nft.sendSuccessNFT')}</p>
          {guild && (
            <h6 className="mt-5 mb-0">
              {t('nft.guild')}: <span className="color-primary">{guild}</span>
            </h6>
          )}
        </div>
      </Col>
      <Col className="mb-5">
        <div className="wrap-text">
          <CopyToClipboard text={walletAddress} onCopy={handleCopy}>
            <div className="d-flex align-items-center mb-2" id="copyTooltip">
              <h6 id="copyId" className="copy-wallet-sp">
                {walletAddress}
              </h6>
              <img className="m-2" src={ICONS.iconCopy} alt="icon" />
            </div>
          </CopyToClipboard>
        </div>
      </Col>
      <Row style={{ justifyContent: 'center' }}>
        {dataNft.map((item, index) => renderItem(item, index))}
      </Row>
      {Number(statusRequest) !== REQUEST_REJECT && Number(statusRequest) !== REQUEST_SUCCESS && (
        <div className="mb-5 d-flex justify-content-center">
          <button
            type="button"
            className={
              disableBtn
                ? 'btn btn-stroke-fill btn-finish btn-stroke-fill-disable'
                : 'btn btn-stroke-fill btn-finish'
            }
            disabled={disableBtn}
            onClick={handleClickFinish}
          >
            {t('nft.DoneSendingNFTs')}
          </button>
        </div>
      )}

      <Tooltip target="copyTooltip" placement="top" isOpen={isCopy}>
        {t('copy.copied')}
      </Tooltip>
    </Layout>
  );
}

export default Transfer;
