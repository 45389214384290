import React from 'react';

function WalletConnectIcon(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.189 12.79c6.523-6.387 17.1-6.387 23.622 0l.785.769a.806.806 0 010 1.156l-2.685 2.63a.424.424 0 01-.59 0l-1.081-1.058c-4.55-4.456-11.93-4.456-16.48 0l-1.157 1.133a.424.424 0 01-.59 0l-2.686-2.63a.806.806 0 010-1.156l.862-.844zm29.176 5.438l2.39 2.34a.806.806 0 010 1.156L28.978 32.278a.848.848 0 01-1.181 0l-7.65-7.49a.212.212 0 00-.295 0l-7.649 7.49a.848.848 0 01-1.18 0L.244 21.724a.806.806 0 010-1.156l2.39-2.34a.848.848 0 011.18 0l7.65 7.489c.082.08.214.08.295 0l7.65-7.49a.848.848 0 011.18 0l7.65 7.49c.081.08.213.08.295 0l7.65-7.49a.848.848 0 011.18 0z"
        fill="#F5F5F5"
      />
    </svg>
  );
}

export default WalletConnectIcon;
