import BigNumber from 'bignumber.js';

const formatPointNumber = (price, numberFormat = 2) => {
  try {
    const numericPrice = Number(price);
    if (!Number.isNaN(numericPrice) && Number.isInteger(numericPrice)) {
      return new BigNumber(numericPrice).toFormat();
    }
    // return new BigNumber(numericPrice).toFormat(numberFormat);
    const formattedPrice = new BigNumber(numericPrice).toFormat(numberFormat);
    const [integerPart, decimalPart] = formattedPrice?.split('.');

    if (decimalPart) {
      const trimmedDecimalPart = decimalPart?.replace(/0+$/, '');
      return trimmedDecimalPart ? `${integerPart}.${trimmedDecimalPart}` : integerPart;
    }

    return formattedPrice;
  } catch (error) {
    console.log('Error format point: ', error);
    return price;
  }
};

export default formatPointNumber;
