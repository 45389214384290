import React from 'react';
import './style.scss';
import { SERVICE_UNAVAILABLE_IMG } from '../../../assets/imgs';

function ServiceUnavailable(props) {
  const { dataConnect } = props;

  return (
    <div dataConnect={dataConnect} link="/service-unavailable" className="wrap-service-unavailable">
      <div className="wrap-description-area">
        <img src={SERVICE_UNAVAILABLE_IMG.serviceUnavailableImg} alt="dgg-503" className="image" />
        <div className="service-text">
          Service
          <br />
          Unvailable
        </div>
        <div className="description-service-unavailable">
          Our service is temporarily unavailable
          <br />
          due to maintenance downtime.
          <br />
          Please try again in a few minutes.
        </div>
        <img src={SERVICE_UNAVAILABLE_IMG.planet1} alt="dgg-planet" className="planet1-img" />
      </div>
      <img src={SERVICE_UNAVAILABLE_IMG.land} alt="dgg-503" className="maintenance-land" />
      <img src={SERVICE_UNAVAILABLE_IMG.robo} alt="dgg-robo" className="maintenance-robo" />
      <img src={SERVICE_UNAVAILABLE_IMG.planet2} alt="dgg-planet" className="planet2-img" />
      <img src={SERVICE_UNAVAILABLE_IMG.planet3} alt="dgg-planet" className="planet3-img" />
    </div>
  );
}

export default ServiceUnavailable;
