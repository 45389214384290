import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import ReferralCopyIcon from '../../components/icons/ReferralCopyIcon';
import shortenAddress from '../../utils/shortenAddress';
import VIcon from '../../components/icons/VIcon';
import { COPY_TIME_INTERVAL } from '../../constants';

function ReferralOption({ label = '', content = '' }) {
  const [copiedText, setCopiedText] = useState(false);

  useEffect(() => {
    if (copiedText) {
      const resetState = setInterval(() => {
        setCopiedText(false);
      }, COPY_TIME_INTERVAL);
      return () => clearInterval(resetState);
    }
    return undefined;
  }, [copiedText]);

  return (
    <div>
      <div className="title-invite-friend">{label}</div>
      <div className="referral-option">
        <p className="referral-label">
          {content.length > 21 ? shortenAddress(content, 21) : content}
        </p>
        <div>
          <CopyToClipboard onCopy={() => setCopiedText(true)} text={content}>
            {copiedText ? (
              <VIcon color="#00ffff" />
            ) : (
              <ReferralCopyIcon className="cursor-pointer" />
            )}
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}

export default ReferralOption;
