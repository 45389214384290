import React from 'react';
import { ICONS } from '../../../../../assets/imgs';
import TButton from '../../../common/Button';

function RegistrationForm(props) {
  const { isForm, comment, setComment, setIsForm, setIsTeam, titleRegistration, registrationForm } =
    props;
  const showSelectTeam = () => {
    if (isForm) setIsTeam(true);
  };
  return (
    <>
      <h6 className="color-primary mb-2" style={{ fontSize: '24px' }}>
        {titleRegistration}
      </h6>
      <div
        className="link-registration"
        onClick={() => window.open(registrationForm, '_blank')}
        aria-hidden
      >
        <TButton title="Link form registration question" className="btn-link-form" />
      </div>
      <div className="registration-question">
        <img
          src={isForm ? ICONS.iconChecked : ICONS.iconTick}
          alt="dgg-netWork"
          style={{ width: '20px', height: '20px', marginRight: '10px' }}
          onClick={() => setIsForm(!isForm)}
          aria-hidden
        />
        <p style={{ fontSize: '12px' }}>
          By checking this box, you are confirming that you have filled the form above.
          Administrators will check your form and accept your request to join the tournament if all
          of the information is valid
        </p>
      </div>
      <div className="wrap-group-email">
        <textarea
          className="input-comment"
          placeholder="Comment"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        />
      </div>
      <TButton
        title="Join Tournament"
        width="full-width"
        className={isForm ? 'mb-3' : 'mb-3 btn-filter-disable'}
        onClick={showSelectTeam}
      />
    </>
  );
}

export default RegistrationForm;
