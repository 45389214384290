import JSCookie from 'js-cookie';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import useSwitchNetwork from './useSwitchNetwork';
import useActiveWeb3React from './useActiveWeb3React';
import tournamentApi from '../services/tournament';
import { ConfirmRequestBackendStatus } from '../constants';

export default function useConfirmRequest({ setStatus, setRedeemSignature, chainId }) {
  const { switchNetwork } = useSwitchNetwork();
  const { account: signerAddress } = useActiveWeb3React();

  const createConfirmRequest = useCallback(
    async ({ requestTransactionHash }) => {
      try {
        if (!requestTransactionHash) {
          toast.error('Please close modal and try again later');
          return;
        }
        if (!signerAddress) {
          toast.error('Please connect your wallet');
          return;
        }

        const walletAddress = JSCookie.get('walletAccount');
        if (walletAddress !== signerAddress) {
          toast.error('Please connect to your linked wallet');
          return;
        }

        await switchNetwork(chainId);
        setStatus(ConfirmRequestBackendStatus.PENDING);
        const { data } = await tournamentApi.sendConfirmRequest(requestTransactionHash, chainId);
        setRedeemSignature(data);
        if (data) {
          setTimeout(() => {
            setStatus(ConfirmRequestBackendStatus.CREATED);
          }, 3000);
        } else {
          setStatus(ConfirmRequestBackendStatus.FAILED);
        }
      } catch (error) {
        console.log('Create Confirm Request Error: ', error);
        if (error.message.includes('user rejected transaction')) {
          toast.error('Oops, you have rejected request');
          setStatus(ConfirmRequestBackendStatus.REJECTED);
        } else {
          toast.error('Failed to verify signature. Please try again later');
          setStatus(ConfirmRequestBackendStatus.FAILED);
        }
      }
    },
    [chainId, signerAddress],
  );

  return { createConfirmRequest };
}
