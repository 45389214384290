import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import Layout from '../../components/common/Layout';
import './style.scss';
import OverViewIcon from '../../components/icons/OverViewIcon';
import RewardIcon from '../../components/icons/RewardIcon';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import Pagination from '../../components/common/Pagination/index';
import { TOURNAMENT_PRIZE } from '../../../assets/imgs';
import tournamentApi from '../../services/tournament';
import UnauthorizePage from '../UnauthorizePage/UnauthorizePage';
import useMessage from '../../components/common/toast/UseMessage';
import { ERR_MESSAGE, GAME_TYPE } from '../../constants';
import { useAuthContext } from '../../context/AuthContext';
import TButton from '../../components/common/Button';
import SelectCustom from '../../components/common/SelectCustom';
import scholarshipApi from '../../services/scholarship';
import formatPointNumber from '../../lib/formatPointNumber';
import ReferralTable from '../../components/common/CommonTable/ReferralTable';

const headerTextAlignCenter = ['Game', 'Amount', 'Status'];

function MyPrizes(props) {
  const { dataConnect } = props;
  const { openMessageError } = useMessage();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalPage, setTotalPage] = useState();
  const [battleRoyaleRewardTable, setBattleRoyaleRewardTable] = useState([]);
  const [overviewMyPrize, setOverviewMyPrize] = useState();
  const [gameFilter, setGameFilter] = useState();
  const [listGame, setListGame] = useState([]);

  const history = useHistory();
  const { isLogin } = useAuthContext();

  const columnsMyNft = [
    {
      Header: ' ',
      columns: [
        {
          Header: t('header.tournaments'),
          accessor: (data) => (
            <div className="tournament-title cursor-pointer" aria-hidden="true">
              <a href={`/tournament/${data?.tournamentSlug}`} rel="noreferrer">
                {data?.tournamentName}
              </a>
            </div>
          ),
        },
        {
          Header: t('donate.game'),
          accessor: (data) => <p className="">{data?.gameName}</p>,
        },
        {
          Header: t('prize'),
          accessor: (data) => <p className="">{data?.prize}</p>,
        },
        {
          Header: t('donate.amount'),
          accessor: (data) => {
            const amount = data?.amount;
            if (amount) {
              return (
                <p>
                  {formatPointNumber(amount)} {data?.tokenSymbol}
                </p>
              );
            }
            return <p>-</p>;
          },
        },
        {
          Header: t('filter.date'),
          accessor: (data) => (
            <p className="">
              {format(
                parseISO(data?.createdAt ? data?.createdAt : new Date().toISOString()),
                'dd/MM/yyyy',
              )}
            </p>
          ),
        },
      ],
    },
  ];

  const getBattleRoyaleReward = async () => {
    dispatch(showLoading());

    const params = {
      page: currentPage,
      limit: itemsPerPage,
    };
    if (gameFilter) {
      params.gameId = gameFilter;
    }
    try {
      const res = await tournamentApi.getBattleRoyaleReward(params);
      setBattleRoyaleRewardTable(res.data.battleRoyalePrizes);
      setTotalPage(res.data.totalPage);
    } catch (error) {
      openMessageError(error.response?.data || error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const getOverViewReward = async () => {
    dispatch(showLoading());
    try {
      const res = await tournamentApi.getOverViewReward();
      setOverviewMyPrize(res.data);
    } catch (error) {
      openMessageError(error.response?.data || error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const getListGame = async () => {
    const res = await scholarshipApi.getListGame();
    setListGame(res.data);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getData = async () => {
    dispatch(showLoading());
    try {
      await getOverViewReward();
      await getListGame();
    } catch (error) {
      openMessageError(error.response?.data || error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    getBattleRoyaleReward();
  }, [gameFilter]);

  useEffect(() => {
    getBattleRoyaleReward().catch((error) => {
      openMessageError(t('error.error'), error);
    });
  }, [currentPage]);

  return (
    <Layout title={t('myPrize.defalt')} dataConnect={dataConnect} type={1} link="/tournament">
      {isLogin ? (
        <div>
          <img
            src={TOURNAMENT_PRIZE.tournamentPrize}
            className="image-my-prize"
            alt="banner-prize"
          />

          <div className="prize-body">
            <div className="overview-title">
              <OverViewIcon />
              <p className="prize-head-title">{t('myPrize.Overview')}</p>
            </div>
            {overviewMyPrize?.length > 0 ? (
              <div className="list-game-overview-wrapper">
                {overviewMyPrize?.map((i) => (
                  <GameCardPrize data={i} key={i?.id} />
                ))}
              </div>
            ) : (
              <div className="no-data-points">{t('myPrize.noData')}</div>
            )}
            {overviewMyPrize?.length > 0 && (
              <div className="btn-redeem-rewards-wrapper">
                <TButton
                  title={t('redeemRewards.title')}
                  className="btn-redeem-reward"
                  onClick={() => {
                    history.push('/redeem-reward');
                  }}
                />
              </div>
            )}
            <div className="my-reward-wrapper">
              <div className="my-rewards">
                <RewardIcon />
                <p className="prize-head-title">{t('myPrize.rewardsHistory')}</p>
              </div>
              <div className="select-btn-wrapper">
                <div className="btn-select-game-wrapper">
                  <SelectCustom
                    items={listGame}
                    selectedItem={gameFilter}
                    setSelectedItem={setGameFilter}
                    classNameDropdown="btn-select-game"
                    type={GAME_TYPE}
                    textDefault={t('referral.selectGame')}
                    selectAllGame
                  />
                </div>
                <div className="btn-transaction-history-wrapper">
                  <TButton
                    title={t('referral.viewAllTransactionHistory')}
                    className="btn-transaction-history"
                    onClick={() => {
                      history.push('/transaction-history');
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="reward-history-table">
              <ReferralTable
                headerTextAlignCenter={headerTextAlignCenter}
                pageSizePagination={itemsPerPage}
                columns={columnsMyNft}
                data={battleRoyaleRewardTable}
              />
              <div className="reward-pagination">
                <Pagination
                  totalPage={totalPage}
                  onChange={handlePageChange}
                  forcePage={currentPage}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <UnauthorizePage />
      )}
    </Layout>
  );
}

export default MyPrizes;

function GameCardPrize({ data }) {
  const { t } = useTranslation();
  return (
    <div className="game-card-prize">
      <div className="img-card-thumbnail-wrapper">
        <img src={data?.thumnail} alt="dgg-img" className="img-card-thumbnail" />
      </div>
      <div className="content-wrapper">
        <div className="column-card-wrapper">
          <p>{t('explore.game')}</p>
          <div className="prize-info">{data?.name}</div>
        </div>
        <div className="column-card-wrapper">
          <p>{t('myPrize.reward')}</p>
          <div className="reward-wrapper">
            <p className="reward-number">{formatPointNumber(data?.totalRewards)}</p>
            <img src={data?.tokenImage} alt="ticket-icon" className="img-ticket-icon" />
          </div>
        </div>
        <div className="column-card-wrapper">
          <p>{t('myPrize.tournamentPlayed')}</p>
          <div className="prize-info">{data?.tournamentPlayed}</div>
        </div>
        <div className="column-card-wrapper">
          <p>{t('myPrize.totalPrize')}</p>
          <div className="prize-info">{data?.totalPrizes}</div>
        </div>
      </div>
    </div>
  );
}
