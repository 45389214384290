import React from 'react';
import { useTranslation } from 'react-i18next';
import TicketIcon from '../../icons/TicketIcon';
import formatPointNumber from '../../../lib/formatPointNumber';

function RedeemGameCard({ selectedGame, setSelectedGame, data = null }) {
  const { t } = useTranslation();
  return (
    <div
      className={`redeem-game-card-wrapper ${selectedGame === data ? 'redeem-card-active' : ''}`}
      aria-hidden
      onClick={() => setSelectedGame(data)}
    >
      <div className="img-game-banner-wrapper">
        <img className="img-game-banner" src={data?.thumnail} alt="game-card" />
      </div>
      <div className="game-info-wrapper">
        <div className="game-name">{data?.name}</div>
        <div className="balance-wrapper">
          <div className="title">{t('myPrize.totalBalance')}</div>
          <div className="balance-point">
            <p className="balance-point-number">{formatPointNumber(selectedGame?.totalBalance)}</p>
            {data?.tokenImage ? (
              <img className="img-balance-point" src={data?.tokenImage} alt="game-point" />
            ) : (
              <TicketIcon />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RedeemGameCard;
