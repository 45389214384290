import React from 'react';
import { Spinner as SpinnerComponent } from 'react-bootstrap';
import { useAppSelector } from '../../../store';

import './spinner.scss';

export default function Spinner() {
  const isLoading = useAppSelector((state) => state.loadingPage);

  return (
    <div className={`spinner ${isLoading || 'visually-hidden'}`}>
      <SpinnerComponent animation="border" role="status" variant="light" />
    </div>
  );
}
