import React from 'react';
import { useTranslation } from 'react-i18next';
import { QR_CODE_IMG } from '../../../../assets/imgs';

function LoginWithWalletConnect() {
  const { t } = useTranslation();
  return (
    <div className="login-wallet-wrapper">
      <div className="login-wallet-btn">
        <img src={QR_CODE_IMG.walletConnectIcon} alt="metamask-logo" className="metamask-logo" />
        <p className="open-wallet">{t('login.withWalletConnect.open')}</p>

        <p className="warning-text">{t('login.withWalletConnect.message')}</p>
      </div>
    </div>
  );
}

export default LoginWithWalletConnect;
