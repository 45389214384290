import React from 'react';
import './styles.scss';

const TButton = ({
  className,
  disabled = false,
  title,
  onClick,
  id,
  type,
  width,
  leftIcon,
  children,
  ...props
}) => (
  <button
    className={`btn-stroke-fill ${className} ${type} ${width} ${disabled ? 'disabled' : ''}`}
    disabled={disabled}
    onClick={onClick}
    id={id}
    type="button"
    {...props}
  >
    {leftIcon && <img src={leftIcon} alt="btn-previous-icon" className="btn-previous-icon" />}
    {title}
    {children}
  </button>
);

export default TButton;
