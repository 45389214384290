import React from 'react';

function FBIcon({ color = '#00FFFF', ...props }) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4_102)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 20C40 8.954 31.046 0 20 0S0 8.954 0 20c0 9.982 7.314 18.256 16.876 19.756V25.782h-5.08V20h5.08v-4.406c0-5.012 2.984-7.78 7.554-7.78 2.188 0 4.476.39 4.476.39v4.92h-2.52c-2.486 0-3.26 1.542-3.26 3.124V20h5.546l-.886 5.78h-4.66v13.976C32.686 38.256 40 29.982 40 20z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_4_102">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FBIcon;
