import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { HOME } from '../../../assets/imgs/index';
import TButton from '../../components/common/Button';
import './styles.scss';
import { useAppDispatch } from '../../store';
import { toggleModalOpen } from '../../store/features/openModalLogin';

function UnauthorizePage() {
  const walletRef = useRef(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <div className="unauthorize-page-container">
      <img className="unauthorize-page-img" src={HOME.unauthorPage} alt="unauthorize-page" />
      <h5>{t('require.unauthorizePage')}</h5>
      <div ref={walletRef}>
        <TButton
          className="go-login-btn"
          title={t('login.loginButton')}
          onClick={() => {
            dispatch(toggleModalOpen());
          }}
        />
      </div>
    </div>
  );
}

export default UnauthorizePage;
