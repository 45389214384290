import React from 'react';

function ShieldIcon(props) {
  return (
    <svg
      width={24}
      height={28}
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M12.114 27.334c-.165 0-.328-.038-.477-.115l-4.802-2.486c-1.364-.706-2.43-1.498-3.26-2.421a10.991 10.991 0 01-2.853-7.299L.667 6.167c-.007-1.021.65-1.938 1.636-2.283L11.12.81A2.51 2.51 0 0112.76.8l8.85 2.97c.991.33 1.661 1.24 1.666 2.26l.057 8.854a10.989 10.989 0 01-2.753 7.327c-.825.936-1.88 1.74-3.229 2.46l-4.759 2.541c-.147.08-.312.12-.478.122"
        fill="#F13535"
      />
      <path
        d="M13.423 13.633l1.811-1.763a.961.961 0 000-1.386 1.026 1.026 0 00-1.424 0l-1.811 1.762-1.81-1.762a1.026 1.026 0 00-1.424 0 .961.961 0 000 1.386l1.811 1.763-1.81 1.764a.961.961 0 000 1.386c.197.192.454.287.712.287.256 0 .514-.095.712-.287l1.809-1.763 1.81 1.763c.198.192.455.287.712.287.258 0 .516-.095.713-.287a.961.961 0 000-1.386l-1.81-1.764z"
        fill="#F13535"
      />
    </svg>
  );
}

export default ShieldIcon;
