import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HOME, ICONS } from '../../../../assets/imgs/index';
import {
  DGG_SOCIAL_NETWORK_LINK,
  ROUTER__LINK,
  WALLET_APPSTORE_URL,
  WALLET_GOOGLEPLAY_URL,
} from '../../../constants';
import SocialGroup from '../../common/SocialGroup';
import './styles.scss';

function HomeContent() {
  const { t } = useTranslation();
  const history = useHistory();
  const DESCRIPTION_DGG_HOME = t('homeContent.description');
  const mediaList = [
    {
      name: 'Facebook',
      title: 'DGG Network',
      icon: ICONS.iconFacebook,
      path: DGG_SOCIAL_NETWORK_LINK.FACEBOOK,
    },
    {
      name: 'Telegram',
      title: 'DGG Network',
      icon: ICONS.iconTelegram,
      path: DGG_SOCIAL_NETWORK_LINK.TELEGRAM,
    },
    {
      name: 'Twitter',
      title: 'DGG Network',
      icon: ICONS.iconTwitter,
      path: DGG_SOCIAL_NETWORK_LINK.TWITTER,
    },
    {
      name: 'Discord',
      title: 'DGG Network',
      icon: ICONS.iconDiscord,
      path: DGG_SOCIAL_NETWORK_LINK.DISCORD,
    },
  ];
  return (
    <div
      className="wrap-content-home"
      style={{
        backgroundImage: `url(${HOME.bgHome})`,
        backgroundSize: `cover`,
        backgroundPosition: `center`,
        backgroundRepeat: `no-repeat`,
      }}
    >
      <div className="content">
        <h1 className="title">DGG GAME PLATFORM</h1>
        <img src={HOME.dggNetwork} alt="dgg-network" className="sub-image" />
        <h2 className="sub-title">{t('homeContent.subTitle')}</h2>
        <div className="store">
          <a href={WALLET_APPSTORE_URL} target="_blank" rel="noreferrer">
            <img className="store-img" src={HOME.appleStore} alt="apple store" />
          </a>
          <a href={WALLET_GOOGLEPLAY_URL} target="_blank" rel="noreferrer">
            <img className="store-img" src={HOME.googlePlay} alt="google play" />
          </a>
        </div>
        <h6 className="description">{DESCRIPTION_DGG_HOME}</h6>

        <div
          className="btn-stroke-fill btn-go-scholar"
          onClick={() => history.push(ROUTER__LINK.TOURNAMENT)}
          aria-hidden
        >
          {t('homeContent.start')}
        </div>

        <div className="social-media-home">
          {mediaList.map((item) => (
            <SocialGroup
              key={item.name}
              icon={item.icon}
              name={item.name}
              title={item.title}
              path={item.path}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomeContent;
