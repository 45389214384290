import React from 'react';

function RedWarningIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 5.833v5.834m0 2.491v.009M17.5 10a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
        stroke="#F13535"
        strokeWidth={1.2}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default RedWarningIcon;
