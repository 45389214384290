import React, { useMemo } from 'react';
import JSCookie from 'js-cookie';
import { useTranslation } from 'react-i18next';
import {
  ALINIEX_REF_CODE,
  ARBITRUM_CHAIN_ID,
  BUY_CRYPTO_LINK,
  CHAIN_INFO,
  POLYGON_CHAIN_ID,
  TRANSACTION_FEE_DEFAULT,
} from '../../../constants';
import './styles.scss';

function ToastErrorInsufficientFund({ account = '', chainId }) {
  const aliniexLink = useMemo(() => {
    const linkedAccount = JSCookie.get('walletAccount');
    const CHAIN_DATA = CHAIN_INFO[chainId];
    const baseUrl = BUY_CRYPTO_LINK.ALINIEX;
    const referralCodeUrl = `&referral_code=${ALINIEX_REF_CODE}`;
    const redirectUrl = `&redirect_to=${window.location.href}`;
    const networkUrl = `&network=${CHAIN_DATA?.chainAliniexType}`;
    const itemsUrl = `items=${
      CHAIN_DATA?.tokenAliniexSupport
    },${CHAIN_DATA?.nativeCurrency?.symbol?.toLowerCase()}`;
    let amountsUrl = `&amounts=0,0`;
    if (chainId === Number(ARBITRUM_CHAIN_ID)) {
      amountsUrl = `&amounts=0,${TRANSACTION_FEE_DEFAULT.ARBITRUM}`;
    }
    if (chainId === Number(POLYGON_CHAIN_ID)) {
      amountsUrl = `&amounts=1,${TRANSACTION_FEE_DEFAULT.POLYGON}`;
    }
    const finalUrl = `${baseUrl}${itemsUrl}${amountsUrl}&wallet_address=${
      account || linkedAccount
    }${networkUrl}${redirectUrl}${referralCodeUrl}`;

    return finalUrl;
  }, [chainId, account]);
  const { t } = useTranslation();

  return (
    <div>
      {t('Insufficient funds for transaction fee.')}&nbsp;
      <a href={aliniexLink} target="_blank" className="toast-decoration" rel="noreferrer">
        {t('Buy crypto on Aliniex')}
      </a>
      &nbsp;{t('to pay the gas fee')}
    </div>
  );
}

export default ToastErrorInsufficientFund;
