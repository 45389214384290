import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import './i18n/i18n';
import { store } from './store';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import Seo from './components/common/Seo';
import Web3Provider from './providers/Web3Provider';
import { AuthProvier } from './context/AuthContext';
import { MaintenanceProvier } from './context/MaintenanceContext';
import { LanguageProvier } from './context/LanguageContext';

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <Seo />
    <Provider store={store}>
      <Web3Provider>
        <AuthProvier>
          <MaintenanceProvier>
            <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>
                <LanguageProvier>
                  <App />
                </LanguageProvier>
              </BrowserRouter>
            </PersistGate>
          </MaintenanceProvier>
        </AuthProvier>
      </Web3Provider>
    </Provider>
  </>,
);
