import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PUBLIC_NAV_BAR, ASSETS_NAV_BAR } from '../../../constants/sideBar';
import './styles.scss';
import { useAuthContext } from '../../../context/AuthContext';

function NavBar() {
  const history = useHistory();
  const pathSite = history.location.pathname;
  const { t } = useTranslation();

  const handleMovePage = (title, path) => {
    if (title === t('header.faq')) {
      window.open(path, '_blank');
    } else {
      history.push(`${path}`);
    }
  };
  const { isLogin } = useAuthContext();

  return (
    <div>
      <div className="navbar-container">
        {PUBLIC_NAV_BAR.map((item) => (
          <div
            key={item.display}
            className={`navbar-item ${pathSite.includes(item.url) ? 'text-aqua' : ''} ${
              item.style
            }`}
            onClick={() => handleMovePage(item.display, item.url)}
            aria-hidden
          >
            {t(item.display)}
          </div>
        ))}
        {isLogin &&
          ASSETS_NAV_BAR.map((item) => (
            <div
              key={item.display}
              className={`navbar-item ${pathSite.includes(item.url) ? 'text-aqua' : ''} ${
                item.style
              }`}
              onClick={() => handleMovePage(item.display, item.url)}
              aria-hidden
            >
              {t(item.display)}
            </div>
          ))}
      </div>
    </div>
  );
}

export default NavBar;
