import React from 'react';

function ReferralStep1(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M20 39.999c11.045 0 20-8.954 20-20C40 8.955 31.044 0 20 0 8.954 0 0 8.954 0 20c0 11.045 8.954 19.999 20 19.999z"
        fill="#0FF"
      />
      <path
        d="M20 9.86c-4.14 0-7.5 3.36-7.5 7.5 0 4.059 3.18 7.359 7.4 7.479h.36a7.486 7.486 0 007.24-7.48c0-4.14-3.36-7.5-7.5-7.5zM33.56 34.699a19.95 19.95 0 01-13.559 5.3c-5.24 0-10-2.02-13.56-5.3.48-1.82 1.78-3.48 3.68-4.76 5.46-3.64 14.34-3.64 19.76 0 1.92 1.28 3.2 2.94 3.68 4.76z"
        fill="#0FF"
      />
    </svg>
  );
}

export default ReferralStep1;
