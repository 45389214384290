import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import VIcon from '../../icons/VIcon';
import CopyIcon from '../../icons/CopyIcon';
import LinkIcon from '../../icons/LinkIcon';
import { CHAIN_INFO, COPY_TIME_INTERVAL } from '../../../constants';
import shortenAddress from '../../../utils/shortenAddress';
import './style.scss';

function ShowTxHash({
  chainId = Number(process.env.ARBITRUM_CHAIN_ID),
  txHash = '---',
  txLength = 6,
  type = 'address',
  className = '',
}) {
  const [copiedText, setCopiedText] = useState(false);

  useEffect(() => {
    const resetState = setInterval(() => {
      setCopiedText(false);
    }, COPY_TIME_INTERVAL);
    return () => clearInterval(resetState);
  }, []);

  return (
    <div className={`tx-hash ${className}`}>
      <a
        target="_blank"
        rel="noreferrer"
        href={`${CHAIN_INFO[chainId]?.blockExplorerUrls[0]}/${type}/${txHash}`}
      >
        <div className="tx-title" title={txHash}>
          {shortenAddress(txHash, txLength)}
        </div>
      </a>

      <CopyToClipboard onCopy={() => setCopiedText(true)} text={txHash}>
        <div className="copy-icon">
          {copiedText ? <VIcon color="#00FFFF" /> : <CopyIcon color="#00FFFF" />}
        </div>
      </CopyToClipboard>

      <a
        target="_blank"
        rel="noreferrer"
        href={`${CHAIN_INFO[chainId]?.blockExplorerUrls[0]}/${type}/${txHash}`}
      >
        <div className="copy-icon">
          <LinkIcon color="#00FFFF" />
        </div>
      </a>
    </div>
  );
}

export default ShowTxHash;
