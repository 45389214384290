import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import './style.scss';
import TButton from '../Button';
import EyeActiveIcon from '../../icons/EyeActiveIcon';
import EyeDeactiveIcon from '../../icons/EyeDeactiveIcon';
import TicketIcon from '../../icons/TicketIcon';
import { TICKET_TYPE_IMG } from '../../../../assets/imgs';
import ShowTxHash from '../ShowTxHash/ShowTxHash';
import RenderBtnResubmit from '../../pages/RedeemReward/RenderBtnResubmit';

const variants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: {
    opacity: 0,
    height: 0,
  },
};

function RedeemHistoryTable({ data = [] }) {
  const [expandedRowIndex, setExpandedRowIndex] = useState(-1);
  useEffect(() => {
    if (data) {
      setExpandedRowIndex(-1);
    }
  }, [data]);

  const { t } = useTranslation();
  return (
    <div className="table-style">
      <table>
        <thead>
          <tr />
          <tr>
            <td>{t('redeemRewards.gameTicket')}</td>
            <td className="text-center">{t('redeemRewards.ticketsRedeemed')}</td>
            <td className="text-center">{t('redeemRewards.pointsUsed')}</td>
            <td>{t('nft.status')}</td>
            <td>{t('filter.date')}</td>
            <td className="text-end">{t('redeemRewards.transaction')}</td>
            <td />
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <TableSection
              // eslint-disable-next-line
              key={`table-section-${index}`}
              data={item}
              index={index}
              expandedRowIndex={expandedRowIndex}
              setExpandedRowIndex={setExpandedRowIndex}
            />
          ))}
          {data?.length < 1 && (
            <tr className="odd">
              <td colSpan={100}>{t('myPrize.noData')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

const TableSection = ({ data, index, expandedRowIndex, setExpandedRowIndex }) => {
  const { t } = useTranslation();
  return (
    <>
      {/* eslint-disable-next-line */}
      <tr key={`body-${index}`}>
        <td data-label={t('redeemRewards.gameTicket')}>
          <div className="game-ticket-wrapper">
            <img alt="game-img" src={data?.gameImage} className="game-img" />
            <p>{data?.gameName}</p>
          </div>
        </td>
        <td data-label={t('redeemRewards.ticketsRedeemed')} className="text-center">
          <p>{data?.quantity}</p>
        </td>
        <td data-label={t('redeemRewards.pointsUsed')}>
          <div className="point-use-wrapper">
            <img
              alt="point-img"
              src={data?.tokenImage || TICKET_TYPE_IMG.ticketToken}
              className="point-img"
            />
            <p>{data?.amount}</p>
          </div>
        </td>
        <td data-label={t('nft.status')}>
          <RenderStatusRedeem data={data} />
        </td>
        <td data-label={t('filter.date')}>
          <p>
            {format(
              parseISO(data?.createdAt ? data?.createdAt : new Date().toISOString()),
              'dd/MM/yyyy',
            )}
          </p>
        </td>
        <td data-label={t('redeemRewards.transaction')}>
          {data?.transactionHash ? (
            <div className="tx-hash-wrapper">
              <ShowTxHash
                type="tx"
                chainId={data?.redeemDetail[0]?.chainId}
                txHash={data?.transactionHash}
                txLength={10}
              />
            </div>
          ) : (
            <RenderBtnResubmit data={data} />
          )}
        </td>
        <td>
          <ExpendableButton
            index={index}
            expandedRowIndex={expandedRowIndex}
            setExpandedRowIndex={setExpandedRowIndex}
          />
        </td>
      </tr>
      <tr key={`expan-${index}`} className="padding-zero">
        <td colSpan={7} className={`${!(expandedRowIndex === index) && 'padding-zero'}`}>
          <AnimatePresence initial={false}>
            {expandedRowIndex === index && (
              <motion.div
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={variants}
                transition={{
                  duration: 0.2,
                }}
                style={{ width: '100%' }}
              >
                <div className="expanded-row-wrapper">
                  {data?.redeemDetail?.map((i, index) => (
                    // eslint-disable-next-line
                    <div className="expand-card" key={`card-${index}`}>
                      <div className="img-name-wrapper">
                        <img
                          src={i?.ticketCollectionImage}
                          alt="ticket-img"
                          className="ticket-img"
                        />
                        <p className="ticket-name">{i?.ticketCollectionName}</p>
                      </div>
                      <div className="balance-number-wrap">
                        <p className="balance-point">{i?.quantity}</p>
                        {i?.ticketTypeImage ? (
                          <img
                            className="img-balance-point"
                            src={i?.ticketTypeImage}
                            alt="game-point"
                          />
                        ) : (
                          <TicketIcon />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </td>
      </tr>
    </>
  );
};

export default RedeemHistoryTable;

const ExpendableButton = ({ index, setExpandedRowIndex, expandedRowIndex }) => {
  const { t } = useTranslation();
  return (
    <TButton
      className={`${expandedRowIndex === index ? 'btn-expand-active' : 'btn-expand-deactive'}`}
      onClick={() => {
        if (index === expandedRowIndex) {
          setExpandedRowIndex(-1);
        } else {
          setExpandedRowIndex(index);
        }
      }}
    >
      {expandedRowIndex === index ? (
        <div className="eye-wrapper">
          <p className="eye-title hide-title">{t('hideDetail')}</p>
          <EyeActiveIcon className="icon-hidden" />
        </div>
      ) : (
        <div className="eye-wrapper">
          <p className="eye-title">{t('showDetails')}</p>
          <EyeDeactiveIcon className="icon-hidden" />
        </div>
      )}
    </TButton>
  );
};

function RenderStatusRedeem({ data }) {
  return (
    <div className="status-wrapper">
      <div className={`${data?.status === 'Succeed' ? 'green-dot' : 'red-dot'}`} />
      <p className={`${data?.status === 'Succeed' ? 'green-label' : 'red-label'}`}>
        {data?.status}
      </p>
    </div>
  );
}
