import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';
import { BiCaretDown, BiCaretUp } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/imgs';
import { useAppDispatch } from '../../../store';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import ModalDuration from '../../common/Modal/ModalDuration';
import scholarshipApi from '../../../services/scholarship';
import './styles.scss';
import useMessage from '../../common/toast/UseMessage';
import { ERR_MESSAGE, RENTAL_AVAILABLE, RENTAL_RENTED, RENTAL_UN_LIST } from '../../../constants';
import ModalDelete from '../../common/Modal/ModalDelete';
import { convertColorStatus } from '../../../utils/convertColorStatus';
import ModalConfirm from '../../common/Modal/ModalConfirm';
import ModalGoToEmail from '../../common/Modal/ModalGoToEmail';

const NAME = 'name';
const START_DATE = 'startDate';
const STATUS = 'status';

const DESC = 'desc';
const ASC = 'asc';

function NFTRental(props) {
  const { id, account, tokenAccess, slug, urlMarket } = props;
  const { t } = useTranslation();

  const history = useHistory();
  const { search } = history.location;
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();
  const [isShowDuration, setShowDuration] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showReturn, setShowReturn] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [idUnlist, setIdUnlist] = useState();
  const [idReturn, setIdReturn] = useState();
  const [idNfts, setIdNfts] = useState([]);

  const [orderBy, setOrderBy] = useState(NAME);

  const [orderType, setOrderType] = useState(DESC);

  const handleSort = (name) => {
    if (name === orderBy) {
      setOrderType(orderType === DESC ? ASC : DESC);
    } else {
      setOrderType(ASC);
    }
    setOrderBy(name);
  };

  const getRentalNfts = async () => {
    dispatch(showLoading());
    try {
      const params = {
        walletAddress: account,
        gameId: id,
        orderBy,
        orderType,
      };
      const res = await scholarshipApi.getMyNfts(params, slug);
      setIdNfts(res.data);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const sendNfts = async () => {
    dispatch(showLoading());
    try {
      const data = { nfts: idNfts.filter((e) => e.checked && e.status === RENTAL_UN_LIST) };
      await scholarshipApi.sendNfts(data);
      getRentalNfts();
    } catch (error) {
      if (error?.response?.data?.email_error) {
        setShowEmail(true);
      } else {
        openMessageError(error.response.data?.errors[0].msg || ERR_MESSAGE);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const unlistNfts = async (value) => {
    dispatch(showLoading());
    try {
      const data = {
        nft_ids: idNfts
          .filter((e) => e.id === value && e.status === RENTAL_AVAILABLE)
          .map((e) => e.id),
      };
      await scholarshipApi.unListNfts(data);
      getRentalNfts();
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const returnNfts = async (value) => {
    dispatch(showLoading());
    try {
      const data = {
        nft_ids: idNfts
          .filter((e) => e.id === value && e.status === RENTAL_RENTED)
          .map((e) => e.id),
      };
      await scholarshipApi.returnNfts(data);
      getRentalNfts();
    } catch (error) {
      Error(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (tokenAccess) {
      getRentalNfts();
    }
  }, [id, orderBy, orderType]);

  const handleOpenModalDelete = (value) => {
    setShowDelete(true);
    setIdUnlist(value);
  };
  const handleDeleteRental = (value) => {
    unlistNfts(value);
  };

  const handleOpenModalReturn = (value) => {
    setShowReturn(true);
    setIdReturn(value);
  };
  const handleReturnNft = (value) => {
    returnNfts(value);
  };

  const handleCheckedID = (index) => {
    const nft = [...idNfts];
    nft[index].checked = !idNfts[index].checked;
    setIdNfts(nft);
  };

  const handleSendNfts = () => {
    sendNfts();
  };

  const isCheckedAcceptSendNft =
    idNfts.filter((e) => e.checked && e.status === RENTAL_UN_LIST).length > 0;

  return (
    <>
      <ModalGoToEmail
        show={showEmail}
        setShow={setShowEmail}
        data={idNfts}
        query={search}
        type={1}
      />
      <ModalDelete
        title={t('nft.unlistNFT')}
        description={t('nft.confirm.un-list')}
        show={showDelete}
        setShow={setShowDelete}
        indexDelete={idUnlist}
        handleDelete={handleDeleteRental}
      />
      <ModalConfirm
        title={t('nft.returnNFT')}
        description={t('nft.confirm.return')}
        show={showReturn}
        setShow={setShowReturn}
        indexConfirm={idReturn}
        handleConfirm={handleReturnNft}
      />
      <div className="wrap-my-nfts-rental">
        {idNfts.length > 0 ? (
          <div className="wrap-table-nfts">
            <Table className="table-nft-rental">
              <thead>
                <tr>
                  <th>
                    <h6>ID</h6>
                  </th>
                  <th>
                    <h6
                      className="no-select cursor-pointer"
                      onClick={() => handleSort(NAME)}
                      aria-hidden
                    >
                      {t('name')}{' '}
                      <span>
                        {orderBy === NAME && orderType === ASC ? (
                          <BiCaretUp
                            className={
                              orderBy === NAME ? 'ac-icon-sort-nft icon-sort-nft' : 'icon-sort-nft'
                            }
                          />
                        ) : (
                          <BiCaretDown
                            className={
                              orderBy === NAME ? 'ac-icon-sort-nft icon-sort-nft' : 'icon-sort-nft'
                            }
                          />
                        )}
                      </span>
                    </h6>
                  </th>
                  <th>
                    <h6
                      className="no-select cursor-pointer"
                      onClick={() => handleSort(START_DATE)}
                      aria-hidden
                    >
                      {t('rentalDuration')}{' '}
                      <span>
                        {orderBy === START_DATE && orderType === ASC ? (
                          <BiCaretUp
                            className={
                              orderBy === START_DATE
                                ? 'ac-icon-sort-nft icon-sort-nft'
                                : 'icon-sort-nft'
                            }
                          />
                        ) : (
                          <BiCaretDown
                            className={
                              orderBy === START_DATE
                                ? 'ac-icon-sort-nft icon-sort-nft'
                                : 'icon-sort-nft'
                            }
                          />
                        )}
                      </span>
                    </h6>
                  </th>
                  <th>
                    <h6
                      className="no-select cursor-pointer"
                      onClick={() => handleSort(STATUS)}
                      aria-hidden
                    >
                      {t('status')}{' '}
                      <span>
                        {orderBy === STATUS && orderType === ASC ? (
                          <BiCaretUp
                            className={
                              orderBy === STATUS
                                ? 'ac-icon-sort-nft icon-sort-nft'
                                : 'icon-sort-nft'
                            }
                          />
                        ) : (
                          <BiCaretDown
                            className={
                              orderBy === STATUS
                                ? 'ac-icon-sort-nft icon-sort-nft'
                                : 'icon-sort-nft'
                            }
                          />
                        )}
                      </span>
                    </h6>
                  </th>
                </tr>
              </thead>
              {idNfts.map((item, index) => (
                <tbody key={item.tokenId} className="row-nft">
                  <tr>
                    <td>
                      <h6>#{item?.tokenId}</h6>
                    </td>
                    <td>
                      <h6 className="d-flex align-items-center gap-2">
                        {item.type_media === 'video' ? (
                          <video autoPlay playsInline loop width="40" height="40">
                            <track kind="captions" />
                            <source src={item.image} type="video/mp4" />
                          </video>
                        ) : (
                          <img src={item.image} alt="img" className="row-nft-image" />
                        )}
                        {item.name}
                      </h6>
                    </td>
                    <td>
                      <h6 className="row-nft-duration">{item.duration || t('unlimited')}</h6>
                    </td>
                    <td>
                      <h6 className={convertColorStatus(item.status)?.color}>
                        {convertColorStatus(item.status)?.name}
                      </h6>
                    </td>
                    <td style={{ textAlign: 'end' }}>
                      {item.status === RENTAL_AVAILABLE && (
                        <img
                          src={ICONS.iconDelete}
                          alt="dgg-network"
                          className="cursor-pointer"
                          onClick={() => handleOpenModalDelete(item.id)}
                          aria-hidden
                        />
                      )}
                      {item.status === RENTAL_UN_LIST && (
                        <img
                          src={item.checked ? ICONS.iconChecked : ICONS.iconTick}
                          alt="icon"
                          className="cursor-pointer"
                          onClick={() => handleCheckedID(index)}
                          aria-hidden
                        />
                      )}
                      {item.status === RENTAL_RENTED && (
                        <img
                          src={ICONS.iconReturn}
                          alt="icon"
                          className="cursor-pointer"
                          onClick={() => handleOpenModalReturn(item.id)}
                          aria-hidden
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              ))}
            </Table>
          </div>
        ) : (
          <h6 className="mb-5 no-data">
            <div>
              {t('require.byNFT.notify1')}{' '}
              <a href={urlMarket} target="_blank" rel="noopener noreferrer" className="a-link">
                {t('require.byNFT.notify2')}
              </a>{' '}
              {t('require.byNFT.notify3')}
            </div>
          </h6>
        )}
        {idNfts.length > 0 && (
          <div
            className={
              isCheckedAcceptSendNft
                ? 'btn-stroke-fill mb-5'
                : 'btn-stroke-fill mb-5 btn-brown btn-disable'
            }
            onClick={isCheckedAcceptSendNft ? handleSendNfts : () => {}}
            aria-hidden
          >
            {t('nft.acceptNFT')}
          </div>
        )}
      </div>
      <ModalDuration show={isShowDuration} setShow={setShowDuration} />
    </>
  );
}

export default NFTRental;
