import React from 'react';
import { BiCaretDown, BiCaretUp } from 'react-icons/bi';
import { MY_TOURNAMENT_SORT } from '../../../constants';
import './styles.scss';

function SortIcon(props) {
  const { ASC } = MY_TOURNAMENT_SORT;
  const { sortBy, sortType, currentSortBy } = props;

  return (
    <span>
      {sortBy === currentSortBy && sortType === ASC ? (
        <BiCaretUp className={sortBy === currentSortBy ? 'ac-icon-sort icon-sort' : 'icon-sort'} />
      ) : (
        <BiCaretDown
          className={sortBy === currentSortBy ? 'ac-icon-sort icon-sort' : 'icon-sort'}
        />
      )}
    </span>
  );
}

export default SortIcon;
