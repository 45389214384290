import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import BigNumber from 'bignumber.js';

Chart.register(CategoryScale);

function DonutChart({ amountData = [], totalNumber = 0, title = '' }) {
  const [chartData, setChartData] = useState();
  const { t } = useTranslation();
  const [textCenter, setTextCenter] = useState({
    id: 'textCenter',
    beforeDatasetsDraw(chart) {
      const { ctx } = chart;
      ctx.save();
      ctx.font = 'small-caps bold 22px arial';

      ctx.fillStyle = '#00ffff';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(`0%`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y);
    },
  });

  const options = {
    cutout: '80%',
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    if (amountData?.length > 0) {
      const chartDataNumber = amountData?.map((i) => i?.total || i?.amount || '0');

      const textCenter = {
        id: 'textCenter',
        beforeDatasetsDraw(chart) {
          const { ctx, data } = chart;
          ctx.save();
          const datasets = data.datasets[0].data;
          const totalAmount = datasets.reduce(
            (total, amount) => new BigNumber(total).plus(new BigNumber(amount || 0)).toNumber(),
            0,
          );

          let totalPercent = 0;
          if (Number(totalAmount)) {
            totalPercent = new BigNumber(datasets[0])
              .div(totalAmount)
              .multipliedBy(100)
              .toFormat(0);
          }
          ctx.font = 'small-caps bold 22px arial';
          ctx.fillStyle = '#00ffff';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(
            `${totalPercent}%`,
            chart.getDatasetMeta(0).data[0].x,
            chart.getDatasetMeta(0).data[0].y,
          );
        },
      };

      setTextCenter(textCenter);
      setChartData({
        labels: [t('filter.tier1'), t('filter.tier2')],
        datasets: [
          {
            data: chartDataNumber,
            backgroundColor: ['#00FFFF', '#e5c000'],
            borderColor: 'transparent',
          },
        ],
        hoverOffset: 30,
      });
    }
  }, [amountData]);

  return (
    <div className="donut-overview-wrapper">
      <div className="referral-tiers-wrapper">
        <p className="referral-small-title">{title}</p>
        <div className="tier-line-wrapper">
          {amountData?.map((i) => (
            <TierLine key={i?.id} data={i} />
          ))}
        </div>
      </div>
      <div className={`donut-chart-wrapper ${totalNumber === 0 && 'donut-zero'}`}>
        {chartData && <Doughnut data={chartData} options={options} plugins={[textCenter]} />}
      </div>
    </div>
  );
}

export default DonutChart;

function TierLine({ className = '', data }) {
  const { t } = useTranslation();
  return (
    <div className="tier-wrapper">
      <div
        className={`dot ${data?.referralLevel === 1 ? 'blue-dot' : 'yellow-dot'} ${className}`}
      />
      <p className="tier-value">
        {t('filter.tier')} {data?.referralLevel}: {data?.total || data?.amount || '-'}{' '}
        {data?.tokenSymbol}
      </p>
    </div>
  );
}
