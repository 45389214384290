import React from 'react';

function WebsiteIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.855 2.15a1.615 1.615 0 00-1.608-.417L2.84 5.607a1.595 1.595 0 00-1.153 1.26c-.118.621.295 1.411.835 1.741l4.193 2.56c.43.264.985.198 1.34-.159l4.801-4.8a.613.613 0 01.884 0 .629.629 0 010 .883l-4.81 4.8c-.356.357-.423.91-.16 1.341l2.562 4.209c.3.499.816.783 1.383.783.066 0 .141 0 .208-.01a1.62 1.62 0 001.358-1.15l3.976-13.307a1.619 1.619 0 00-.4-1.608"
        fill="#0FF"
      />
      <path
        opacity={0.4}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.508 14.009a.624.624 0 01-.442-1.067l1.137-1.138a.627.627 0 01.885 0 .627.627 0 010 .884l-1.139 1.137a.62.62 0 01-.441.184zm3.134.993a.624.624 0 01-.442-1.067l1.138-1.138a.627.627 0 01.884 0 .627.627 0 010 .884L6.084 14.82a.62.62 0 01-.442.183zm.211 2.974a.62.62 0 00.884 0l1.138-1.138a.627.627 0 000-.884.627.627 0 00-.884 0l-1.138 1.138a.624.624 0 000 .884z"
        fill="#0FF"
      />
    </svg>
  );
}

export default WebsiteIcon;
