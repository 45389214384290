import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TButton from '../../common/Button';
import { JOIN_TICKET } from '../../../../assets/imgs';
import { MARKETPLACE_FRONTEND_URL } from '../../../constants';

function ModalBuyTicket(props) {
  const { t } = useTranslation();
  const { show, setShow, collectionId, collectionSlug } = props;
  const loginRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (loginRef.current && !loginRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [loginRef]);
  return (
    <div
      className={show ? 'wrap-modal-custom join-tournament d-block' : 'wrap-modal-custom'}
      ref={loginRef}
    >
      <h6 className="color-primary mb-4" style={{ fontSize: '24px' }}>
        {t('donate.buyTicket')}
      </h6>
      <p style={{ fontSize: '16px' }}>{t('donate.require')}</p>
      <div className="d-flex justify-content-center mt-3 mb-3">
        <img src={JOIN_TICKET.ticket} alt="coin" />
      </div>
      <div className="d-flex gap-3">
        <TButton
          className="mb-2"
          title={t('donate.cancel')}
          type="reverse"
          width="full-width"
          onClick={() => setShow(false)}
        />
        <TButton
          className="mb-2"
          title={t('Buy Now')}
          width="full-width"
          onClick={() => {
            window.open(
              `${MARKETPLACE_FRONTEND_URL}ticket-collections/${collectionSlug}-${collectionId}`,
              '_blank',
            );
          }}
        />
      </div>
    </div>
  );
}

export default ModalBuyTicket;
