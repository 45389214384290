import { toast } from 'react-toastify';
import { useCallback } from 'react';

export default function useMessage() {
  const openMessage = useCallback((data) => toast(data.message, { type: 'success' }), []);
  const openMessageError = useCallback((err) => {
    if (err !== 'Unauthorized') {
      toast(err, { type: 'error' });
    }
  }, []);
  return { openMessage, openMessageError };
}
