import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/imgs';
import './styles.scss';

function ModalSuccess(props) {
  const { show, message } = props;
  const { t } = useTranslation();
  return (
    <div className={show ? 'wrap-modal-success d-block' : 'wrap-modal-success'}>
      <div className="group-header">
        <div className="title">{t('success.submitSuccess')}</div>
      </div>
      <div className="group-message">
        <h6>{message}</h6>
        <img src={ICONS.iconSuccess} alt="icon" />
      </div>
    </div>
  );
}

export default ModalSuccess;
