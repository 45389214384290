import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import scholarshipApi from '../../services/scholarship';

const initialState = {
  isCreateProfile: true,
  profile: [],
};
export const getProfile = createAsyncThunk('auth/login', async (_, thunkAPI) => {
  try {
    const res = await scholarshipApi.getProfile();
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
const profileSlice = createSlice({
  name: 'auth/login',
  initialState,
  reducers: {
    checkIsProfile: (state, action) => {
      state.isCreateProfile = !action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.isCreateProfile = !state.profile.firstName;
    });
  },
});
export const { checkIsProfile } = profileSlice.actions;
export default profileSlice.reducer;
