import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import { ICONS, TEAM } from '../../../../assets/imgs';
import Pagination from '../../common/Pagination/index';
import './styles.scss';

function ListMyTeam(props) {
  const { listMyTeam, onPageChange, totalPage, currentPage } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const goToDetail = (id) => {
    history.push(`/my-team/${id}`);
  };
  const goToEditDetail = (e, value) => {
    e.stopPropagation();
    history.push(`/my-team/update/${value}`);
  };

  return (
    <div className="list-my-team">
      {listMyTeam.length ? (
        <>
          <Table borderless>
            <thead>
              <tr>
                <th className="header-name">
                  <h6>{t('team.teamName')}</h6>
                </th>
                <th className="header-owner-label"> </th>
                <th className="header-member">
                  <h6>{t('team.member')}</h6>
                </th>
                <th className="header-owner">
                  <h6>{t('team.owner')}</h6>
                </th>
                <th className="header-setting"> </th>
              </tr>
            </thead>
            <tbody>
              {listMyTeam.map((item) => (
                <tr key={item?.id} onClick={() => goToDetail(item.id)} aria-hidden>
                  <td className="d-between content-name">
                    <h6>
                      <img
                        src={item.avatar || TEAM.logoTeamDefault}
                        className="icon-team"
                        alt="dgg-network"
                      />
                      <span className="name-team">{item.name}</span>
                    </h6>
                  </td>
                  <td className="content-owner-label">
                    {item.isOwner && <h6 className="owned">{t('team.owner')}</h6>}
                  </td>
                  <td className="content-member">
                    <h6 className="member">{item.currentMembers}</h6>
                  </td>
                  <td className="content-owner">
                    <h6>
                      <img src={item.avatarUser || TEAM.avatarUser} className="icon-team" alt="" />
                      {item.ownerName}
                    </h6>
                  </td>
                  <td className="content-setting">
                    {item.isOwner && (
                      <h6
                        className="icon-edit"
                        onClick={(e) => goToEditDetail(e, item.id)}
                        aria-hidden
                      >
                        <img src={ICONS.iconSetting} alt="dgg-network" />
                      </h6>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination totalPage={totalPage} onChange={onPageChange} forcePage={currentPage} />
        </>
      ) : (
        <div className="no-text">{t('team.thereAreNoTeams')}</div>
      )}
    </div>
  );
}

export default ListMyTeam;
