import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import VIcon from '../../icons/VIcon';
import CopyIcon from '../../icons/CopyIcon';
import './style.scss';
import { COPY_TIME_INTERVAL } from '../../../constants';

function CopyableComponent({ className = '', text = '', defaultText = '' }) {
  const [copiedText, setCopiedText] = useState(false);

  useEffect(() => {
    const resetState = setInterval(() => {
      setCopiedText(false);
    }, COPY_TIME_INTERVAL);
    return () => clearInterval(resetState);
  }, []);

  return (
    <div className={`copyable-wrapper ${className}`}>
      <p title={text} className="copyable-text">
        {text || defaultText}
      </p>
      {text && (
        <CopyToClipboard onCopy={() => setCopiedText(true)} text={text}>
          <div className="copy-icon-wrapper">
            {copiedText ? <VIcon color="#00FFFF" /> : <CopyIcon color="#00FFFF" />}
          </div>
        </CopyToClipboard>
      )}
    </div>
  );
}

export default CopyableComponent;
