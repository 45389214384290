import React, { useEffect, useRef, useState } from 'react';
import './header.scss';
import { AiOutlineMenu } from 'react-icons/ai';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NAV__LINKS, SUB_NAV_LINKS } from '../../../constants';
import NotificationIcon from '../Notification';
import ButtonWallet from '../ButtonWallet';
import { HOME, ICONS } from '../../../../assets/imgs';
import { isOpenModalLoginSelector, toggleModalOpen } from '../../../store/features/openModalLogin';
import { useAppDispatch } from '../../../store';
import NewModalLogin from '../Modal/NewModalLogin';
import { useAuthContext } from '../../../context/AuthContext';
import SelectLanguageDropdown from '../Layout/SelectLanguageDropdown';
import SelectLanguageSwitch from '../Layout/SelectLanguageSwitch';

const Header = (props) => {
  const { dataConnect, type } = props;
  const headerRef = useRef(null);
  const history = useHistory();
  const TYPE_SUB_MENU = 1;
  const { isLogin } = useAuthContext();
  const { t } = useTranslation();

  const [subLinks, setSubLink] = useState(NAV__LINKS);

  useEffect(() => {
    if (type === TYPE_SUB_MENU) {
      setSubLink(SUB_NAV_LINKS);
    }
  }, [type]);
  const toggleMenu = () => headerRef.current.classList.toggle('active__menu');

  function renderHeaderItem(item) {
    const { t } = useTranslation();
    if (t(item.display) === t('header.docs') || t(item.display) === t('header.wallet')) {
      return (
        <div className="cursor-pointer" onClick={() => window.open(item.url, '_blank')} aria-hidden>
          <h2>{t(item.display)}</h2>
        </div>
      );
    }
    if (t(item.display) === t('header.marketPlace')) {
      return (
        <div className="coming-soon" aria-hidden>
          <h2>{t(item.display)}</h2>
          <p>{t('comingSoon')}</p>
        </div>
      );
    }
    return (
      <NavLink to={item.url} className={(navClass) => (navClass.isActive ? 'active' : '')}>
        <h2>{t(item.display)}</h2>
      </NavLink>
    );
  }

  function renderMobileHeaderItem(item) {
    const { t } = useTranslation();
    if (t(item.display) === t('header.docs') || t(item.display) === t('header.wallet')) {
      return (
        <div className="cursor-pointer" onClick={() => window.open(item.url, '_blank')} aria-hidden>
          <h2>{t(item.display)}</h2>
        </div>
      );
    }
    if (t(item.display) === t('Marketplace')) {
      return (
        <div className="coming-soon" aria-hidden>
          <h2>
            {t(item.display)} <span>{` ${t('comingSoon')}`}</span>
          </h2>
        </div>
      );
    }
    return (
      <NavLink
        to={item.url}
        className={(navClass) => (navClass.isActive ? 'active' : '')}
        style={item.url === '/profile' && !isLogin ? { display: 'none' } : {}}
      >
        <h2 className={item.style}>{t(item.display)}</h2>
      </NavLink>
    );
  }

  const isOpenModalLogin = useSelector(isOpenModalLoginSelector);
  const dispatch = useAppDispatch();

  const handleToggleModalLogin = () => {
    dispatch(toggleModalOpen());
  };
  return (
    <>
      <header className="header" ref={headerRef}>
        <div className="container-fluid padding-custom">
          <div className="navigation">
            <div className="logo">
              <h2 className=" d-flex gap-2 align-items-center ">
                <img src={HOME.logo} alt="logo" onClick={() => history.push('/')} aria-hidden />
              </h2>
            </div>
            <div className="nav__menu">
              <ul className="nav__list">
                {NAV__LINKS.map((item) => (
                  <li className="nav__item" key={item.url}>
                    {renderHeaderItem(item)}
                  </li>
                ))}
              </ul>
            </div>

            <div className="nav__right d-flex align-items-center gap-5">
              <span className="mobile__menu">
                <AiOutlineMenu onClick={toggleMenu} />
              </span>
            </div>

            <div className="sub-notify-connect">
              <div className="language-dropdown-in-header">
                <SelectLanguageDropdown />
              </div>
              {isLogin && <NotificationIcon img={ICONS.iconNotify} dataConnect={dataConnect} />}
              <ButtonWallet />
            </div>
          </div>
        </div>
      </header>
      <div
        className={type === TYPE_SUB_MENU ? 'sub__menu px-4 sub__menu__layout' : 'sub__menu px-4'}
        ref={headerRef}
      >
        <div className="d-flex justify-content-between">
          <ul className="nav__list">
            {subLinks.map((item) => (
              <li className="nav__item" key={item.url}>
                {renderMobileHeaderItem(item)}
              </li>
            ))}
            <li className="language-in-mobile">{t('header.language')}</li>
          </ul>
          <div className="language-dropdown-mobile-icon">
            {isLogin ? (
              <NotificationIcon
                className="custom-notification-mobile"
                img={ICONS.iconNotify}
                dataConnect={dataConnect}
              />
            ) : (
              <div />
            )}
            <SelectLanguageSwitch />
          </div>
        </div>
        <div className="show-button-login">
          <ButtonWallet />
        </div>
      </div>
      {!isLogin && <NewModalLogin show={isOpenModalLogin} setShow={handleToggleModalLogin} />}
    </>
  );
};

export default Header;
