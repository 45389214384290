import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ethers } from 'ethers';
import JSCookie from 'js-cookie';
import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import '../assets/scss/index.scss';
import { isIOS, osVersion } from 'react-device-detect';
import Spinner from './components/common/spinner/Spinner';
import ToastMessage from './components/common/toast/ToastMessage';
import useMessage from './components/common/toast/UseMessage';
import { ERR_MESSAGE } from './constants';
import Home from './pages/Home';
import MyTeam from './pages/MyTeam';
import CreateMyTeam from './pages/MyTeam/CreateMyTeam';
import MyTeamDetail from './pages/MyTeam/MyTeamDetail';
import UpdateMyTeam from './pages/MyTeam/UpdateMyTeam';
import MyTournament from './pages/MyTournament/MyTournament';
import PendingTeam from './pages/PendingTeam';
import Profile from './pages/Profile';
import Rental from './pages/Rental';
import Scholarship from './pages/Scholarship';
import ScholarshipDetail from './pages/ScholarshipDetail';
import Tournaments from './pages/Tournament';
import Explore from './pages/Tournament/Explore';
import TournamentDetail from './pages/TournamentDetail';
import Transfer from './pages/Transfer';
import PageNotFound from './pages/PageNotFound';
import Ranking from './pages/Ranking';
import Tracking from './pages/Tracking';
import Blog from './pages/Blog';
import FAQ from './pages/FAQ';
import AuthApi from './services/authen';
import { useAppDispatch } from './store';
import { hideLoading, showLoading } from './store/features/loadingPage';
import Referral from './pages/Referral/Referral';
import MyPrizes from './pages/MyPrizes/MyPrizes';
import { useAuthContext } from './context/AuthContext';
import RedeemReward from './pages/RedeemReward/RedeemReward';
import TransactionHistory from './pages/TransactionHistory/TransactionHistory';
import ContactUs from './pages/ContactUs/ContactUs';
import { getProfile } from './store/features/login';
import { HOME } from '../assets/imgs';
import { useMaintenanceContext } from './context/MaintenanceContext';
import ServiceUnavailable from './pages/ServiceUnavailable';

export default function App() {
  const { openMessageError } = useMessage();
  const dispatch = useAppDispatch();
  const accountEmail = JSCookie.get('email');
  const { isCreatedProfile, isLogin, isLinkedEmaiAddress, checkLinkedEmailAddress } =
    useAuthContext();
  const { isMaintenance } = useMaintenanceContext();
  const history = useHistory();
  const { account: activeAccount, provider: web3Provider } = useWeb3React();
  const refreshExpireIn = JSCookie.get('expiresIn');

  useEffect(() => {
    if (isLogin && (!isCreatedProfile || !isLinkedEmaiAddress)) {
      history.push('/profile');
    }
  });
  useEffect(() => {
    if (isMaintenance) {
      history.push('/service-unavailable');
    }
  });

  useEffect(() => {
    const handleBeforeunload = () => {
      dispatch(hideLoading());
    };

    window.addEventListener('beforeunload', handleBeforeunload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
    };
  }, []);

  const linkWallet = async () => {
    dispatch(showLoading());
    try {
      const date = new Date();
      const signer = web3Provider.getSigner(activeAccount);
      const message = `${date.getTime()}.${activeAccount}`;
      const hashMessage = ethers.utils.hashMessage(message);
      const signature = await signer.signMessage(hashMessage);
      const data = {
        walletAddress: activeAccount,
        message,
        signature,
        email: accountEmail,
      };
      await AuthApi.connectWallet(data);
      const expiresInMilliseconds = parseInt(refreshExpireIn, 10);
      const dateExpire = new Date(expiresInMilliseconds);

      JSCookie.set('walletAccount', activeAccount, { expires: dateExpire });
      JSCookie.set('isLinkedWithEmaiAddress', true, { expires: dateExpire });
      checkLinkedEmailAddress();
      dispatch(getProfile());
    } catch (error) {
      openMessageError(error?.response?.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const dataConnect = {
    linkWallet,
  };

  return isIOS && parseInt(osVersion.split('.')[0], 10) < 15 ? (
    <SupportedVersionRequired />
  ) : (
    <>
      <Spinner />
      <ToastMessage />
      <Switch>
        <Route exact path="/assets">
          <Rental dataConnect={dataConnect} />
        </Route>
        <Route exact path="/tournament/explore">
          <Explore dataConnect={dataConnect} />
        </Route>
        <Route exact path="/tournament">
          <Tournaments dataConnect={dataConnect} />
        </Route>
        <Route exact path="/my-tournament">
          <MyTournament dataConnect={dataConnect} />
        </Route>
        <Route exact path="/profile">
          <Profile dataConnect={dataConnect} />
        </Route>
        <Route exact path="/scholarships">
          <Scholarship dataConnect={dataConnect} />
        </Route>
        <Route exact path="/scholarships/:slug">
          <ScholarshipDetail dataConnect={dataConnect} />
        </Route>
        <Route exact path="/my-team">
          <MyTeam dataConnect={dataConnect} />
        </Route>
        <Route exact path="/my-team/create">
          <CreateMyTeam dataConnect={dataConnect} />
        </Route>
        <Route exact path="/my-team/:id">
          <MyTeamDetail dataConnect={dataConnect} />
        </Route>
        <Route exact path="/my-team/update/:id">
          <UpdateMyTeam dataConnect={dataConnect} />
        </Route>
        <Route exact path="/pending-team">
          <PendingTeam dataConnect={dataConnect} />
        </Route>
        <Route exact path="/transfer/:id">
          <Transfer dataConnect={dataConnect} />
        </Route>
        <Route exact path="/tournament/blog">
          <Blog dataConnect={dataConnect} />
        </Route>
        <Route exact path="/tournament/faq">
          <FAQ dataConnect={dataConnect} />
        </Route>
        <Route exact path="/tournament/:slug">
          <TournamentDetail dataConnect={dataConnect} />
        </Route>
        <Route exact path="/ranking">
          <Ranking dataConnect={dataConnect} />
        </Route>
        <Route exact path="/tracking">
          <Tracking dataConnect={dataConnect} />
        </Route>
        <Route exact path="/referral-program">
          <Referral dataConnect={dataConnect} />
        </Route>
        <Route exact path="/my-prizes">
          <MyPrizes dataConnect={dataConnect} />
        </Route>
        <Route exact path="/redeem-reward">
          <RedeemReward dataConnect={dataConnect} />
        </Route>
        <Route exact path="/transaction-history">
          <TransactionHistory dataConnect={dataConnect} />
        </Route>
        <Route exact path="/contact-us">
          <ContactUs dataConnect={dataConnect} />
        </Route>
        <Route exact path="/service-unavailable">
          <ServiceUnavailable dataConnect={dataConnect} />
        </Route>
        <Route exact path="/">
          <Home dataConnect={dataConnect} />
        </Route>
        <Route exact path="/*">
          <PageNotFound dataConnect={dataConnect} />
        </Route>
      </Switch>
    </>
  );
}

function SupportedVersionRequired() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '16px',
          backgroundColor: '#393939',
          borderRadius: '12px',
          position: 'absolute',
          top: '50%',
          transform: 'translate(0, -50%)',
          width: '90%',
          maxWidth: '450px',
        }}
      >
        <img
          src={HOME.updateRequire}
          alt="update-os-img"
          style={{
            maxHeight: '200px',
            maxWidth: '300px',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <div
          style={{
            marginTop: '12px',
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 600,
            color: 'white',
          }}
        >
          System update required
        </div>
        <div
          style={{
            color: '#888585',
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '16px',
            marginTop: '4px',
            textAlign: 'center',
          }}
        >
          Visit Settings to update your iOS version (15.0) to 16.4 or later for better experience on
          DGG Game Platform!
        </div>
      </div>
    </div>
  );
}
