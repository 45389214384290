import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { SCHOLARSHIP } from '../../../../assets/imgs';
import { APPLY_APPROVED, APPLY_APPROVING } from '../../../constants';
import InfoItem from './InfoItem';
import countries from '../../../utils/countries';
import './styles.scss';

function ApplySuccess(props) {
  const { type, data, mode } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const CREATE_MODE_MANUAL = 0;

  const convertCodeToNameCountry = (countryId) =>
    countries.filter((e) => e.id === Number(countryId))[0]?.name;
  return (
    <div
      className="wrap-apply-success"
      style={type === APPLY_APPROVED ? { height: 'unset', padding: '20px 0' } : {}}
    >
      {type === APPLY_APPROVING && <img src={SCHOLARSHIP.dggFormSuccess} alt="dgg-network" />}
      {type === APPLY_APPROVED && (
        <>
          <div>
            <h6 className="mb-2">{t('scholarship.question2')}</h6>

            <div
              className="btn-stroke-fill mb-5"
              onClick={() => history.push('/tracking')}
              aria-hidden
            >
              {t('navigate.goToTracking')}
            </div>
          </div>

          <div className="wrap-info-apply-success">
            <InfoItem name="First name" value={data?.firstName} />
            <InfoItem name="Last name" value={data?.lastName} />
            <InfoItem name="Email" value={data?.email} />
            <InfoItem name="Country" value={convertCodeToNameCountry(data?.country)} />
            <InfoItem name="Discord" value={data?.discord} />
            <InfoItem name="Wallet Address" value={data?.walletAddress} />
            <InfoItem name="Game" value={data?.game} />
            <InfoItem name="Guild" value={data?.guild} />
            {data?.username ? (
              <InfoItem name="Game Account Username" value={data?.username} />
            ) : (
              <h6>
                {t('scholarship.accountUserName')}:{' '}
                <span style={{ color: 'red' }}>{t('scholarship.approval')}</span>
              </h6>
            )}
            {mode === CREATE_MODE_MANUAL && (
              <div>
                {data?.password ? (
                  <InfoItem name="Game Account Password" value={data?.password} type={1} />
                ) : (
                  <h6>
                    {t('scholarship.accountPassword')}:{' '}
                    <span style={{ color: 'red' }}>{t('scholarship.approval')}</span>
                  </h6>
                )}
              </div>
            )}

            <h6>KYC:</h6>
            <Row>
              <Col>
                <img src={data?.KYCBefore} alt="dgg-network" className="img-info-apply" />
              </Col>
              <Col>
                <img src={data?.KYCAfter} alt="dgg-network" className="img-info-apply" />
              </Col>
            </Row>
          </div>
        </>
      )}

      {type === APPLY_APPROVING && <h6>{t('notification.noti1')}</h6>}
    </div>
  );
}

export default ApplySuccess;
