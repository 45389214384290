import React from 'react';
import { ToastContainer } from 'react-toastify';

export default function ToastMessage() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      style={{ zIndex: '99999' }}
    />
  );
}
