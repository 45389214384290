export const convertColorStatus = (status) => {
  let result = {
    name: '',
    color: '',
  };
  switch (status) {
    case 0:
      result = {
        name: 'Unlisted',
        color: 'unList-color',
      };
      break;
    case 1:
      result = {
        name: 'Available',
        color: 'available-color',
      };
      break;
    case 2:
      result = {
        name: 'Waiting for transfer',
        color: 'pending-color',
      };
      break;
    case 3:
      result = {
        name: 'Assigned',
        color: 'rented-color',
      };
      break;
    case 4:
      result = {
        name: 'Waiting for refund NFT',
        color: 'refund-color',
      };
      break;
    default:
      break;
  }
  return result;
};

export const convertStatusRentalRequest = (status) => {
  let result = {
    name: '',
    color: '',
  };
  switch (status) {
    case 0:
      result = {
        name: 'Requesting',
        color: '#9B51E0',
      };
      break;
    case 1:
      result = {
        name: 'Pending',
        color: '#E08F17',
      };
      break;
    case 2:
      result = {
        name: 'Success',
        color: '#219653',
      };
      break;
    case 3:
      result = {
        name: 'Rejected',
        color: '#EB5757',
      };
      break;
    default:
      break;
  }
  return result;
};
