import React from 'react';
import Modal from 'react-modal';
import './styles.scss';

const ModalNft = (props) => {
  const { dataDetailNft } = props;

  const closeModal = () => {
    props.handleCloseModal();
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <Modal
      isOpen={props.isVisible}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="content-nft p-3">
        <div className="w-100">
          <p>#{dataDetailNft.id}</p>
          <h4>{dataDetailNft.name}</h4>
        </div>
        <img src={dataDetailNft.img} alt="nft" />
      </div>
    </Modal>
  );
};

export default ModalNft;
