import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'reactstrap';
import './styles.scss';
import { useTranslation } from 'react-i18next';

function ModalGoToEmail(props) {
  const { show, setShow, data, query } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const emailRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (emailRef.current && !emailRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [emailRef]);

  const handleGotoProfile = () => {
    history.push({
      pathname: '/profile',
      state: { query, data },
    });
  };

  return (
    <div className={show ? 'wrap-modal-custom d-block' : 'wrap-modal-custom'} ref={emailRef}>
      <h6 className="color-primary mb-2">{t('alert.warning.title')}!</h6>
      <h6 className="mb-3">{t('require.toFillEmail')}</h6>
      <Row>
        <div className="btn-stroke-fill" onClick={handleGotoProfile} aria-hidden>
          {t('goToProfile')}
        </div>
      </Row>
    </div>
  );
}

export default ModalGoToEmail;
