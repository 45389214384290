import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import COMMON_EN from './locales/en/common.json';
import COMMON_VI from './locales/vi/common.json';

const resources = {
  en: {
    common: COMMON_EN,
  },
  vi: {
    common: COMMON_VI,
  },
};

const defaultNS = 'common';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    ns: ['common'],
    defaultNS,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },

    react: {
      // wait: true,
      useSuspense: true,
    },
  });

export default i18n;
