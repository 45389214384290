import { ICONS } from '../../assets/imgs';

export const PUBLIC_SIDE_BAR = [
  {
    icon: ICONS.iconGame,
    subIcon: ICONS.subIconGame,
    title: 'Scholarships',
    path: 'scholarships',
    imgStyles: 'board-icon-game',
    id: 'id-tooltip-scholarship',
  },
  {
    icon: ICONS.iconRental,
    subIcon: ICONS.subIconRental,
    title: 'Asset Management',
    path: 'assets',
    imgStyles: 'board-icon-rental',
    id: 'id-tooltip-rental',
  },
  {
    icon: ICONS.iconTournament,
    subIcon: ICONS.subIconTournament,
    title: 'Tournaments',
    path: 'tournament',
    imgStyles: 'board-icon-tournament-rental',
    id: 'id-tooltip-tournament-rental',
  },
  {
    title: 'Explore',
    path: 'tournament/explore',
    imgStyles: 'board-icon-explore-rental',
    id: 'id-tooltip-explore-rental',
  },
];

export const ASSETS_SIDE_BAR = [
  {
    title: 'My Tournaments',
    path: 'my-tournament',
    imgStyles: 'board-icon-my-tournament-rental',
    id: 'id-tooltip-my-tournament-rental',
  },
  {
    title: 'My Prizes',
    path: 'my-prizes',
    imgStyles: 'board-icon-my-prizes',
    id: 'id-tooltip-my-reward',
  },
  {
    title: 'My Teams',
    path: 'my-team',
    imgStyles: 'board-icon-my-team-rental',
    id: 'id-tooltip-my-team-rental',
  },

  {
    title: 'Pending Team',
    path: 'pending-team',
    imgStyles: 'board-icon-my-team-rental',
    id: 'id-tooltip-pending-team-rental',
  },
  {
    icon: ICONS.referralIconActive,
    subIcon: ICONS.referralIcon,
    title: 'Referral',
    path: 'referral-program',
    imgStyles: 'referral-icon',
    id: 'id-tooltip-referral',
  },
  {
    icon: ICONS.iconRedeem,
    subIcon: ICONS.iconRedeemSub,
    title: 'Redeem',
    path: 'redeem-reward',
    imgStyles: 'profile-icon-rental',
    id: 'id-tooltip-redeem-reward',
  },
];

export const PUBLIC_NAV_BAR = [
  {
    display: 'Explore',
    url: '/tournament/explore',
    style: '',
  },
  {
    display: 'FAQ',
    url: 'https://docs.dgg.network/help-and-feedback/faq',
  },
];

export const ASSETS_NAV_BAR = [
  {
    display: 'My Teams',
    url: '/my-team',
  },
  {
    display: 'My Tournaments',
    style: '',
    url: '/my-tournament',
  },
  {
    display: 'My Prizes',
    style: '',
    url: '/my-prizes',
  },
  {
    display: 'Pending Team',
    style: '',
    url: '/pending-team',
  },
];
