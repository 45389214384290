import React from 'react';
import { SeedItem, SeedTeam } from 'react-brackets';
import './styles.scss';

const SeedBracket = ({ seed, branchLose, branchWin, final, height }) => {
  const onMouseEnter = (teamId) => {
    const allWithClass = Array.from(document.getElementsByClassName(`team-${teamId}`));
    allWithClass.forEach((element) => {
      const e = element;
      e.style.backgroundColor = 'rgb(31, 210, 241)';
    });
  };

  const onMouseLeave = (teamId) => {
    const allWithClass = Array.from(document.getElementsByClassName(`team-${teamId}`));
    allWithClass.forEach((element) => {
      const e = element;
      const winner = e.getAttribute('data-win');
      if (winner === 'true') {
        e.style.backgroundColor = 'aqua';
      } else {
        e.style.backgroundColor = '#252c42';
        e.style.color = 'white';
      }
    });
  };

  return (
    <SeedItem style={{ marginTop: final ? height / 2 : 0 }}>
      <div>
        {seed?.teams?.map((team) => (
          <div
            key={team.id}
            className="d-flex justify-content-end"
            onMouseEnter={() => onMouseEnter(team?.id)}
            onMouseLeave={() => onMouseLeave(team?.id)}
          >
            <SeedTeam
              className={`${team?.win ? 'team-win' : 'team-lose'} team-name team-${team?.id}`}
              data-win={team?.win}
            >
              {team?.name || 'NO TEAM '}
            </SeedTeam>
            <SeedTeam
              className={`${
                team?.win ? 'team-win' : 'team-lose'
              } score d-flex justify-content-center team-${team?.id}`}
              data-win={team?.win}
            >
              {team?.score || 0}
            </SeedTeam>
            {branchLose && (
              <div color="#11CDA7" className="branch-lose" style={{ height: height / 2 }} />
            )}
            {branchWin && (
              <div color="#11CDA7" className="branch-win" style={{ height: height / 2 }} />
            )}
            {final && <div color="#11CDA7" className="final" />}
          </div>
        ))}
      </div>
    </SeedItem>
  );
};

export default SeedBracket;
