import React from 'react';
import './styles.scss';
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt({ html: true, linkify: true, typographer: true });

function Detail(props) {
  const { description } = props;
  const formattedText = description?.replace(/\n/g, '<br/>');
  return (
    <div className="detail-tab">
      <div
        className="detail-content"
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{
          __html: md?.render(formattedText || ''),
        }}
      />
    </div>
  );
}

export default Detail;
