import React from 'react';

function GameIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M12.934 4.26c2.057-.814 4.4.184 5.215 2.241.815 2.058 2.057 8.353-.57 9.392-1.447.57-3.138-.815-4.503-2.506a2.558 2.558 0 00-2.037-.978H8.96a2.62 2.62 0 00-2.037.978c-1.365 1.711-3.056 3.076-4.503 2.506-2.628-1.039-1.385-7.334-.57-9.392a4.026 4.026 0 015.215-2.24c.286.122.57.264.815.448.306.224.632.387.998.509a3.49 3.49 0 001.12.183c.755 0 1.488-.265 2.12-.692.244-.184.53-.326.815-.449z"
        fill="#fff"
      />
      <rect x={4.99634} y={6.51953} width={1.11789} height={4.47157} rx={0.558946} fill="#fff" />
      <rect
        x={7.79077}
        y={8.19727}
        width={1.11789}
        height={4.47157}
        rx={0.558946}
        transform="rotate(90 7.79 8.197)"
        fill="#fff"
      />
      <ellipse cx={13.0996} cy={8.75489} rx={0.752934} ry={0.752934} fill="#fff" />
      <circle cx={15.9272} cy={8.75489} r={0.752934} fill="#fff" />
      <circle cx={14.5134} cy={7.27247} r={0.752934} fill="#fff" />
      <circle cx={14.5134} cy={10.2393} r={0.752934} fill="#fff" />
    </svg>
  );
}

export default GameIcon;
