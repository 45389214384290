import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TEAM } from '../../../assets/imgs';
import TButton from '../../components/common/Button';
import NewLayout from '../../components/common/NewLayout/NewLayout';
import useMessage from '../../components/common/toast/UseMessage';
import { ERR_MESSAGE } from '../../constants';
import tournamentApi from '../../services/tournament';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import './styles.scss';
import UnauthorizePage from '../UnauthorizePage/UnauthorizePage';
import { useAuthContext } from '../../context/AuthContext';

function PendingTeam(props) {
  const { dataConnect } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openMessageError, openMessage } = useMessage();
  const [listInviteTeam, setListInviteTeam] = useState([]);
  const { isLogin } = useAuthContext();

  const getListInviteTeam = async () => {
    dispatch(showLoading());
    try {
      const { data } = await tournamentApi.getInviteTeam();
      setListInviteTeam(data);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const joinTeam = async (e, id) => {
    e.stopPropagation();
    dispatch(showLoading());
    const payload = {
      teamId: id,
    };
    try {
      await tournamentApi.applyTeam(payload);
      getListInviteTeam().catch((error) => {
        openMessageError(t('error.error'), error);
      });
      openMessage({ message: t('success.applyTeamSuccessful') });
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (isLogin) {
      getListInviteTeam().catch((error) => {
        openMessageError(t('error.error'), error);
      });
    }
  }, []);

  return (
    <NewLayout
      dataConnect={dataConnect}
      title={t('referral.pendingTeam')}
      type={1}
      link="/tournament"
    >
      {isLogin ? (
        <div className="list-pending-team">
          <Table borderless>
            {listInviteTeam.length ? (
              <tbody>
                {listInviteTeam.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <h6>
                        <img src={TEAM.logoTeamDefault} className="icon-team" alt="dgg-network" />
                        <span>{item.name}</span>
                      </h6>
                    </td>
                    <td>
                      <TButton
                        className="btn-accept"
                        title={t('condition.accept')}
                        onClick={(e) => joinTeam(e, item.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr className="no-text">
                  <td>{t('referral.noInvite')}</td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      ) : (
        <UnauthorizePage />
      )}
    </NewLayout>
  );
}

export default PendingTeam;
